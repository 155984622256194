import React from "react";

//best practice imports
import practiceIcon1 from "../../../../app/assets/images/plug-and-play/icons/listed/1.png";
import practiceIcon2 from "../../../../app/assets/images/plug-and-play/icons/listed/2.png";
import practiceIcon3 from "../../../../app/assets/images/plug-and-play/icons/listed/3.png";
import practiceIcon4 from "../../../../app/assets/images/plug-and-play/icons/listed/4.png";
import practiceIcon5 from "../../../../app/assets/images/plug-and-play/icons/listed/5.png";
import practiceIcon6 from "../../../../app/assets/images/plug-and-play/icons/listed/6.png";

const Practices = () => {
  return (
    <>
      <div class="cust-padding-2 bg-light cust-pad-btm-100">
        <div class="container custom-cn-width">
          <div class="main-title sectiontitle-elements text-center">
            <h2 class="fw-normal wow animate__bounceInn mb-4 animated">
              Our Top Best Practices
            </h2>
          </div>
          <div class="row g-4">
            <div class="col-lg-6 col-xl-4">
              <div class="listed-cont">
                <div class="d-flex align-items-center mb-3">
                  <div class="listed-img me-3">
                    <img src={practiceIcon1} alt="" class="img-fluid" />
                  </div>
                  <div>
                    <h4 class="mb-3 fw-bold">
                      Employee Training and Development
                    </h4>
                  </div>
                </div>
                <p className="text-dark">
                  Continuous training program ensure agents are updated with
                  product knowledge, communication skills, and service
                  techniques.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-xl-4">
              <div class="listed-cont">
                <div class="d-flex align-items-center mb-3">
                  <div class="listed-img me-3">
                    <img src={practiceIcon2} alt="" />
                  </div>
                  <div>
                    <h4 class="mb-3 fw-bold">Quality Assurance</h4>
                  </div>
                </div>
                <p className="text-dark">
                  Regular monitoring maintains high service standards and
                  identifies areas for improvement.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-xl-4">
              <div class="listed-cont">
                <div class="d-flex align-items-center mb-3">
                  <div class="listed-img me-3">
                    <img src={practiceIcon3} alt="" />
                  </div>
                  <div>
                    <h4 class="mb-3 fw-bold">Efficient Use of Technology</h4>
                  </div>
                </div>
                <p className="text-dark">
                  Leveraging advanced call center technologies such as CRM
                  systems, ACD, IVR, and workforce management tools for enhanced
                  productivity.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-xl-4">
              <div class="listed-cont">
                <div class="d-flex align-items-center mb-3">
                  <div class="listed-img me-3">
                    <img src={practiceIcon4} alt="" />
                  </div>
                  <div>
                    <h4 class="mb-3 fw-bold">Performance Metrics and KPIs</h4>
                  </div>
                </div>
                <p className="text-dark">
                  Monitoring KPIs like AHT, FCR, CSAT scores, and NPS to gauge
                  effectiveness and identify improvement opportunities.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-xl-4">
              <div class="listed-cont">
                <div class="d-flex align-items-center mb-3">
                  <div class="listed-img me-3">
                    <img src={practiceIcon5} alt="" />
                  </div>
                  <div>
                    <h4 class="mb-3 fw-bold">Proactive Communication</h4>
                  </div>
                </div>
                <p className="text-dark">
                  Keeping customers informed about new products, services, or
                  changes and reaching out proactively to resolve potential
                  issues.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-xl-4">
              <div class="listed-cont">
                <div class="d-flex align-items-center mb-3">
                  <div class="listed-img me-3">
                    <img src={practiceIcon6} alt="" />
                  </div>
                  <div>
                    <h4 class="mb-3 fw-bold">Agent Empowerment</h4>
                  </div>
                </div>
                <p className="text-dark">
                  Empowering agents with authority and resources to resolve
                  issues effectively, improving both Employee satisfaction and
                  customer experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ----best practice end ---- */}

      <style jsx>{`
        .listed-cont {
          background: #ffffff !important;
          border-radius: 15px;
          border: none;
          box-shadow: rgba(149, 157, 165, 0.1) 0 8px 24px;
          box-shadow: none;
          border-radius: 10px;
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          padding: 25px !important;
          height: 100%;
        }
        .listed-img {
          flex-shrink: 0;
          padding: 16px;
          background: #0c5b79;
          border-radius: 50%;
        }
        .listed-img img {
          width: 32px;
        }
        .listed-cont h3 {
          font-size: 28px;
          line-height: 1.4;
          color: #fff !important;
          margin-bottom: 0px;
        }
        .listed-cont p {
          font-size: 18px;
          line-height: 1.4;
          margin-bottom: 0;
        }
      `}</style>
    </>
  );
};

export default Practices;
