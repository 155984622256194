import React from "react";
import { Link } from "react-router-dom";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";

// slider import
import moc1 from "../../../app/assets/images/prelease/1.png";
import moc2 from "../../../app/assets/images/prelease/dt2.png";
import moc3 from "../../../app/assets/images/prelease/dt3.jpg";
import moc4 from "../../../app/assets/images/prelease/dt4.jpg";
import moc5 from "../../../app/assets/images/prelease/dt5.jpg";
import moc6 from "../../../app/assets/images/prelease/dt6.jpg";
import moc7 from "../../../app/assets/images/prelease/dt7.jpg";
import moc8 from "../../../app/assets/images/prelease/dt8.jpg";
import moc9 from "../../../app/assets/images/prelease/dt9.jpg";
import moc10 from "../../../app/assets/images/prelease/dt10.jpg";
import moc11 from "../../../app/assets/images/prelease/dt11.png";

const products = () => {
  const properties = {
    prevArrow: (
      <button className="cs-btn btn-sc-left">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
      </button>
    ),
    nextArrow: (
      <button className="cs-btn btn-sc-right">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
        </svg>
      </button>
    ),
  };
  return (
    <div className="pressrelease-detail-page">
      <HeaderDark />
      <section class="mt-4 mt-lg-5 section-padding-btm">
        <div className="container custom-cn-width">
          <div className="row g-5 justify-content-center text-center">
            <div className="col-lg-9 mb-4 mb-lg-5 pt-lg-4">
              <h1 class="fw-light">
                CAP Digisoft Solutions, Inc. Opens New Call Center Office in El
                Salvador to Boost Market Expansion
              </h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center mt-3 mb-0">
                  <li class="breadcrumb-item">
                    <Link className="text-primary" to="/pressrelease">
                      Press Release
                    </Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    CAP Digisoft Solutions, Inc. Opens New Call Center Office in
                    El Salvador
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          {/*
			<!-- Press Release Sec --> */}
          <fieldset class="transition pressrelease-detail">
            <legend class="text-xl-start">
              {" "}
              SAN SALVADOR, El Salvador | January 10, 2023{" "}
            </legend>
            <div className="row align-items-start w-100">
              <div className="col-lg-6 order-lg-1 order-2">
                <p class="mt-3 mt-lg-0 mb-3">
                  CAP Digisoft Solutions, Inc. has announced the opening of a
                  new call center office in El Salvador. The state-of-the-art
                  facility, located in the heart of San Salvador, will serve as
                  a growth solution for companies seeking to expand their market
                  share and improve their customer service operations. The new
                  call center office is equipped with the latest technology and
                  staffed with a team of highly trained professionals known as
                  the "Growth Team". They are ready to handle a wide range of
                  customer service and sales support inquiries for{" "}
                  <a
                    href="https://capdigisoft.com/"
                    title="CAP Digisoft Solutions"
                    rel="nofollow">
                    CAP Digisoft
                  </a>{" "}
                  and their clients.
                </p>
                <p class="mb-3">
                  CEO Arun Kumar stated that the expansion is a testament to the
                  company's commitment to helping clients grow their businesses.
                  He believes that the new call center office in El Salvador
                  will help companies achieve their market expansion goals
                  through top-quality service and support.
                </p>
                <p class="mb-3">
                  <a
                    href="https://capdigisoft.com/"
                    title="CAP Digisoft Solutions"
                    rel="nofollow">
                    CAP Digisoft
                  </a>{" "}
                  is excited to be a part of the local community in El Salvador
                  and to provide employment opportunities to the talented
                  workforce. The company looks forward to using the El Salvador
                  Growth Team to build long-lasting relationships with clients
                  and helping them succeed in their business endeavors.
                </p>
                <p class="mb-3">
                  About CAP Digisoft Solutions, Inc. Founded in 2003, CAP
                  Digisoft Solutions, Inc. has been delivering software, KPO,
                  and{" "}
                  <a
                    href="/idea-booster"
                    target="_blank"
                    rel="noreferrer"
                    title="Idea to Product Services">
                    idea to product solutions
                  </a>{" "}
                  to over 1000 small, medium, and larger corporations in the
                  USA. They specialize in web and custom software development,
                  medical-legal knowledge process outsourcing, go-to-market
                  strategy, product positioning, and digital marketing, and have
                  earned an A+ rating from the BBB as a premier provider of
                  digital solutions.
                </p>

                <ul class="list-unstyled list-inline mb-0 footer-social share-btn">
                  <li class="list-inline-item f-15 d-md-inline-block d-block mb-2 mb-md-0">
                    Share this post on social media?
                  </li>
                  <li class="list-inline-item fb">
                    <a
                      title="Facebook"
                      class="text-danger"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/sharer/sharer?u=https://capdigisoft.com/press-release/cds-opens-new-call-center-office-in-el-salvador">
                      <svg
                        className="social-icons-list"
                        stroke="currentColor"
                        fill="none"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
                          fill="currentColor"></path>
                      </svg>
                    </a>
                  </li>
                  <li class="list-inline-item ln">
                    <a
                      title="Linked In"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fcapdigisoft.com%2Fpress-release%2Fcds-opens-new-call-center-office-in-el-salvador"
                      class="text-danger">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                      </svg>
                    </a>
                  </li>

                  <li class="list-inline-item tw">
                    <a
                      title="twitter"
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/share?text=CAP Digisoft Solutions, Inc. has announced the opening of a new call center office in El Salvador. &amp;url=https%3A%2F%2Fcapdigisoft.com%2Fpress-release%2Fcds-opens-new-call-center-office-in-el-salvador"
                      class="text-danger">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 text-center other-sec order-lg-2 order-1">
                <div className="bx-wrapper">
                  <Slide
                    className="brand-slider"
                    {...properties}
                    slidesToScroll={1}
                    slidesToShow={1}
                    autoplay={true}
                    arrows={true}
                    indicators={true}>
                    <div className="each-slide-effect">
                      <img src={moc1} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc2} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc3} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc4} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc5} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc6} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc7} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc8} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc9} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc10} alt="Terry Johnson" class="img-fluid" />
                    </div>
                    <div className="each-slide-effect">
                      <img src={moc11} alt="Terry Johnson" class="img-fluid" />
                    </div>
                  </Slide>
                </div>

                <h5 class="mt-3 mb-2 px-lg-5 font-size-23">
                  "CEO Arun Kumar stated that the expansion is a testament to
                  the company's commitment to helping clients grow their
                  businesses."
                </h5>
                <a
                  class="d-inline-block underline-border"
                  href="https://www.prweb.com/releases/2023/1/prweb19102906.htm"
                  target="_blank"
                  rel="noreferrer">
                  <span class="border-line d-inline-block me-1">
                    View Press Release
                  </span>
                  <i class="fa fa-arrow-right fa-sm position-relative"></i>
                </a>
              </div>
            </div>
          </fieldset>
        </div>
      </section>
      <div id="ssba-bar-2" class="left ssbp-wrap ssbp--theme-1">
        <div className="ssbp-container d-none d-lg-block">
          <ul class="ssbp-bar-list">
            <li class="">
              <a
                data-site=""
                class="ssba_facebook_share ssbp-facebook ssbp-btn"
                target="_blank"
                rel="noreferrer"
                href="http://www.facebook.com/sharer?u=https://capdigisoft.com/press-release/cds-opens-new-call-center-office-in-el-salvador">
                <svg
                  className="social-icons-list"
                  stroke="currentColor"
                  fill="none"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
                    fill="currentColor"></path>
                </svg>
              </a>
            </li>
            <li class="ssbp-li--linkedin">
              <a
                data-site="linkedin"
                class="ssba_linkedin_share ssba_share_link ssbp-linkedin ssbp-btn"
                target="_blank"
                rel="noreferrer"
                href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://capdigisoft.com/press-release/cds-opens-new-call-center-office-in-el-salvador">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                </svg>
              </a>
            </li>
            <li class="ssbp-li--twitter">
              <a
                data-site=""
                class="ssba_twitter_share ssbp-twitter ssbp-btn"
                href="https://twitter.com/share?text=CAP Digisoft Solutions, Inc. has announced the opening of a new call center office in El Salvador. &amp;url=https%3A%2F%2Fcapdigisoft.com%2Fpress-release%2Fcds-opens-new-call-center-office-in-el-salvador"
                target="_blank"
                rel="noreferrer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default products;
