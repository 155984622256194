import React from "react";

const unLocking = () => {
  return (
    <>
      {/* Unlocking the Path Section Start Here */}
      <div className="wg-create-design v-testi cust-padding border-line-btm">
        <div className="container custom-cn-width">
          <div className="row justify-content-center offer-sec pt-0">
            <div className="sectiontitle-element--center text-center">
              <div className="heading-section wow fadeInUp style-2">
                <h5 class="mb-2 fw-400">Unlocking the Path</h5>
                <div className="main-title sectiontitle-elements">
                  <h2 class="fw-normal pb-0 mb-2">What Idea Booster Offers</h2>
                </div>
                <p class="text-dark pb-3">
                  Idea Booster provides a complete array of services to elevate
                  your app concept from initial ideation to a proven and
                  compelling idea that attracts the necessary resources for
                  development and launch.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-margin">
              <div className="card offer-card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">1</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">Idea Refinement</h4>
                  </div>
                  <p class="text-dark">
                    {" "}
                    We refine your app idea to meet market needs and appeal to
                    your target audience, establishing a solid development
                    foundation.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-margin">
              <div className="card offer-card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">2</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">Technical Feasibility</h4>
                  </div>
                  <p class="text-dark">
                    Our experts evaluate the technical feasibility of your app,
                    ensuring a smooth development process without feature
                    compromises.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-margin">
              <div className="card offer-card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">3</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">App Design</h4>
                  </div>
                  <p class="text-dark">
                    We create UX/UI designs with wireframes, mockups, and
                    prototypes to optimize user experience and gather feedback
                    before development.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-margin">
              <div className="card offer-card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">4</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">Development Cost Analysis</h4>
                  </div>
                  <p class="text-dark">
                    {" "}
                    We offer a detailed financial analysis for development,
                    maintenance, and updates, aiding in budgeting and funding
                    efforts.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-margin">
              <div className="card offer-card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">5</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">Pitch Deck Service</h4>
                  </div>
                  <p class="text-dark">
                    Our pitch deck showcases your app's market potential, value,
                    and team to attract investors and partners effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>
        {`
          @media (min-width: 575px) and (max-width: 1299px) {
            .card.offer-card {
              min-height: 272px !important;
              height: 100% !important;
            }
          }
          @media (max-width: 575px) {
            .idea-booster-page .card {
              margin-right: 10px !important;
            }
          }
          .card-margin .card {
            padding: 13px !important;
            min-height: 212px;
          }
          .card-numb,
          .popup-youtube .icon {
            background: #0c5b79;
          }
          .card-margin,
          .testi-para {
            margin-bottom: 30px;
          }
          img.img-fluid.custom-thumbnail {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        `}
      </style>
    </>
  );
};

export default unLocking;
