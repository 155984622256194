import React from "react";
import "../../../../app/assets/css/plug-and-play.css";
import "react-slideshow-image/dist/styles.css";

// Partners imports
import partnerOne from "../../../../app/assets/images/plug-and-play/1.png";
import partnerThree from "../../../../app/assets/images/plug-and-play/3.png";
import partnerFour from "../../../../app/assets/images/plug-and-play/4.png";
import partnerFive from "../../../../app/assets/images/plug-and-play/5.png";
import partnerSix from "../../../../app/assets/images/plug-and-play/6.png";
import partnerSeven from "../../../../app/assets/images/plug-and-play/7.png";
import partnerEight from "../../../../app/assets/images/plug-and-play/8.png";
import partnerNine from "../../../../app/assets/images/plug-and-play/9.png";

const Slides = () => {
  return (
    <>
      {/* slide end */}
      <div className="cust-padding bg-white pb-0">
        <div class="container-fluid p-0" style={{ overflow: "hidden" }}>
          <div class="main-title sectiontitle-elements text-center">
            <h2 class="fw-normal mb-0">Our Trusted Clients</h2>
          </div>
          <div class="Marquee">
            <div class="Marquee-content">
              <div class="Marquee-tag">
                <img src={partnerOne} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                {" "}
                <img src={partnerThree} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerFour} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerFive} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                {" "}
                <img src={partnerSix} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerSeven} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerEight} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerNine} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerOne} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                {" "}
                <img src={partnerThree} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerFour} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerFive} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                {" "}
                <img src={partnerSix} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerSeven} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerEight} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerNine} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerOne} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                {" "}
                <img src={partnerThree} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerFour} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerFive} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                {" "}
                <img src={partnerSix} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerSeven} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerEight} class="img-fluid" alt="pp-img" />
              </div>
              <div class="Marquee-tag">
                <img src={partnerNine} class="img-fluid" alt="pp-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slides;
