import React, {useEffect} from 'react';
import { Link } from "react-router-dom";

import HeaderTwo from '../common/header/HeaderTwo';
import FooterOne from '../common/footer/FooterOne';


const TermstoUse = () => {

    useEffect(() => {
        document.title = 'Terms to use | CAP Digisoft ';
      }, []);
return (
<div className="ourcompany-page">
    <HeaderTwo />
    <section class="d-flex align-items-center justify-content-center position-relative banner-sec inner inner-sec text-start margin-minus cp-mt">
		<div className="container position-relative z-index-1 custom-cn-width">
			<div className="row align-items-center">
				<div className="col-lg-10 offset-lg-1 my-lg-5 mb-lg-4">
					<h2 class="mb-1">Terms of Use</h2>
					<ol class="breadcrumb f-17 fw-400">
						<li class="breadcrumb-item"><Link to="/" class="text-black">Home</Link></li>
						<li class="breadcrumb-item active text-black" aria-current="page">Terms of Use</li>
					</ol>
				</div>		
			</div>
		</div>
	</section>

    <section className='section-padding'>
		<div className="container custom-cn-width">
			<div className="row g-xl-5 g-4 justify-content-center">
				<div className="col-lg-10">
					<div className="b-r-6 border p-lg-5 p-4 privacy-list text-start">
						<h5 class="mb-3 fw-500">The following Terms of Use will be deemed to have been accepted by the User on usage of the website www.capdigisoft.com. You (the Users) are kindly requested to read the following "Terms of Use" carefully before you use any services of this website.</h5>
						<ul class="terms-list ps-4 mb-0 list-styled">
							<li class="">The term 'User' shall refer to the User/Visitor who is browsing this website. The term 'CDS' shall refer to CDS – CAP Digisoft Solutions. The term 'Website' refers to www.capdigisoft.com owned and monitored by CDS.</li>
							<li class="">CDS is a registered trademark of CAP Digisoft Solutions. This trademark may not be used in any manner without prior written consent from CAP Digisoft Solutions.</li>
							<li class="">By using this website, you (the User) shall agree to follow and be bound by the following "Terms of Use" concerning to your usage of this website. CDS may revise the "Terms of Use" at any time without prior notice to you (the User).</li>
							<li class="">CDS may terminate User's or Visitor's access to the website at any time for any reason. The provisions regarding to accuracy of information disclaimer of warranty, and indemnification shall survive such termination. At all times CDS may monitor every access of all the Users to this website.</li>
							<li class="">All content present on this website is the exclusive property of CDS. The software, text, images, and graphics used on this website belong exclusively to CDS. No material from this website may be modified, copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any form without prior written permission from CDS. All rights not expressly granted herein are reserved by CDS. Unauthorized usage of any materials appearing on this website may violate trademark, copyright, and other applicable laws, and could result in criminal or civil penalties.</li>
							<li class="">The website, and all content, materials, information, software, products and services provided on this website are provided on an "As Is" and "As Available" basis. CDS expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fittingness for a particular purpose and non-infringement.</li>
							<li class="">CDS shall not be in any way responsible for any damage to User's computer system or loss of data that results from the download of any content, materials, information from this website.</li>
							<li class="">CDS may change or discontinue any aspect of its website at any time without prior intimation, including, its content, services or features. CDS wholly reserves the right to change the "Terms of Use" applicable to usage of this website. Such changes shall be effective immediately upon notice, which shall be placed on the website.</li>
							<li class="">In no event will CDS be liable for damages of any kind, including without limitation, direct, incidental or consequential damages (including but not limited to, damages for profits, business interruption and loss of programs or information) arising out of the use of or inability to use CDS's website, or any information provided on the website.</li>
							<li class="">User agrees to indemnify, defend and hold CDS harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on this website.</li>
							<li class="">The information contained in this website has been obtained from sources believed to be reliable. CDS disclaims all warranties as to the accuracy, completeness or adequacy of such information.</li>
							<li class="">CDS makes no warranty that: (a) this website will meet your requirements; (b) this website will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results that may be obtained from the use of this website or any services offered through this website will be accurate or reliable.</li>
							<li class="">The User's right to privacy is of paramount importance to CDS. Any information provided by the User will not be shared with any third party. CDS reserves the right to use the information to provide the User a more custom-made online experience.</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>

    <FooterOne />
</div>
)
}

export default TermstoUse;