import React from "react";
import { Link } from "react-router-dom";
import HeaderDark from "../../../../common/header/HeaderDark";
import FooterOne from "../../../../common/footer/FooterOne";
import banner from "../../../../../src/app/assets/images/cds-blog/banner.jpg";
import Meta from "../../../Meta/Blog";
import blogData from "./blog-data";

const Blogs = () => {
  // Separate blogs based on id
  const specialBlog = blogData.find((blog) => blog.id === 1);
  const regularBlogs = blogData.filter((blog) => blog.id > 1 && blog.id <= 6);
  const otherBlogs = blogData.filter((blog) => blog.id > 6);

  return (
    <>
      <HeaderDark />
      <Meta />
      <div className="blog-list_page">
        {/* <div className="container-fluid pt-0 px-0 margin-minus">
          <div className="blog-banner">
            <img alt="" className="img-fluid rounded-3" src={banner} />
            <h2>BLOG</h2>
          </div>
        </div> */}

        <div className="container custom-cn-width section-padding pt-0">
          <div className="blog-list">
            <div className="row">
              {/* Special Blog */}
              <div className="col-lg-6 col-12 mb-blog">
                <Link to={specialBlog.link}>
                  <div className="special-design">
                    <img
                      src={specialBlog.img}
                      alt="Special Blog Image"
                      className="special-image img-fluid"
                    />
                    <div className="item-blog">
                      <h2>{specialBlog.title}</h2>
                      <div className="blog-list-body">
                        <p>{specialBlog.description}</p>
                      </div>
                      <div className="blog-list-header">
                        <span className="blog-history other-details">{specialBlog.date}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Regular Blogs */}
              <div className="col-lg-6 col-12 mb-blog">
                <div className="featured-blogs">
                  <h3>Featured Posts</h3>
                  <ul className="list-unstyled">
                    {regularBlogs.map((blog) => (
                      <li key={blog.id} className="mb-4 featured-blog-list">
                        <Link to={blog.link}>
                          <h2>
                            {blog.title}
                          </h2>

                          <div className="d-flex">
                            <span className="other-details blog-history">
                              {blog.date}
                            </span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Other Blogs */}
              {otherBlogs.map((blog) => (
                <div key={blog.id} className="col-lg-4 col-md-6 col-12 px-4">
                  <Link to={blog.link} className="other-blog">
                    <img
                      src={blog.img}
                      alt={blog.title}
                      className="blog-image"
                    />
                    <h2>
                      {blog.title.length > 76
                        ? `${blog.title.substring(0, 76)}...`
                        : blog.title}
                    </h2>
                    <p className="blog-description">
                      {blog.description.length > 88
                        ? `${blog.description.substring(0, 88)}...`
                        : blog.description}
                    </p>
                    <span className="other-details">{blog.date}</span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        <FooterOne />
      </div>
    </>
  );
};

export default Blogs;
