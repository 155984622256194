import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/cluedin/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/cluedin/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/cluedin/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/cluedin/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/figma.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/react-native.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/hireharmony.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/iledgers.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/J&p.png";

const Cluedin = () => {
  useEffect(() => {
    document.title = "Clued.in Closet | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div className="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div className="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Clued.in Closet</h2>
          <p class="text-white text-center pb-4 pt-3 px-4">
            Efficiently organize your closet within the app.
          </p>
          <div className="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div className="about py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-xl-3 col-md-4 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Clued.in Closet?</p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12">
              <p className="p-4 p-lg-0">
                Clued.in Closet offers an efficient closet management and outfit
                planning. Seamlessly schedule your outfits and receive daily
                suggestions tailored to your local weather, thanks to our
                advanced AI. The integration with the Clued.in mirror takes your
                experience a step further, allowing you to visually assess how
                your chosen outfit fits. Experience convenience, style, and
                confidence in your daily wardrobe choices with this all-in-one
                solution.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="picture">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                Professionals with hectic schedules often find it challenging to
                organize and plan their wardrobes. AI-driven closet management
                apps simplify the process, offering quick outfit suggestions
                based on their daily agenda and local weather.
              </p>
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                Introducing Clued.in Closet our innovative closet management app
                powered by AI, designed to simplify and elevate the wardrobe
                experience for fashion enthusiasts. Users can effortlessly plan,
                schedule, and explore diverse looks while staying connected to
                the latest fashion trends.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="picture1">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="impact py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12  ">
              <ul class="impact pt-2 list-styled">
                <li>Closet Organization</li>
                <li>Outfit Scheduling</li>
                <li>Weather-Based Outfit Suggestions</li>
                <li>Time-Saving and Convenience</li>
                <li>Fashion Inspiration</li>
                <li>Clued.in Mirror Integration</li>
                <li>Personalized Styling</li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-12 pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-0 pt-lg-5">
              <div className="impact-img4 d-flex flex-wrap align-items-center">
                <div className="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12  pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p className="pt-1 pt-lg-4 p-4 p-lg-0">
                Clued.in Closet mobile application aims to simplify and enhance
                the user's daily clothing decisions, offering convenience, time
                savings, and a personalized approach to fashion through
                intelligent closet management and outfit suggestions. The
                integration with the Clued.in mirror adds a novel dimension to
                the user experience by allowing them to visually assess outfit
                choices.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-cases pb-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center py-4 g-4">
            <div className="col-lg-4 col-sm-12">
              <Link to="/hireharmony">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Hireharmony</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    Hire Harmony is a web application based on hiring process of
                    an organization.
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12 ">
              <Link to="/iledgers">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">iLedgers</h4>
                  </div>

                  <p class=" pt-2">Truck Fault Inspection</p>

                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/j&p_services+">
                <div className="case rounded-3 h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">J&P services+</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    Allows guests in J&P-partnered hotels to check in, request
                    services.
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Cluedin;
