import React from "react";

const Hero = () => {
  return (
    <>
      <style jsx>{``}</style>

      {/* Your App Journey Section Start Here */}
      <div className="wg-result bg-black cust-padding border-line-btm">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="content text-center">
              <div className="heading-section wow fadeInUp style-2">
                <h5 class="mb-2 fw-400 text-gradient">
                  We Bring to Your App Journey
                </h5>
                <div className="main-title sectiontitle-elements">
                  <h2 class="fw-normal text-white mb-0 pb-0">
                    What Value Do We Add?
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="cust-width">
                <ul class="value-sec-list">
                  <li>
                    <b class="text-gradient">Refinement and Cost-Efficiency:</b>{" "}
                    Use Idea Booster to sharpen your project's focus and ensure
                    efficient use of your budget.
                  </li>
                  <li>
                    <b class="text-gradient">
                      Early User Feedback Integration:
                    </b>{" "}
                    Avoid costly development mistakes by validating features
                    with real user insights from the start.
                  </li>
                  <li>
                    <b class="text-gradient">Focus on What Matters:</b> Our
                    method ensures your project concentrates on functionalities
                    that truly resonate with your audience.
                  </li>
                  <li>
                    <b class="text-gradient">
                      Deep Dive into User Requirements:
                    </b>{" "}
                    Uncover new opportunities and gain a deep market
                    understanding to align with user needs and expectations.
                  </li>
                  <li>
                    <b class="text-gradient">Leverage Proven Success:</b>{" "}
                    Benefit from our experience in launching successful
                    products, utilizing insights on captivating functionalities,
                    user confidence strategies, and optimal technologies.
                  </li>
                  <li>
                    <b class="text-gradient">Innovate with Confidence:</b> Save
                    significantly while pushing the boundaries of innovation,
                    ensuring your offering deeply connects with target users and
                    distinguishes itself in a competitive market.
                  </li>
                  <li class="mb-0">
                    <b class="text-gradient">
                      Strategic, User-Centered Development:{" "}
                    </b>
                    Achieve a development process that's not just financially
                    prudent but also positions you as a leader in innovation and
                    market relevance.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>{`
        .value-sec-list {
          padding-left: 2rem;
        }
      `}</style>
    </>
  );
};

export default Hero;
