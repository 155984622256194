import React, {useEffect} from "react";
import HeaderOne from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";
import thankYou from "../../../../src/app/assets/images/thank/thank-you.jpg";
// import pdt10 from '../../../src/app/assets/images/products/10.jpg';



function ThankYouPage() {
  useEffect(() => {
    document.title = 'Thank You | CAP Digisoft ';
  }, []);
  return (
    <>
      <HeaderOne />
      <section className="d-flex align-items-center justify-content-center position-relative banner-sec inner mt-80 text-center text-lg-start thank-you-sec">
        <div className="themesflat-container">
          <div className="home-cta mt-4 bg-light-green">
            <div className="row g-lg-5 g-4 justify-content-center">
              <div className="col-lg-6">
                <div>
                  <img src={thankYou} alt="Thank you for contacting us" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 p-4 pt-0 pt-lg-4 pb-0 pb-lg-4">
                <h1 className="fw-light">Thank you for contacting us</h1>
                <h5 className="mt-3 mt-lg-4 fw-400">We appreciate your interest in choosing our service, will look forward to work with you.</h5>
                <h5 className="mt-2 mt-lg-3 fw-400">Our representative will contact you shortly.</h5>

                
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </>
  );
}

export default ThankYouPage;
