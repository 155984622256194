import React, { useEffect } from "react";
import HeaderOne from "../../../common/header/HeaderOne";
import FooterOne from "../../../common/footer/FooterOne";
import { Link } from "react-router-dom";


// slides thumbnail imports
import slide1 from "../../../app/assets/images/slides/cap-introduction.jpg";
import slide2 from "../../../app/assets/images/slides/idea-to-product.jpg";
import slide3 from "../../../app/assets/images/slides/legal-managed-services.jpg";
import slide4 from "../../../app/assets/images/slides/data-annotation-service.jpg";
import slide5 from "../../../app/assets/images/slides/mobile-software-development.jpg";
import slide6 from "../../../app/assets/images/slides/marketing-and-consulting-service.jpg";
import slide7 from "../../../app/assets/images/slides/client-success-service.jpg";
import slide8 from "../../../app/assets/images/slides/nearshore-call-center-outsourcing.jpg";
import slide9 from "../../../app/assets/images/slides/comprehensive-website-graphical-design.jpg";
import slide10 from "../../../app/assets/images/slides/staffing-solution.jpg";
import slide11 from "../../../app/assets/images/slides/b2b-lead-generation.jpg";
import slide12 from "../../../app/assets/images/slides/knowledge-process-outsourcing.jpg";
import slide13 from "../../../app/assets/images/slides/attesta-ai.jpg";
import slide14 from "../../../app/assets/images/slides/service-offering.jpg";
import slide15 from "../../../app/assets/images/slides/managed-services.jpg";
import slide16 from "../../../app/assets/images/slides/rcm.jpg";
import slide17 from "../../../app/assets/images/slides/Fura.jpg";
import slide18 from "../../../app/assets/images/slides/cap-service-brief.jpg";
import slide19 from "../../../app/assets/images/slides/edexa.jpg";
import slide20 from "../../../app/assets/images/slides/attesta-ai2.jpg";

// SlideCard Component
const SlideCard = ({ image, title, link, lastUpdated }) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-12 d-flex">
      <Link to={link} target="_blank" rel="noreferrer" aria-label={`View ${title}`}>
        <div className="card h-100 video-card video-card1">
          <img src={image} className="card-img-top" alt={`Slide for ${title}`} />
          <div className="card-body p-3 d-flex flex-column justify-content-between h-100">
            <h5 className="card-title">{title}</h5>
            <div className="d-flex justify-content-between">
              <p className="small text-dark">Last Updated:</p>
              <p className="small text-dark">{lastUpdated}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};



const slidesData = [
  {
    image: slide1,
    title: "CAP Introduction",
    link: "https://docs.google.com/presentation/d/1RSfbUL9FO3HqKhgt63iTdzZLyGgCZi1zgA5UM0kRTXI/edit#slide=id.g29dff46de72_2_53",
    lastUpdated: "Nov 18, 2023",
  },
  {
    image: slide2,
    title: "Idea to Product",
    link: "https://docs.google.com/presentation/d/1s3wga7NMyRdHZhOG12PZhCkVzh_P2BxjX4oZNLXKvI8/edit#slide=id.p1",
    lastUpdated: "Nov 24, 2023",
  },
  {
    image: slide3,
    title: "Legal Managed Services",
    link: "https://docs.google.com/presentation/d/1k25ZHOheFAojzFUORas-qXqT-zUvFeFjFM1hRz0zBuI/edit#slide=id.p1",
    lastUpdated: "Nov 18, 2023",
  },
  {
    image: slide4,
    title: "Data Annotation Service",
    link: "https://docs.google.com/presentation/d/1wYtSkWxa0b2naBm_3SbUwVOlM8ZRlgNPVs0rkovmWMg/edit#slide=id.g261dde1737e_6_187",
    lastUpdated: "Nov 21, 2023",
  },
  {
    image: slide5,
    title: "Mobile App & Software Development",
    link: "https://docs.google.com/presentation/d/1ClhSBn0DSgaGccbR54tU54V-mS0byBHCKj8_zzxa78Q/edit#slide=id.p1",
    lastUpdated: "Nov 24, 2023",
  },
  {
    image: slide6,
    title: "Marketing and Consulting Services",
    link: "https://docs.google.com/presentation/d/1Ue99DL7ol-KPtRRq9TAWlI-6X52pxCaEVwLUZwGsLQo/edit#slide=id.g29e53a4827d_1_99",
    lastUpdated: "Nov 24, 2023",
  },
  {
    image: slide7,
    title: "Client Success Service",
    link: "https://docs.google.com/presentation/d/1BSBdNzXsJeBOMcMzWVPBxqg_MjUIjZulEerxKTZwg1c/edit#slide=id.g29e476460ce_1_32",
    lastUpdated: "Nov 24, 2023",
  },
  {
    image: slide8,
    title: "Nearshore Call Center Outsourcing",
    link: "https://docs.google.com/presentation/d/1XWKZ6BU9nxAZB7N6dkNG3uMQLCaCWi1JDvnp_YaXz_M/edit#slide=id.g29ecba13500_1_82",
    lastUpdated: "Dec 06, 2023",
  },
  {
    image: slide9,
    title: "Comprehensive Website / Graphic Design",
    link: "https://docs.google.com/presentation/d/1m_-u3qBQH0CUe_dPeMeiwvvrn8TnhHa5DVsWMOG8WI4/edit#slide=id.g29f443c76e2_1_130",
    lastUpdated: "Dec 06, 2023",
  },
  {
    image: slide10,
    title: "Staffing Solution",
    link: "https://docs.google.com/presentation/d/1a0djYg5ExP-S6kNoDTo8CSbYRTJBAwSaEBkyinRW7OI/edit#slide=id.g29dfac6c637_15_88",
    lastUpdated: "Dec 06, 2023",
  },
  {
    image: slide11,
    title: "B2B Lead Generation",
    link: "https://docs.google.com/presentation/d/137PkG5CcVQWBAh1mrqZYWb7UrrRo6hAbnxkUQJS0h_s/edit#slide=id.g29f3fec1151_2_121",
    lastUpdated: "Dec 06, 2023",
  },
  {
    image: slide12,
    title: "Knowledge Process Outsourcing",
    link: "https://docs.google.com/presentation/d/1okcAr8EE_sBe9Qw447ptZfAqL5VmxRSTyqSjeUIjouE/edit#slide=id.g2624cc5e692_0_0",
    lastUpdated: "Dec 06, 2023",
  },
  {
    image: slide13,
    title: "AttestaAI",
    link: "https://docs.google.com/presentation/d/103DJEuIMFZVyArpbKnorX6fFbB5OW7so8K41g00uNs4/edit#slide=id.g2b245d3dae4_0_0",
    lastUpdated: "Jan 23, 2024",
  },
  {
    image: slide14,
    title: "Introduction & Service Brief",
    link: "https://docs.google.com/presentation/d/1tQyeZ2Cs9Qju_9VK7N7UEar3Yhv3MnexRQpZLtYZZWc/edit?usp=sharing",
    lastUpdated: "Mar 25, 2024",
  },
  {
    image: slide15,
    title: "Managed Services",
    link: "https://docs.google.com/presentation/d/15QWdpf46QBtW32jHrTislU_4gdu_fba_Nw4VWuWrtds/edit#slide=id.g2c68442e023_0_1730",
    lastUpdated: "Mar 30, 2024",
  },
  {
    image: slide16,
    title: "REVENUE CYCLE MANAGEMENT",
    link: "https://docs.google.com/presentation/d/1iRZpcKV5RdmwMh_l0Cie5Bsx3RbgEf_VG6o9aIWAhIg/edit?usp=sharing",
    lastUpdated: "Oct 16, 2024",
  },
  {
    image: slide17,
    title: "Fura",
    link: "https://docs.google.com/presentation/d/1CxhMY9pHdTD7Cl152el_BaNWle0k8YWOOlsaUD2q1FY/edit#slide=id.p1",
    lastUpdated: "Oct 16, 2024",
  },
  {
    image: slide18,
    title: "CDS Service",
    link: "https://capdigisoft.com/pdf/cap-digisoft-solutions.pdf",
    lastUpdated: "Oct 16, 2024",
  },
  {
    image: slide19,
    title: "Edexa",
    link: "https://capdigisoft.com/pdf/edexa.pdf",
    lastUpdated: "Oct 16, 2024",
  },
  {
    image: slide20,
    title: "AttestaAI",
    link: "https://capdigisoft.com/pdf/attestaai.pdf",
    lastUpdated: "Oct 16, 2024",
  },
];

const ClientSuccess = () => {
  useEffect(() => {
    document.title = "Slides | CAP Digisoft";
  }, []);

  return (
    <div className="slidespage">
      <HeaderOne />
      <section className="case-study-bg d-flex align-items-center justify-content-center inner pt-5 section-padding margin-minus">
        <div className="container">
          <div className="row justify-content-start g-4 g-xl-5">
            <div className="col-lg-12">
              <h6 className="toptitle text-center text-white">CAP Presentations</h6>
              <h1 className="px-lg-4 text-center text-white">Explore our Dynamic Slide Showcase</h1>
              <img src="" alt="" />
            </div>
          </div>
          <div className="row g-4 g-xl-5 mt-1">
            {slidesData.map((slide, index) => (
              <SlideCard
                key={index}
                image={slide.image}
                title={slide.title}
                link={slide.link}
                lastUpdated={slide.lastUpdated}
              />
            ))}
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default ClientSuccess;
