import React from "react";
import { Link } from "react-router-dom";
import HeaderOne from "../../../common/header/HeaderOne";
import FooterOne from "../../../common/footer/FooterOne";
import {
  row1Details,
  row2Details,
  row3Details,
  row4Details,
  row5Details,
} from "./casestudy-data.js";
import Meta from "../../Meta/Case_study.js";

const CaseStudy = () => {
  return (
    <div className="case-study-page">
      <HeaderOne />
      <Meta />
      {/* Group 1 case studies */}
      <section className="case-study-bg d-flex align-items-center justify-content-center banner-sec bg-img inner pt-5 section-padding margin-minus">
        <div className="container custom-cn-width">
          <div className="row align-items-center text-center">
            <div className="col-lg-12">
              <h2 className="pb-lg-5 pb-4 position-relative text-white">
                Case Studies
              </h2>
            </div>
          </div>
          <div className="row text-start justify-content-lg-end justify-content-center case-list">
            <div className="col-12">
              <div className="d-none d-lg-block">
                <div className="row align-items-lg-center justify-content-center d-none d-lg-flex">
                  <div className="col-lg-2 col-md-12 col-sm-12">
                    <div className="row">
                      {row1Details.map((caseStudy, index) => (
                        <div
                          key={index}
                          className="col-lg-12 col-md-6 col-sm-12 last-one">
                          <Link
                            to={caseStudy.path}
                            className="case-box mt- transition">
                            <div className="icon">
                              <img
                                src={caseStudy.image}
                                alt="team"
                                className="img-fluid mb-1 mt-4"
                              />
                            </div>
                            <Link to={caseStudy.path}>{caseStudy.title}</Link>
                            <Link to={caseStudy.path} className="sub-text">
                              {caseStudy.subtext}
                            </Link>
                            <Link
                              className="d-inline-block underline-border mt-4 learn-more"
                              to={caseStudy.path}>
                              <span className="border-line d-inline-block me-1">
                                Learn more
                              </span>
                              <i className="fa fa-arrow-right fa-sm position-relative"></i>
                            </Link>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-12 col-sm-12 ">
                    <div className="row">
                      {row2Details.map((caseStudy, index) => (
                        <div
                          key={index}
                          className="col-lg-12 col-md-6 col-sm-12">
                          <Link
                            to={caseStudy.path}
                            className="case-box mt- transition">
                            <div className="icon">
                              <img
                                src={caseStudy.image}
                                alt="team"
                                className="img-fluid mb-1 mt-4"
                              />
                            </div>
                            <Link to={caseStudy.path}>{caseStudy.title}</Link>
                            <Link to={caseStudy.path} className="sub-text">
                              {caseStudy.subtext}
                            </Link>
                            <Link
                              className="d-inline-block underline-border mt-4 learn-more"
                              to={caseStudy.path}>
                              <span className="border-line d-inline-block me-1">
                                Learn more
                              </span>
                              <i className="fa fa-arrow-right fa-sm position-relative"></i>
                            </Link>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-12 col-sm-12">
                    <div className="row">
                      {row3Details.map((caseStudy, index) => (
                        <div
                          key={index}
                          className="col-lg-12 col-md-6 col-sm-12 last-one">
                          <Link
                            to={caseStudy.path}
                            className="case-box mt- transition">
                            <div className="icon">
                              <img
                                src={caseStudy.image}
                                alt="team"
                                className="img-fluid mb-1 mt-4"
                              />
                            </div>
                            <Link to={caseStudy.path}>{caseStudy.title}</Link>
                            <Link to={caseStudy.path} className="sub-text">
                              {caseStudy.subtext}
                            </Link>
                            <Link
                              className="d-inline-block underline-border mt-4 learn-more"
                              to={caseStudy.path}>
                              <span className="border-line d-inline-block me-1">
                                Learn more
                              </span>
                              <i className="fa fa-arrow-right fa-sm position-relative"></i>
                            </Link>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-12 col-sm-12">
                    <div className="row">
                      {row4Details.map((caseStudy, index) => (
                        <div
                          key={index}
                          className="col-lg-12 col-md-6 col-sm-12">
                          <Link
                            to={caseStudy.path}
                            className="case-box mt- transition">
                            <div className="icon">
                              <img
                                src={caseStudy.image}
                                alt="team"
                                className="img-fluid mb-1 mt-4"
                              />
                            </div>
                            <Link to={caseStudy.path}>{caseStudy.title}</Link>
                            <Link to={caseStudy.path} className="sub-text">
                              {caseStudy.subtext}
                            </Link>
                            <Link
                              className="d-inline-block underline-border mt-4 learn-more"
                              to={caseStudy.path}>
                              <span className="border-line d-inline-block me-1">
                                Learn more
                              </span>
                              <i className="fa fa-arrow-right fa-sm position-relative"></i>
                            </Link>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-12 col-sm-12 ">
                    <div className="row">
                      {row5Details.map((caseStudy, index) => (
                        <div
                          key={index}
                          className="col-lg-12 col-md-6 col-sm-12 last-one">
                          <Link
                            to={caseStudy.path}
                            className="case-box mt- transition">
                            <div className="icon">
                              <img
                                src={caseStudy.image}
                                alt="team"
                                className="img-fluid mb-1 mt-4"
                              />
                            </div>
                            <Link to={caseStudy.path}>{caseStudy.title}</Link>
                            <Link to={caseStudy.path} className="sub-text">
                              {caseStudy.subtext}
                            </Link>
                            <Link
                              className="d-inline-block underline-border mt-4 learn-more"
                              to={caseStudy.path}>
                              <span className="border-line d-inline-block me-1">
                                Learn more
                              </span>
                              <i className="fa fa-arrow-right fa-sm position-relative"></i>
                            </Link>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-lg-center justify-content-center d-lg-none">
                {/* Combine all row1 to row5 details in a single row */}
                {[
                  ...row1Details,
                  ...row2Details,
                  ...row3Details,
                  ...row4Details,
                  ...row5Details,
                ].map((caseStudy, index) => (
                  <div key={index} className="col-md-6 col-sm-6">
                    <Link
                      to={caseStudy.path}
                      className="case-box mt- transition">
                      <div className="icon">
                        <img
                          src={caseStudy.image}
                          alt="team"
                          className="img-fluid mb-1 mt-4"
                        />
                      </div>
                      <Link to={caseStudy.path}>{caseStudy.title}</Link>
                      <Link to={caseStudy.path} className="sub-text">
                        {caseStudy.subtext}
                      </Link>
                      <Link
                        className="d-inline-block underline-border mt-4 learn-more"
                        to={caseStudy.path}>
                        <span className="border-line d-inline-block me-1">
                          Learn more
                        </span>
                        <i className="fa fa-arrow-right fa-sm position-relative"></i>
                      </Link>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0">
        <div className="container custom-cn-width">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-xxl-7 col-xl-8 col-lg-9">
              <h1 className="fw-light mb-lg-4 mb-3 text-dark">
                Discover the CAP Digisoft Difference
              </h1>
              <p className="px-lg-5 text-dark font-size-17">
                Our highly-skilled, cost-effective, expert global resources
                bring the highest quality development standards at competitive
                pricing without the need to make any compromises.
              </p>
              <Link
                to="/javascript:;"
                className="btn cust-btn-primary secondary-main mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0 section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12">
              <hr className="my-0" />
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default CaseStudy;
