import React, {useEffect} from 'react';
import { Link } from "react-router-dom";

import HeaderTwo from '../common/header/HeaderTwo';
import FooterOne from '../common/footer/FooterOne';

const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = 'Privacy Policy | CAP Digisoft ';
      }, []);
return (
<div className="ourcompany-page">
	<HeaderTwo />
	<section
		class="d-flex align-items-center justify-content-center position-relative banner-sec inner inner-sec text-start margin-minus cp-mt">
		<div className="container position-relative z-index-1 custom-cn-width">
			<div className="row align-items-center">
				<div className="col-lg-10 offset-lg-1 my-lg-5 mb-lg-4">
					<h2 class="mb-1">Privacy Policy</h2>
					<ol class="breadcrumb f-17 fw-400">
						<li class="breadcrumb-item"><Link to="/" class="text-black">Home</Link></li>
						<li class="breadcrumb-item active text-black" aria-current="page">Privacy Policy</li>
					</ol>
				</div>
			</div>
		</div>
	</section>

	<section className='section-padding'>
		<div className="container custom-cn-width">
			<div className="row g-xl-5 g-4 justify-content-center">
				<div className="col-lg-10">
					<div className="b-r-6 border p-lg-5 p-4 privacy-list text-start">
						<p>At CDS we recognize that privacy is crucial. This document outlines the types of personal
							information we receive and collect when you use CDS's services, as well as some of the steps
							we take to safeguard your information. We hope this will help you make an informed decision
							about sharing personal information with us.</p>
						<p>In this document we highlight CDS Privacy Policy, which describes in detail the privacy
							practices that apply to CDS's BPO and IT services.</p>
						<p>We can be reached via e-mail at <span class="color">info@capdigisoft.com or you can reach us
								by Telephone/Fax</span> at any of the above given numbers. Any and every user/visitor to
							our Website/Web page is traceable; our Web server automatically recognizes the consumer's
							domain name and e-mail address (where possible).</p>
						<p>We collect the domain name and e-mail addresses (where possible) of users/visitors to our Web
							page, the e-mail addresses of those who communicate with us via e-mail, the e-mail addresses
							of those who aggregate information on what pages consumers' access or visit, and information
							on consumers who browse our Web page.</p>
						<p>The information we collect is used to improve the content of our Web page, used to customize
							the content and/or layout of our Web site for each individual user/visitor, used to notify
							consumers about updates to our Web site and used by us to contact consumers.</p>
						<h5 class=" mt-4 mb-2">With Respect To Cookies:</h5>
						<p>We do not set any cookies.</p>
						<p>If you have any query with respect to our BPO and IT services, please let us know by sending
							us e-mail at the above address, calling us at the above Telephone/Fax number or writing to
							us at the above addresses.</p>
						<p>Persons who supply us with their e-mail addresses, telephone/fax numbers will be secured and
							cannot be accessed by third parties.</p>
						<h5 class=" mt-4 mb-2">With Respect To Web Server: </h5>
						<p>From time to time, we may use customer information for new, unanticipated uses not previously
							disclosed in our privacy notice. If our information practices change at some time in the
							future, we will post the policy changes to our Web site to notify you of these changes and
							provide you with the ability to opt out of these new uses. If you are concerned about how
							your information is used, you should check back at our Web site periodically.</p>
						<p>Customers may prevent their information from being used for purposes other than those for
							which it was originally collected by e-mailing us at the above email ID or writing us at the
							above postal address.</p>
						<p>Upon request we provide site visitors with access to no information that we have collected
							and that we maintain about them.</p>
						<p>Upon request we offer visitors no ability to have factual inaccuracies corrected in
							information that we maintain about them.</p>
						<h5 class=" mt-4 mb-2">With Respect To Security:</h5>
						<p>When we transfer and receive any type of sensitive data/information, we redirect visitors to
							a secure server and will notify visitors through a pop-up screen on our site. We have
							appropriate security measures in place in our physical facilities to protect against the
							loss, misuse or alteration of information that we have collected from you at our site.</p>
						<h5 class=" mt-4 mb-2">With Respect To Request For SEO (Search Engine Optimization) Reviews:
						</h5>
						<p>SEO reviews give us an opportunity to demonstrate to our visitors how search engine
							optimization works, how it can help their website, and to give them an idea of how we work
							and what sorts of things we are looking at. In requesting a review, we require some personal
							and site information such as contact information, website address, targeted keyword phrases,
							etc. This information is used for no other purpose than to provide those requesting one with
							a custom search engine positioning review.</p>
						<h5 class=" mt-4 mb-2">With Respect To Privacy For Our SEO Clients:</h5>
						<p>There is no more serious offences that can be made by a search engine optimization firm that
							to share the information given to them by a client with those outside the company. We
							realize that the information that we receive as SEO's can be extremely confidential and
							often includes credit card information, FTP information and more. This information will be
							kept highly confidential and will be given to staff solely on a need-to-know basis (for
							example, the SEO working on your site will need FTP access to your server).</p>
						<p>If you feel that this Web site is not following it's stated information policy, you may
							contact us at the above addresses or telephone/fax numbers.</p>
					</div>
				</div>
			</div>
		</div>
	</section>

	<FooterOne />
</div>
)
}

export default PrivacyPolicy;