import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

const GenAIMeta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = 'Unlocking Potential with Capdigisoft: Leaders in Generative AI Technology'; // Reset title if necessary
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <>
      <Helmet>
      <title>Unlocking Potential with Capdigisoft: Leaders in Generative AI Technology</title>
        <meta
          name="description"
          content="Capdigisoft pioneers Generative AI solutions, pushing the boundaries of innovation. Explore our cutting-edge technology for smarter, more creative outcomes."
        />
        <meta name="author" content="https://capdigisoft.com" />
        <meta name="robots" content="index, follow" />

        <link rel="alternate" hreflang="x-default" href="https://capdigisoft.com/generative-ai" />
        <link rel="canonical" href="https://capdigisoft.com/generative-ai" />

        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Unlocking Potential with Capdigisoft: Leaders in Generative AI Technology"
        />
        <meta property="og:url" content="https://capdigisoft.com/generative-ai" />
        <meta
          property="og:image"
          content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
        />
        <meta
          property="og:description"
          content="Elevate your projects with Capdigisoft expertise in Generative AI. Unleash creativity and efficiency through our advanced artificial intelligence solutions."
        />

        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "capdigisoft",
            "url": "https://capdigisoft.com/",
            "logo": "https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png",
            "sameAs": [
              "https://www.facebook.com/capdigisoftsolutions/",
              "https://x.com/CAPDigisoft",
              "@capdigisoftsolutions",
              "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw",
              "https://www.linkedin.com/company/cap-digisoft-solutions-inc"
            ]
          }
          `}
        </script>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Capdigisoft",
                "item": "https://capdigisoft.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "generative-ai",
                "item": "https://capdigisoft.com/generative-ai.php"
              }
            ]
          }
          `}
        </script>
      </Helmet>
    </>
  );
};

export default GenAIMeta;
