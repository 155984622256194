import React, { useState, useEffect } from "react";
import "../../../../app/assets/css/plug-and-play.css";
import HeaderLight from "../../../../common/header/HeaderLight";
import FooterOne from "../../../../common/footer/FooterOne";

// Import Sections
import Banner from "./banner";
import Slides from "./slide";
import Partner from "./partner";
import KeyChallenges from "./keyChallenges";
import Challenges from "./challenge";
import BestPractices from "./bestPractice";
import Tailored from "./tailoredSolution";
import Testimonial from "./testimonial";
import CTA from "./callToAction";
import Tech from "./tech";
import Certificate from "./certificate";

//import form
import Bookameeting from "../../../pages/Form/bookmeeting";
import CustomerExcellence from "./customerExcellence";

import Meta from "../../../Meta/call-center.js";

const CallCenter = () => {
  useEffect(() => {
    document.title = "Optimize Customer Support: Expert Call Center Service";
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="plug-play-page">
        <HeaderLight />
        <Meta />
        <Banner />
        <Slides />
        <KeyChallenges />
        <Challenges />
        <Partner />
        <CustomerExcellence />
        <BestPractices />
        <Tailored />
        <Testimonial />
        <Certificate />
        <Tech />
        <CTA />
        <FooterOne />
      </div>

      <style jsx>{`
        p {
          font-size: 18px !important;
        }
        .cust-pad-btm-100 {
          padding-bottom: 100px;
        }
        .plug-play-page .title {
          font-size: 47px;
          margin-bottom: 10px;
          line-height: 1.2;
          text-transform: none;
        }
        @media screen and (max-width: 1199px) {
          .grad-cont-para {
            padding: 0px;
          }
          .hero-para {
            padding: 0px 40px;
          }
          .end-cta-sec p {
            padding: 0;
          }
          .strategy-sec p {
            padding: 0;
            margin-bottom: 30px;
          }
          .cust-sec-wrapper {
            padding-right: 0;
          }
          .strm-cont {
            padding-left: 0;
          }
          .hero-para {
            padding: 0px;
          }
        }
        @media (max-width: 991px) {
          .cert-cont p {
            padding-right: 0px;
          }
          .cust-padding {
            padding: 70px 0;
          }
          .padding-plug {
            padding: 30px 0 70px;
          }
          .plug-play-main-wrapper {
            display: none;
          }
          .plug-play-wrapper {
            display: block;
            width: 50%;
          }
        }
        @media (max-width: 767px) {
          .cust-sticky {
            position: static !important;
            width: 100% !important;
            padding: 0;
          }
          .main-side-content,
          aside {
            float: unset;
            width: 100%;
          }
          .main-side-content {
            padding: 50px 0 0;
          }
          .wu-serv {
            margin-left: 0;
          }
          .typing-effect p {
            font-size: 42px;
            line-height: normal;
          }
          .hero-gradient-wrapper {
            display: none;
          }
          .typing-effect p span.typed-text {
            height: auto;
          }
          .ai-card {
            flex-direction: column;
          }
          .card-grad-1 {
            margin-right: 0px;
            margin-bottom: 24px;
          }
          .glass-box {
            padding: 25px;
          }
          .counter-sec {
            display: flex;
            justify-content: space-between;
          }
          .counter-sec {
            margin-bottom: 20px;
          }
          .why-sec-count {
            margin-top: 40px;
            display: block;
          }
          .social-links {
            justify-content: center;
          }

          .cust-padding {
            padding: 50px 0;
          }
        }
        @media screen and (max-width: 575px) {
          .plug-play-page .header_area {
            padding-top: 0px;
          }
          .main-cont {
            border-radius: 0px;
            border-bottom: 1px solid #14444d;
          }

          .plug-play-page .title {
            font-size: 28px;
          }
          .typing-effect p {
            font-size: 42px !important;
            line-height: normal;
          }
          .header_area {
            padding-top: 0px;
          }
        }

        .plug-play-page .dark-grad p {
          font-size: 14px !important;
        }
      `}</style>

      {/* Modal Section Start Here */}
      <div
        className="modal fade"
        id="meeting"
        tabindex="-1"
        aria-labelledby="apply-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Book a meeting</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Bookameeting />
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default CallCenter;
