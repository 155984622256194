/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from 'react-router-dom';


import HeaderLight from "../../../../common/header/HeaderOne";
import FooterOne from "../../../../common/footer/FooterOne";

const products = () => {
  return (
    <>

<h4 className="text-dark fw-500 pb-2 fs-4">Recent Blogs</h4>
        <ul className="list-unstyled ps-0 mb-4">
       <Link to="/marketing-strategies-for-the-age-of-everything-online" className="rec-blog-hov"><li>Marketing Strategies for the Age of Everything Online</li></Link >
       <Link to="/the-impact-of-knowledge-processing-on-business-intelligence" className="rec-blog-hov"><li>The Impact of Knowledge Processing on Business Intelligence</li></Link >
       <Link to="/the-importance-of-ui-ux-design-in-todays-digital-landscape" className="rec-blog-hov"><li>The Importance of UI/UX Design in Today's Digital Landscape</li></Link >
       <Link to="/the-office-evolved-how-technology-is-reshaping-the-workplace" className="rec-blog-hov"><li>The Office Evolved: How Technology is Reshaping the Workplace</li></Link >
       <Link to="/optimizing-customer-service-with-ai-powered-solutions" className="rec-blog-hov"><li style={{border: 'none'}}>Optimizing Customer Service with AI-powered Solutions
</li></Link >
         
        </ul>
    
    </>
       
     
  );
};

export default products;
