import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/mergers/banner.jpg";

import icon1 from "../../../../src/app/assets/images/casestudy/mergers/icon1.png";
import icon2 from "../../../../src/app/assets/images/casestudy/mergers/icon2.png";
import icon3 from "../../../../src/app/assets/images/casestudy/mergers/icon4.png";
import icon4 from "../../../../src/app/assets/images/casestudy/mergers/icon5.png";

import PDF from "../../../app/assets/casestudypdf/e-sort-desk-case-study.pdf";

const Mergers = () => {
  useEffect(() => {
    document.title = "Mergers and acquisition firm | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-mergers-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div div className="container custom-cn-width position-relative">
          <div div className="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div div className="col-lg-9">
              <h5 class="text-white">Case Study</h5>
              <h1 class="text-white fw-semibold">
                Merger and Acquisition Firm
              </h1>
            </div>
            <div div className="col-lg-3 text-lg-end">
              <a
                href={PDF}
                class="default-btn orange-btn mt-lg-4"
                target="_blank" rel="noreferrer">
                <i class="fa fa-file-pdf me-1"></i>
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div div className="container custom-cn-width">
          <div div className="row justify-content-center g-lg-5 g-4">
            <div div className="col-lg-7 col-md-12">
              <h1 class="fw-light mb-lg-4 mb-3">Overview</h1>
              <p class="mt-3">
                Even with a successful company with a business model that works,
                it’s still possible to get results that don’t meet expectations.
                It’s a refrain often heard among business owners and can simply
                be indicative of a lack of experience or resources in a
                particular area like marketing or lead generation, or a could be
                a gap in strategy for things like lead nurturing or buyer
                enablement.
              </p>
              <p class="mt-3">
                Flobile’s first step in determining solutions in situations like
                this is to asses where those gaps may be. It’s not a coincidence
                that, according to Salesforce,{" "}
                <span class="text-bold">68% of companies</span> have not even
                made an attempt to measure their sales funnels and almost
                <span class="text-bold">79% of marketing leads</span> are never
                converted into sales finding and filling those gaps is key to
                building a successful sales strategy.
              </p>
              <p class="mt-3">
                After a thorough assessment of current strategy, content, and
                prospect data Flobile was able to partner with the inside sales
                team so that <span class="text-bold">Flobile’s expertise</span>{" "}
                could be used in critical areas to pave those gaps into a
                smooth, reliable
                <span class="text-bold">pathway to success</span>. Flobile
                cleansed prospect data and implemented data governance to keep
                that data current, as well as developed an email marketing
                strategy and buyer enablement tools to assist the buyer’s
                journey through the sale.
              </p>
            </div>
            <div div className="col-lg-5 col-md-9">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 text-center text-lg-start section-padding pt-0">
        <div div className="container custom-cn-width">
          <div div className="row g-xl-5 g-4">
            <div div className="col-lg-7 mb-4 mb-lg-5">
              <h1 class="fw-light">CDS Solution Approach</h1>
            </div>
          </div>
          <div div className="row g-xl-5 g-4">
            <div div className="col-md-6 col-lg-4">
              <div div className="features-item h-100 justify-content-start">
                <div div className="icons sec-primary-ic mb-4 mx-auto mx-lg-0  d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon1}
                    class="img-fluid"
                    alt="Prospect Database Cleansing"
                  />
                </div>
                <h4>Prospect Database Cleansing</h4>
                <p class="mt-3">
                  With unmaintained prospect data and no prospect history to
                  speak of, Flobile’s data scientists to do a thorough cleaning
                  and verification of their lead database to make sure contact
                  information was valid and they were reaching the right
                  prospects by improving not only the total number of leads but
                  also the number of qualified leads.
                </p>
              </div>
            </div>
            <div div className="col-md-6 col-lg-4">
              <div div className="features-item h-100 justify-content-start">
                <div div className="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="Prospect Database Governance"
                  />
                </div>
                <h4>Prospect Database Governance</h4>
                <p class="mt-3">
                  Flobile utilized our data governance service to make sure
                  their contact data was continuously updated and verified to
                  make sure it retains its integrity over time.
                </p>
              </div>
            </div>
            <div div className="col-md-6 col-lg-4">
              <div div className="features-item h-100 justify-content-start">
                <div div className="icons mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center sec-primary-ic rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="Email Marketing Strategy"
                  />
                </div>
                <h4>Email Marketing Strategy</h4>
                <p class="mt-3">
                  Flobile created a customer email nurture strategy as well as
                  developed a custom dashboard to track campaigns with analytics
                  tools to make campaign management decisions easy.
                </p>
              </div>
            </div>
            <div div className="col-md-6 col-lg-4">
              <div div className="features-item h-100 justify-content-start">
                <div div className="icons mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center sec-primary-ic rounded-pill">
                  <img
                    src={icon3}
                    class="img-fluid"
                    alt="Email Marketing Strategy"
                  />
                </div>
                <h4>Buyer Enablement</h4>
                <p class="mt-3">
                  Flobile created buyer enablement tools including to increase
                  awareness, make the buyer’s journey easier, and become a new
                  source of lead generation.
                </p>
              </div>
            </div>
            <div div className="col-md-6 col-lg-4">
              <div div className="features-item h-100 justify-content-start">
                <div div className="icons mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center sec-primary-ic rounded-pill">
                  <img
                    src={icon4}
                    class="img-fluid"
                    alt="Email Marketing Strategy"
                  />
                </div>
                <h4>Email ID Verification</h4>
                <p class="mt-3">
                  Before sending emails Flobile verify that the addresses are
                  valid.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class=" text-center text-lg-start section-padding">
        <div div className="container custom-cn-width">
          <div div className="row g-lg-0 g-4 justify-content-center">
            <div div className="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div div className="p-lg-5 p-4">
                <h1 class="fw-light text-white">Results</h1>
                <p class="mt-3 text-white mb-3">
                  In our initial trial period, we brought in{" "}
                  <span class="text-bold">3x results</span> compared to their
                  existing vendor and afterward were able to{" "}
                  <span class="text-bold">achieve a 2x increase</span> on a
                  sustained basis.
                </p>
                <div div className="case-study-count row g-3 g-xl-0">
                  <div div className="col-lg-12 text-center">
                    <h1 class="fw-light text-white">3x</h1>
                    <p class="text-white lh-sm px-lg-1">
                      Increase in results compared to the existing vendor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div div className="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div div className="p-lg-5 p-4">
                <h1 class="fw-light  mb-3">Conclusion</h1>
                <h4 class="mt-3 ">Prospect Database Cleansing</h4>
                <p class="mt-3">
                  Even for established businesses, it makes sense to find a
                  partner who can
                  <span class="text-bold">complement your strengths</span> with
                  expertise in areas that would require a large investment to
                  replicate with an in-house team. Flobile has the resources and
                  expertise in areas like prospect database cleansing and
                  governance, marketing strategy, buyer enablement and more that
                  makes them the reliable partner you need to{" "}
                  <span class="text-bold">drive your business’ success</span> to
                  the next level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div div className="container custom-cn-width">
          <div div className="row g-5 align-items-center justify-content-center text-center">
            <div div className="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div div className="container">
          <div div className="row align-items-center justify-content-center">
            <div div className="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div div className="container custom-cn-width">
          <div div className="row g-lg-5 g-4 text-center">
            <div div className="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Case Studies</h1>
            </div>
          </div>
          <div div className="">
            <div div className="row g-4 g-xl-5 text-center text-xl-start">
              <div div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">Medico – View Desk</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/medico-view-desk-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">Logistics Company</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/logistics-company">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Workflow Management System
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/workflow-management-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default Mergers;
