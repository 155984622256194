import React from "react";

const CTA = () => {
  return (
    <>
      {/* ------------- certificate end --------- */}

      <section className="text-center text-lg-start bg-white cust-padding cust-pad-btm-100">
        <div className="container  custom-cn-width  custom-cn-width">
          <div className="cta-block cta-blue b-r-6">
            <div className="row g-xl-5 g-4 align-items-center justify-content-center">
              <div className="col-12 col-xl-8">
                <div class="main-title sectiontitle-elements">
                  <h2 class="fw-normal wow animate__bounceInn mb-4 animated text-light">
                    Complimentary, No-Obligation Trial
                  </h2>
                </div>

                <p className="text-white">
                  We offer qualified prospects an opportunity to experience our
                  solutions firsthand, absolutely free of charge and without any
                  obligations. This trial is our way of demonstrating the
                  tangible benefits our services can bring to your business.
                  Don't hesitate - schedule a meeting with us today, and let's
                  embark on a journey toward your company's growth together.
                </p>

              </div>
              <div className="col-12 col-xl-4 text-center text-lg-end">
                <a
                  href="#javascript;"
                  className="default-btn solution_class_popu"
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                  form_title="Mobile &amp; Software Development">
                  Book your meeting!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <style jsx>{`
        .gradient-aqua {
          left: 0;
          top: 60px;
          width: 250px;
          height: 250px;
          background: radial-gradient(
            circle at 50% 50%,
            #00d0ff,
            rgba(76, 0, 255, 0)
          );
          opacity: 0.9;
        }

        .gradient-aqua,
        .hero-gradient-wrapper {
          position: absolute;
          border-radius: 50% 22% 40% 80%;
          filter: blur(100px);
          z-index: 0;
        }

        .glass-box {
          background: #224452;
          border-radius: 16px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          -webkit-backdrop-filter: blur(5px);
          border: 1px solid #969696;
          padding: 50px;
        }

        .cust-hero-card {
          border: none;
          height: 100% !important;
          padding: 5px;
          border-radius: 10px;
        }

        .cert-cont p {
          padding-right: 70px;
        }

        .dark-grad {
          background-image: linear-gradient(to right, #396577, #234451);
        }

        .cert-img {
          width: 50px;
        }

        .cust-text-format p {
          font-size: 14px;
          font-weight: 500;
          color: #000 !important;
        }
      `}</style>
    </>
  );
};

export default CTA;
