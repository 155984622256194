import React from "react";

const Hero = () => {
  return (
    <>
      <style jsx>{``}</style>
      {/* Confidently realize app ideas Section Start Here */}
      <div className="wg-result cust-padding-2 border-line-btm">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="content text-center">
              <div className="heading-section sectiontitle-element--center wow fadeInUp style-2">
                <h5 class="mb-2 fw-400">
                  Confidently realize app ideas
                </h5>
                <div className="main-title sectiontitle-elements">
                  <h2 class="fw-normal mb-0 pb-0">
                    Bringing Your App Ideas to Life with Confidence
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="cust-width">
                <p class="mb-3 text-center text-dark">
                  Addressing the daunting statistic that only 0.5% of apps
                  succeed, CAP Digisoft’s Idea Booster and Idea to Product, are
                  designed to navigate through the common pitfalls of app
                  development such as lack of product-market fit, inadequate
                  marketing strategy, and weak business infrastructure. Idea
                  Booster fine-tunes your app idea with targeted user feedback,
                  ensuring it meets market demands and stands out, while Idea to
                  Product transforms these validated concepts into market-ready
                  apps, integrating development, marketing, and strategic
                  business planning.
                </p>
                <p class="mb-0 text-center text-dark">
                  Our programs are bolstered by CAP Digisoft's ISO 27001 and
                  HIPAA certifications, emphasizing our commitment to quality
                  and data security in bringing your app to life. Through a
                  combination of expert guidance, strategic development, and
                  comprehensive market analysis, we aim to elevate your app into
                  the successful 0.5%, ensuring it is not only developed with
                  precision but also positioned for market success and growth.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default Hero;
