import React, { useEffect } from "react";
import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";
import pagenotfound from "../../../app/assets/images/pagenotfound/404.jpg";
import { Link } from "react-router-dom";
// import pdt10 from '../../../src/app/assets/images/products/10.jpg';

function Errorpage() {
  useEffect(() => {
    document.title = "Page not found! | CAP Digisoft ";
  }, []);
  return (
    <>
      <HeaderDark />

      <section className="d-flex align-items-center justify-content-center position-relative banner-sec inner text-center text-lg-start thank-you-sec">
        <div className="container">
          <div className=" ">
            <div className="row g-lg-5 g-4 justify-content-center align-items-center text-center">
              <div className="col-lg-6 p-4 pt-0 pt-lg-0 pb-0 pb-lg-0">
                <img
                  src={pagenotfound}
                  alt="Thank you for contacting us"
                  className="img-fluid"
                />
                <h3 className="mb-2 mt-lg-4 fw-400">Page not found!</h3>
                <p className="mb-4">
                  Sorry, the requested page cannot be located. It may have been
                  moved or removed. Please navigate back to the homepage.
                </p>
                <Link to="/" className="btn cust-btn-primary ">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </>
  );
}

export default Errorpage;
