import React from "react";

import teamImg from "../../../../app/assets/images/plug-and-play/teams.jpg";

const keyChallenges = () => {
  return (
    <>
      <div class="work_area position-relative overflow-cust cust-padding bg-white pb-0">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="section_wrapper cust-sec-wrapper">
                <div class="main-title sectiontitle-elements">
                  <h2 class="fw-normal wow animate__bounceInn mb-4 animated">
                    Key Challenges
                  </h2>
                </div>
                <p class="text-dark">
                  The call center industry grapples with a high attrition rate
                  of 30 to 45%, costing companies up to $20,000 per new agent
                  for hiring and training. Additionally, U.S. businesses face
                  difficulties in efficiently managing their customer support
                  and sales teams, alongside the challenge of finding offshore
                  call centers well-versed in U.S. culture and lifestyle. Our
                  mission is focused on overcoming these obstacles by offering
                  solutions that streamline operations and ensure cultural
                  alignment, helping businesses enhance their customer
                  engagement strategies.
                </p>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="stream-sec text-center pt-4 pt-lg-0">
                <img src={teamImg} class="img-fluid" alt="pp-img"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====== key Challenges end ======== */}

      <style jsx>{`
        #quote_btn:hover a,
        .main_menu ul li ul li:hover a,
        .menu_item ul li a:hover,
        .service_content p b,
        .service_two__content h4 a:hover,
        .service_two_card.service_page_card:hover a,
        .shoot_count_2 h2,
        .title .expert_color,
        .title span {
          color: #52bdc7;
        }

        .idp-head,
        .kpo-head,
        .mls-card h6,
        .service_title,
        .timeline .year,
        .timeline-heads,
        .title,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 700;
        }

        .cust-sec-wrapper {
          padding-right: 60px;
        }

        .stream-sec img {
          background-color: #224452;
          border: 1.5px solid rgba(72, 72, 72, 0.5);
          border-radius: 20px;
          padding: 45px;
        }

        .strm-cont {
          padding-left: 38px;
        }
      `}</style>
    </>
  );
};

export default keyChallenges;
