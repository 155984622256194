import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

const Meta = () => {

  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = 'CAP Digisoft - Software Development | Artificial Intelligence | Web Solutions'; // Reset title if necessary
          };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  return (
    <div>
      <Helmet>
        <title>CAP Digisoft - Software Development | Artificial Intelligence | Web Solutions</title>
        <meta
          name="description"
          content="CAP Digisoft Solutions has been delivering Software, Artificial Intelligence and KPO solutions, servicing 100+ small, medium and larger corporations globally."
        />
        <meta name="author" content="https://capdigisoft.com" />
        <meta name="robots" content="index, follow" />

        <link rel="alternate" hreflang="x-default" href="https://capdigisoft.com/" />
        <link rel="canonical" href="https://capdigisoft.com/" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="CAP Digisoft - Software Development | Artificial Intelligence | Web Solutions"
        />
        <meta
          property="og:description"
          content="CAP Digisoft offers Software, Mobile, & Web Application Development, and Artificial Intelligence Services. We help Global Businesses successfully solve complex Digital Challenges. Call Today for a Free Consultation."
        />
        <meta property="og:url" content="https://capdigisoft.com/" />
        <meta property="og:site_name" content="CAP Digisoft" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/capdigisoftsolutions/"
        />
        <meta
          property="article:modified_time"
          content="2021-08-23T14:13:42+00:00"
        />
        <meta
          property="og:image"
          content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="CAP Digisoft - Software Development | Artificial Intelligence | Web Solutions"
        />
        <meta
          name="twitter:description"
          content="CAP Digisoft is a full service software consultants specializing in the design, development and support of engaging user experiences."
        />
        <meta
          name="twitter:image"
          content="https://capdigisoft.com/images/cds-logo.png"
        />
        <meta name="twitter:site" content="@CAPDigisoft" />

        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "capdigisoft",
            "url": "https://capdigisoft.com/",
            "logo": "https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png",
            "sameAs": [
              "https://www.facebook.com/capdigisoftsolutions/",
              "https://x.com/CAPDigisoft",
              "@capdigisoftsolutions",
              "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw",
              "https://www.linkedin.com/company/cap-digisoft-solutions-inc"
            ]
          }
          `}
        </script>
      </Helmet>
      </div>
  );
};

export default Meta;
