import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/skoolers/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/skoolers/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/skoolers/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/skoolers/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/linux.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/apache.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/jugl.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/practica.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/reeler.png";

const Skoolers = () => {
  useEffect(() => {
    document.title = "Skoolers | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-attesta-page">
      <HeaderLight />
      <div className="container-fluid pt-0 px-0 margin-minus">
        <div className="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Skoolers</h2>
          <p class="text-white text-center pb-4 pt-3 px-4">
            Easy way to manage your school education structure
          </p>
          <div className="row justify-content-center">
            <div className="img text-center">
              <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
          </div>
        </div>
      </div>
      <div className="about py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-xl-3 col-md-4 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Skoolers?</p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12">
              <p className="p-4 p-lg-0">
                Skoolers is an easy to use management application designed to
                make the good old school ecosystem to be seamlessly integrated
                in every possible way to communicate and manage the workflow of
                an educational institution. It is an exclusive platform to bring
                the school users forum together be it Students, Teachers,
                Parents, Administrative staff and the Management.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="picture">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                Most schools want to deploy a solution exclusive for their
                organisation to manage and optimise the workflow structure with
                enhanced learning methods. The control and view management
                school software available in the market to streamline
                administration processes lack innovative and insightful
                dashboard for effective workflow progress.
              </p>
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                We developed an Edutech platform exclusive for schools
                called“Skoolers”. With highly functional and simple UI with
                responsive dashboard schools can now optimise and set the
                activity workflow with ease. This works on both smartphones,
                laptops and tablets. The app provides parents with information
                about their child and can communicate with the teacher quickly
                and easily.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="picture1">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="impact py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12  py-3">
              <p class="mb-lg-2">
                Skooler has tools that will help you as a teacher – by spending
                less time on administration and repetitive tasks. Teachers can
                focus more on
              </p>
              <ul class="mt-top list-styled ps-4">
                <li>Teaching Session workflow </li>
                <li>Manage multiple classes</li>
                <li>View Student progress</li>
                <li>Attendance Management</li>
                <li>Upload Lessons</li>
                <ul class="list-styled ps-4">
                  <li>Class wise</li>
                  <li>Subject wise</li>
                  <li>Assessment wise</li>
                </ul>
                <li>Evaluate assessments</li>
                <li>Reward Marks</li>
                <li>Share Notification in advance</li>
                <li>Private &amp; Secured</li>
                <li>Zero Maintenance</li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-12 pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-0 pt-lg-5">
              <div className="impact-img4 d-flex flex-wrap align-items-center">
                <div className="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12  pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p className="pt-1 pt-lg-4 p-4 p-lg-0">
                In the end, the client saw the real benefits of our AI system
                that understands the In the end, the client saw the real
                benefits of Tracky which has the capability of generating
                reports with insights that can be exported to the Accounting
                Software used by the client, that can save more than 3000 man
                hours every year.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-cases pb-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center py-4 g-4">
            <div className="col-lg-4 col-sm-12">
              <Link to="/jugl">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Jugl</h4>
                  </div>
                  <p class=" pt-2"> Workforce communication made simple </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12 ">
              <Link to="/practica">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Practica</h4>
                  </div>
                  <p class=" pt-2">Simple Learning Starts Here</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/reeler">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Reeler</h4>
                  </div>
                  <p class=" pt-2">The best place to meet-up</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Skoolers;
