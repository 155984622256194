import React from "react";
import { Link } from "react-router-dom";
import blogData from "../../pages/Insights/blogs/blog-data";

const Blogs = () => {
  // Separate blogs based on id
  const specialBlog = blogData.find((blog) => blog.id === 1);
  const regularBlogs = blogData.filter((blog) => blog.id > 1 && blog.id <= 6);

  return (
    <>
      <div className="blog-list_page home-blog bg-light">
        <div className="container">
          <div className="section-heading text-center">
            <h2 className="font-lg fw-light mb-lg-5 mb-4">Latest Blogs</h2>
          </div>
        </div>

        <div className="container custom-cn-width pt-0">
          <div className="blog-list py-0 home-blog">
            <div className="row">
              {/* Special Blog */}
              <div className="col-lg-6 col-12 ">
                <Link to={specialBlog.link}>
                  <div className="special-design bg-white">
                    <img
                      src={specialBlog.img}
                      alt="Special Blog"
                      className="special-image img-fluid"
                    />
                    <div className="item-blog">
                      <h2>{specialBlog.title}</h2>
                      <div className="blog-list-body">
                        <p>{specialBlog.description}</p>
                      </div>
                      <div className="blog-list-header">
                        <span className="blog-history other-details">{specialBlog.date}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Regular Blogs */}
              <div className="col-lg-6 col-12">
                <div className="featured-blogs cus-pd-24-2">
                  <h3>Featured Posts</h3>
                  <ul className="list-unstyled">
                    {regularBlogs.map((blog) => (
                      <li key={blog.id} className="mb-4 featured-blog-list">
                        <Link to={blog.link}>
                          <h2>
                            {blog.title}
                          </h2>

                          <div className="d-flex">
                            <span className="other-details blog-history">
                              {blog.date}
                            </span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Blogs;
