import React from "react";

// product icons imports
import product1 from "../../../../../app/assets/images/idea-booster/fura.png";
import product2 from "../../../../../app/assets/images/idea-booster/clued-in.png";
import product3 from "../../../../../app/assets/images/idea-booster/iledger.png";
import product4 from "../../../../../app/assets/images/idea-booster/intellicue.png";
import product5 from "../../../../../app/assets/images/idea-booster/hire-harmony.png";

const victory = () => {
  return (
    <>
      {/* Vision to Victory Section Start Here */}
      <div className="wg-result cust-padding border-line-btm">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="content text-center">
              <div className="heading-section sectiontitle-element--center wow fadeInUp style-2">
                <h5 class="mb-2 fw-400">Vision to Victory</h5>
                <div className="main-title sectiontitle-elements">
                  <h2 class="fw-normal mb-0 pb-0">
                    We recently transformed these app ideas into reality
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card h-100 vic-card border-card-1">
                <div className="card-body text-md-center">
                  <img src={product1} alt="ib-img" />
                  <p class="text-dark mt-3">
                    Fura is an innovative AI-powered specialty pharmacy
                    prescription tool that stands at the forefront of this
                    revolution, redefining the way healthcare providers manage
                    and dispense specialty medications.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 position-relative">
              <div className="card h-100 vic-card border-card-2">
                <div className="card-body text-md-center">
                  <img src={product2} alt="ib-img" />
                  <p class="text-dark mt-3">
                    Clued.in Closet offers an efficient closet management and
                    outfit planning. Seamlessly scheduling outfits and giving
                    daily suggestions tailored to local weather, thanks to
                    advanced AI.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card h-100 vic-card border-card-3">
                <div className="card-body text-md-center">
                  <img src={product3} alt="ib-img" />
                  <p class="text-dark mt-3">
                    A comprehensive truck fault inspection is a proactive
                    approach to fleet maintenance, contributing to the safety
                    and efficiency of commercial vehicles.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center top-grad-border">
            <div className="col-lg-4 col-md-6">
              <div className="card h-100 vic-card border-card-4 pb-0">
                <div className="card-body text-md-center pb-0">
                  <img src={product4} alt="ib-img" />
                  <p class="text-dark mt-3">
                    Intellicue is an innovative online learning platform
                    designed to support individuals in enhancing their mental
                    health through a variety of engaging tools.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 position-relative">
              <div className="card h-100 vic-card border-card-5 pb-0">
                <div className="card-body text-md-center pb-0">
                  <img src={product5} alt="ib-img" />
                  <p class="text-dark mt-3">
                    HireHarmony is a web application designed to streamline and
                    optimize the hiring processes of organizations, leveraging
                    intuitive features and advanced technologies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>
        {`
          .vic-card {
            background-color: transparent;
            box-shadow: none !important;
            border-radius: 0;
          }
          .border-card-2:before {
            width: 2px;
            height: 100%;
            left: -15px;
          }
          .border-card-2:after,
          .border-card-2:before,
          .border-card-5:before,
          .top-grad-border:before {
            content: "";
            background-image: radial-gradient(#fff 5%, #14303c 15%, #fff 60%);
            content: "";
            position: absolute;
          }
          .border-card-2:after {
            width: 2px;
            height: 100%;
            right: -15px;
          }
          .border-card-5:before {
            width: 2px;
            height: 100%;
            top: 2px;
            left: -15px;
          }
          .top-grad-border:before {
            width: 100%;
            height: 2px;
            left: -15px;
          }
          .cust-width {
            padding: 0 200px;
          }
          .value-sec-list li {
            font-size: 20px;
            margin-bottom: 20px;
            color: #fff;
            list-style: outside;
            line-height: 28px;
          }
          @media (max-width: 1200px) {
            .cust-width {
              padding: 0;
            }
          }
          @media (max-width: 991px) {
            .sub-section h3 {
              font-size: 32px !important;
            }
            .idea-booster-page .card {
              margin-right: 0;
            }
            .gallery-cont .sub-section {
              text-align: center;
            }
            .cta-arrow-sec,
            .mob-img {
              margin-top: 50px;
            }
            .wg-banner-1 .content .title h2,
            .wg-result .content .title h2 {
              padding-left: 30px;
              padding-right: 30px;
              font-size: 42px;
              line-height: 52px;
            }
            .wg-banner-1 .content p {
              padding-left: 30px;
              padding-right: 30px;
              font-size: 20px;
              line-height: 1.4;
            }
            .section-head {
              font-size: 42px;
              line-height: 52px;
            }
            .vdo-sec-txt {
              font-size: 20px !important;
            }
            .border-card-2:after,
            .top-grad-border:before {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default victory;
