import React from "react";

import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";

import pdt1 from "../../../src/app/assets/images/products/1.jpg";
import pdt2 from "../../../src/app/assets/images/products/2.jpg";
import pdt3 from "../../../src/app/assets/images/products/3.jpg";
import pdt4 from "../../../src/app/assets/images/products/4.jpg";
import pdt6 from "../../../src/app/assets/images/products/6.jpg";
import pdt7 from "../../../src/app/assets/images/products/7.jpg";
import pdt8 from "../../../src/app/assets/images/products/8.jpg";
import pdt9 from "../../../src/app/assets/images/products/9.jpg";
import pdt10 from "../../../src/app/assets/images/products/10.jpg";

import Meta from "../Meta/Products";

const ProductsPage = () => {
  return (
    <div className="prod-page">
      <HeaderOne />
      <Meta/>
      <section class="case-study-bg d-flex align-items-center justify-content-center banner-sec bg-img inner pt-5 section-padding margin-minus">
        <div className="container custom-cn-width">
          <div className="row align-items-center text-center">
            <div className="col-lg-12">
              <h2 class="pb-lg-5 pb-4 position-relative text-white">
                Products that enlight your business development
              </h2>
            </div>
          </div>
          <div className="row g-lg-5 g-4 text-start justify-content-lg-end justify-content-center case-list">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt1} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>Medico-Risk Chart App</h6>
                      <p>
                        Validate Medical litigation risk factors using risk
                        chart application
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt4} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>API CRM Suite</h6>
                      <p>Synchronise Multi-Platform CRM via API</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt7} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>Legal-M App</h6>
                      <p>Manage legal cases and its informational support</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt2} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>Medico AI – Chrono Sort App</h6>
                      <p>
                        Automate and sort your medical records via Hybrid
                        Intelligence
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt8} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>E-Sort Desk</h6>
                      <p>
                        Explore automated Sorting and categorising of documents
                        on your demand
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt10} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>Liti-code System</h6>
                      <p>
                        A complete framework system for litigation coding and
                        encryption
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt3} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>Medico - View Desk</h6>
                      <p>A digital viewpoint software for Medical reports</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt6} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>Joomla zone</h6>
                      <p>
                        Upgrade to the next gen mobile-ready and user-friendly
                        CMS
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="case-box-2 transition">
                    <div className="icon">
                      <img src={pdt9} alt="team" class="img-fluid" />
                    </div>
                    <div className="case-box-details">
                      <h6>Data Governance System</h6>
                      <p>
                        Manage and control data and its facts, all in one system
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterOne />
    </div>
  );
};

export default ProductsPage;
