import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import HeaderTwo from "../../../common/header/HeaderTwo";
import FooterOne from "../../../common/footer/FooterOne";

import BrandSlider from "../../../common/BrandLogo-slider";
import review from "../../../../src/app/assets/images/client-success/review.mp4";
import thumb from "../../../../src/app/assets/images/client-success/thumb.jpg";

import cutout1 from "../../../app/assets/images/client-success/cutout1.jpg";
import cutout2 from "../../../app/assets/images/client-success/cutout2.png";
import cutout3 from "../../../app/assets/images/client-success/cutout3.jpg";
import Meta from "../../Meta/Client-success";


const PlayButtonSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64">
    <circle cx="32" cy="32" r="32" fill="rgba(0, 0, 0, 0.6)" />
    <polygon points="25,16 53,32 25,48" fill="white" />
  </svg>
);


const ClientSucess = () => {
  
    
	useEffect(() => {
	  document.title = 'Client Success | CAP Digisoft ';
	}, []);

  const [isPlaying, setIsPlaying] = useState(false);

  const handleThumbnailClick = () => {
    setIsPlaying(true);
  };
  
  return (
    <div className="client-success-page">
      <HeaderTwo />
      <Meta/>
      <section class="d-flex align-items-center justify-content-center banner-sec client-sec bg-img margin-minus">
        <div className="container custom-cn-width position-relative z-index-1">
          <div className="row g-4">
            <div className="col-xxl-6 col-xl-6 col-lg-6 p-0 m-0 ms-md-4 ms-xl-0 ps-md-5 px-xl-0 col-md-7">
              <h5 class="pb-2 font-size-22">Empower Your Business with</h5>
              <h2 class="pb-2 text-uppercas "> Client Success Service</h2>
              <p class="text-dark pb-2 font-size-19">
                In the dynamic world of startups and product-centric companies,
                the quest for robust Client Success mechanisms is more crucial
                than ever. It's a pursuit that can significantly influence both
                growth and loyalty. Recognizing this urgency, CAP Digisoft steps
                in to bridge the gap.
              </p>
              <p className="fw-medium font-size-19">
                <strong>
                  Our mission is to elevate customer satisfaction levels,
                  turning mere customers into fervent brand advocates.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row g-4 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-8 order-lg-2">
              <div className="design-block ms-0 sec-title b-r-6">
                <h2 class="mb-3">AI-Human Synergy Excellence</h2>
                <p class="pe-xl-5">
                  However, what truly sets our approach apart is the harmony of
                  cutting-edge AI with genuine human agents. This symbiotic
                  blend ensures that while technology accelerates processes, the
                  invaluable human touch fosters deeper, more meaningful
                  connections with clients. It's this balance that allows us to
                  provide a service that's both efficient and warmly personal.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 order-lg-1">
              <img
                src={cutout1}
                class="img-fluid b-r-6"
                alt="A full-suite of KPO services"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="bg-light text-start section-padding">
        <div className="container custom-cn-width">
          <div className="row justify-content-center align-items-center mb-lg-4 g-4">
            <div className="col-lg-6 col-md-8">
              <h1 class="fw-light">Client Success Essentials</h1>
              <p class="mt-3">
                Key aspects of our Client Success as a Service include:
              </p>

              <div className="mt-1">
                <div className="mb-3 mt-4 mt-xl-0">
                  <p>
                    <span class="fw-bold">
                      Enhancing Customer Satisfaction:{" "}
                    </span>
                    Beyond solving issues, we focus on enriching the overall
                    client journey.
                  </p>
                </div>
                <div className="mb-3 mt-4 mt-xl-0">
                  <p>
                    <span class="fw-bold">
                      Providing Product Feedback and Improvement:{" "}
                    </span>
                    Using customer insights, we guide product refinements,
                    ensuring they align with market needs.
                  </p>
                </div>
                <div className="mb-3 mt-4 mt-xl-0">
                  <p>
                    <span class="fw-bold">Reducing Churn: </span>Through
                    proactive strategies, we curtail customer attrition,
                    solidifying loyalty.
                  </p>
                </div>
                <div className="mb-3 mt-4 mt-xl-0">
                  <p>
                    <span class="fw-bold">Cross-Selling and Upselling: </span>
                    With a keen understanding of client needs, we identify and
                    capitalize on organic opportunities for growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 text-center">
              <img
                src={cutout2}
                class="img-fluid b-r-6"
                alt="A full-suite of KPO services"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row g-4 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-8 order-2 order-lg-1">
              <img
                src={cutout3}
                class="img-fluid b-r-6"
                alt="A full-suite of KPO services"
              />
            </div>
            <div className="col-lg-6 col-md-8 order-1 order-lg-2">
              <div className="design-block ms-0 sec-title b-r-6">
                <h2 class="mb-3">Strategic Client Success</h2>
                <p class="pe-xl-5">
                  On the logistical side, outsourcing Client Success to CAP
                  Digisoft is a shrewd move for product companies. We provide
                  expert services without the overheads of in-house teams,
                  allowing companies to focus on innovation. Our scalable
                  solutions are a competitive advantage that grows with your
                  needs and is backed by a depth of client management expertise.
                  In partnering with us, startups aren’t just improving customer
                  touchpoints; they're investing in a growth-driven partnership.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-0 section-padding">
        <div className="container custom-cn-width text-center">
          <div className="row g-xl-5 g-4 justify-content-center text-center">
            <div className="col-lg-12 ">
              <h1 class="fw-light">Our Promise to You</h1>
            </div>
          </div>
          <div className="row g-xl-5 g-4 justify-content-center">
            <div className="col-lg-10">
              <div className="design-sec">
                <div className="row g-lg-0 g-4 pt-lg-5 pt-4">
                  <div className="col-md-4 border-end pe-lg-4">
                    <h4>AI-driven Insights</h4>
                    <p class="mt-3">
                      Harnessing the power of artificial intelligence to deliver
                      proactive client solutions.
                    </p>
                  </div>
                  <div className="col-md-4 border-end px-lg-4">
                    <h4>Genuine Human Engagement</h4>
                    <p class="mt-3">
                      Building authentic relationships through our dedicated
                      team of human agents.
                    </p>
                  </div>
                  <div className="col-md-4 px-4 border-start border-white">
                    <h4>Near-shore Client Success Team</h4>
                    <p class="mt-3">
                      Benefiting from a team that understands regional nuances
                      and operates in a similar time zone, ensuring efficient
                      communication and responsiveness.
                    </p>
                  </div>
                </div>
                <div className="row g-lg-0 g-4 pt-lg-5 pt-4">
                  <div className="col-md-4 border-end pe-lg-4">
                    <h4>Seamless Integration</h4>
                    <p class="mt-3">
                      Smoothly integrating technology and human capabilities for
                      maximum efficiency.
                    </p>
                  </div>
                  <div className="col-md-4 border-end px-lg-4">
                    <h4>Phone &amp; Chat Support</h4>
                    <p class="mt-3">
                      Ensuring your clients always have a real person to connect
                      with.
                    </p>
                  </div>
                  <div className="col-md-4 px-4 border-start border-white">
                    <h4>Scalability</h4>
                    <p class="mt-3">
                      Adaptable solutions that grow with your business needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-light text-center section-padding">
        <div className="container custom-cn-width">
          <div className="row g-5 justify-content-center">
            <div className="col-xxl-7 col-xl-8 col-lg-10 col-md-10 mb-4 mb-lg-5">
              <h1 class="fw-light">
                How CAP Digisoft <b>CSA</b>s Help with your Client Relationships
              </h1>
              <p class="mt-3">
                CAP Digisoft is at the forefront of revolutionizing client
                relationships by understanding the evolving demands of today's
                market. We don't just provide outsourcing services; we empower
                our clients with tools that ensure the highest standards of
                quality and consistency for the customer experience. Plus, with
                our flexible approach, businesses have the advantage of
                adjusting their team sizes as needed, ensuring they are never
                overstaffed or understaffed.
              </p>
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-md-6 col-xl-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-white w-50"
                    viewBox="0 0 576 512"
                  >
                    <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                  </svg>
                </div>
                <h4>List Preparation &amp; Prospect Outreach</h4>
                <p class="mt-3">
                  Begin the client journey right. We identify sourcing
                  opportunities, share them for your perusal, and connect with
                  potential clients. Whether through phone calls or LinkedIn,
                  our ambition is always clear – to ensure a robust rate of
                  client installations, targeting 5 to 8 weekly.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 sec-primary-ic d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-white w-50"
                    viewBox="0 0 640 512"
                  >
                    <path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 352h8.2c32.3-39.1 81.1-64 135.8-64c5.4 0 10.7 .2 16 .7V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM320 352H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H360.2C335.1 449.6 320 410.5 320 368c0-5.4 .2-10.7 .7-16l-.7 0zm320 16a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM496 288c8.8 0 16 7.2 16 16v48h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H496c-8.8 0-16-7.2-16-16V304c0-8.8 7.2-16 16-16z" />
                  </svg>
                </div>
                <h4>Client Onboarding &amp; Support</h4>
                <p class="mt-3">
                  Your product's first impression lasts. Our agents introduce
                  clients to the nuances of your product, conduct training
                  sessions, and share best practices. Furthermore, we stand by
                  to address client queries and concerns, and, if needed, liaise
                  with your technical teams to ensure solutions are prompt and
                  effective.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-white w-50"
                    viewBox="0 0 512 512"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </div>
                <h4>Feedback &amp; Account Management</h4>
                <p class="mt-3">
                  Your product's evolution hinges on feedback. We actively
                  gather client insights, liaising with your product teams to
                  iterate and innovate. Simultaneously, our vigilant account
                  management ensures optimal client adoption, seizing upselling
                  or cross-selling opportunities and managing contract
                  intricacies.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
                  <i class="fas fa-rocket text-white"></i>
                </div>
                <h4>Engagement &amp; Performance Monitoring</h4>
                <p class="mt-3">
                  Informed clients are engaged clients. We keep them in the loop
                  with product updates, organize educational webinars, and even
                  plan networking events. Behind the scenes, our data analytics
                  ensures performance KPIs are monitored, anticipating churn and
                  strategizing its reduction.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-white w-50"
                    viewBox="0 0 640 512"
                  >
                    <path d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48V352h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16V352c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V128H16zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128V352c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z" />
                  </svg>
                </div>
                <h4>Collaboration &amp; Crisis Management</h4>
                <p class="mt-3">
                  Unity is strength. We ensure that our efforts are in lockstep
                  with your sales, marketing, and product teams. Sharing client
                  insights to inform holistic strategies is a given. And, when
                  the inevitable crisis arises, we're poised to manage and
                  mitigate it, keeping clients informed and reassured.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 sec-primary-ic d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-white w-50"
                    viewBox="0 0 512 512"
                  >
                    <path d="M470.7 9.4c3 3.1 5.3 6.6 6.9 10.3s2.4 7.8 2.4 12.2l0 .1v0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3L310.6 214.6c-11.8 11.8-30.8 12.6-43.5 1.7L176 138.1 84.8 216.3c-13.4 11.5-33.6 9.9-45.1-3.5s-9.9-33.6 3.5-45.1l112-96c12-10.3 29.7-10.3 41.7 0l89.5 76.7L370.7 64H352c-17.7 0-32-14.3-32-32s14.3-32 32-32h96 0c8.8 0 16.8 3.6 22.6 9.3l.1 .1zM0 304c0-26.5 21.5-48 48-48H464c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V304zM48 416v48H96c0-26.5-21.5-48-48-48zM96 304H48v48c26.5 0 48-21.5 48-48zM464 416c-26.5 0-48 21.5-48 48h48V416zM416 304c0 26.5 21.5 48 48 48V304H416zm-96 80a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" />
                  </svg>
                </div>
                <h4>Billing, Retention, &amp; Continuous Learning</h4>
                <p class="mt-3">
                  Financial clarity begets trust. We handle all billing queries
                  and disputes and work closely with finance teams. But our role
                  isn't just reactive. Proactive client retention strategies are
                  continually developed. Moreover, our commitment to staying
                  updated ensures we're always a step ahead, ready for the
                  industry's next curveball.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
                  <svg
                    className="fill-white w-50"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path d="M160 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h50.7L9.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L256 109.3V160c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H160zM576 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM448 208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM400 384a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm128 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM272 384a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM144 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM576 336a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-48-80a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                  </svg>
                </div>
                <h4>Operational Efficiency</h4>
                <p class="mt-3">
                  In today's fast-paced world, efficiency isn't just about
                  speed, but also about smart work. Our processes are constantly
                  refined, and tasks automated wherever possible, ensuring
                  scalability aligns with your growth aspirations.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
                  <svg
                    className="fill-white w-35"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16h80V400c0-26.5 21.5-48 48-48s48 21.5 48 48v64h80c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm88 40c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V104zM232 88h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V104c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V232zm144-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V232c0-8.8 7.2-16 16-16z" />
                  </svg>
                </div>
                <h4>Customization is Key</h4>
                <p class="mt-3">
                  Every company is unique. And while our offerings are
                  extensive, we understand the importance of personalization.
                  Depending on your specific product, industry landscape, and
                  distinct needs, our services are flexible and adaptive,
                  ensuring maximum relevance and impact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center section-padding">
        <div className="container custom-cn-width">
          <div className="row g-5 justify-content-center">
            <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-10">
              <h1 class="fw-light">
                Raving Reviews: Our Client's Success Story
              </h1>
              <p class="my-3">
                Unveil the inspiring transformation of one of our valued clients
                in their own words. Dive into their captivating testimonial to
                learn how our services played a pivotal role in their journey to
                success. Get ready to be motivated and see how we can make a
                difference for you too. Don't miss this extraordinary client
                success story.
              </p>
              <div className="video-container" id="video-container">
                {isPlaying ? (
                  <ReactPlayer
                    className="testimonial-video"
                    controls={true}
                    url={review}
                    width="100%"
                    height="100%"
                    playing={true}
                  />
                ) : (
                  <div className="thumbnail-container" onClick={handleThumbnailClick}>
                    <img
                      className="img-fluid custom-thumbnail"
                      src={thumb}
                      alt="Thumbnail"
                    />
                    <div className="play-button">
                      <PlayButtonSVG />
                    </div>
                  </div>
                )}
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <section class="section-padding pt-lg-0 pb-lg-0">
        <div className="container custom-cn-width">
          <div className="row g-xl-5 g-4 align-items-center justify-content-center text-center">
            <div className="col-xxl-7 col-xl-8 col-lg-9">
              <h1 class="fw-light mb-lg-4 mb-3">
                Discover the CAP Digisoft Difference
              </h1>
              <p class="px-xl-4">
                Our accomplished and cost-effective client success specialists
                provide premium solutions, empowering you to optimize value and
                elevate your competitive edge.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="cta-block cta-blue b-r-6">
            <div className="row g-xl-5 g-4 align-items-center justify-content-center">
              <div className="col-lg-9">
                <h2 class="fw-light text-white">
                  Reach out to us and see how our Client Success as a Service
                  can elevate your customer relationships and help build your
                  brand loyalty.{" "}
                </h2>
              </div>
              <div className="col-lg-3 text-lg-end">
                <a
                  href="javascript:;"
                  class="default-btn solution_class_popu"
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                  form_title="Knowledge Process Outsourcing"
                >
                  Let's Talk
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center client-block section-padding pt-lg-0">
        <div className="container">
          <div className="row g-lg-5 g-4 justify-content-center">
            <div className="col-lg-12 mb-4 mb-lg-5">
              <h1 class="px-lg-4 ">
                Trusted by more than{" "}
                <span class="text-theme fw-bold text-grad">3000+</span> clients
                globally
              </h1>
            </div>
          </div>
            <BrandSlider/>
        </div>
      </section>
      <section class="other-sec pt-0 section-padding">
        <div className="container custom-cn-width">
          <div className="row g-5 text-center">
            <div className="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Solutions</h1>
            </div>
          </div>
          <div className="">
            <div className="row g-4 g-xl-5 text-center text-xl-start">
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Software</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Mobile &amp; Software Development
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/mobile-software-development"
                      rel="noreferrer"
                    >
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">call center</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Nearshore Call Center Outsourcing
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/call-center"
                      rel="noreferrer"
                    >
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Product</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">Idea to Product</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/idea-booster"
                       rel="noreferrer"
                    >
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default ClientSucess;
