import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/carfidante/carfidante1.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/carfidante/carfidante2.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/carfidante/carfidante3.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/carfidante/carfidante4.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/figma.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/steppa1.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/verific1.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/aiya1.png";

const Carfidante = () => {
  useEffect(() => {
    document.title = "Carfidante | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div className="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div className="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Carfidante</h2>
          <p class="text-white text-center pb-4 pt-3 px-4">
            A cutting-edge mobile application designed to redefine the
            experience of buying pre-owned cars
          </p>
          <div className="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div className="about py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-xl-3 col-md-4 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Carfidante?</p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12">
              <p className="p-4 p-lg-0">
                Carfidante is a state-of-the-art mobile application that
                redefines the pre-owned car purchasing experience. It combines
                advanced technology, user-friendly design, and a comprehensive
                approach to empower users with the information and tools needed
                to make confident and informed decisions in the used car market.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="picture">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                The traditional pre-owned car buying process is riddled with
                uncertainties, lack of transparency, and time-consuming
                procedures. Buyers often face challenges in assessing the true
                condition of a vehicle, negotiating fair prices, and navigating
                complex paperwork. Sellers, on the other hand, struggle to reach
                the right audience and build trust. This creates a market
                inefficiency that impacts both sides of the transaction.
              </p>
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                Carfidante provides detailed insights into the condition,
                maintenance history, and performance metrics of each listed
                vehicle, ensuring buyers make informed decisions. The app
                utilizes market data and machine learning to establish fair and
                transparent pricing, facilitating seamless negotiations between
                buyers and sellers. Carfidante simplifies the transaction
                process, offering in-app tools for paperwork, payments, and
                logistics, reducing the time and effort required to complete a
                purchase. Establishes a community-driven rating system for both
                buyers and sellers, fostering trust and accountability within
                the Carfidante platform.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="picture1">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="impact py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12  ">
              <ul class="impact pt-2 list-styled">
                <li>Empowered Buyers</li>
                <li>Efficient Transactions</li>
                <li>Increased Seller Reach</li>
                <li>Market Transparency</li>
                <li>Data-Driven Decision Making</li>
                <li>Market Standardization</li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-12 pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-0 pt-lg-5">
              <div className="impact-img4 d-flex flex-wrap align-items-center">
                <div className="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12  pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p className="pt-1 pt-lg-4 p-4 p-lg-0">
                Carfidante's innovative approach not only addresses the inherent
                challenges of buying and selling pre-owned cars but elevates the
                entire experience for users. By leveraging technology to enhance
                transparency, streamline transactions, and build a trustworthy
                community, Carfidante emerges as a transformative force in the
                pre-owned car market, promising a future where every transaction
                is seamless, informed, and satisfying for both buyers and
                sellers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-cases pb-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center py-4 g-4">
            <div className="col-lg-4 col-sm-12">
              <Link to="/steppa">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4>Steppa</h4>
                  </div>
                  <p class="pt-2">
                    Empowering Confident Real-Time Decisions through AI
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12 ">
              <Link to="/verific">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4>Verific</h4>
                  </div>
                  <p class="pt-2">
                    Transform customer feedback into actionable insights
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/aiya">
                <div className="case rounded-3 h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4>Aiya</h4>
                  </div>
                  <p class="pt-2">
                    {" "}
                    Generative AI powered knowledge assistant{" "}
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Carfidante;
