import React from "react";


//Customer Excellence imports
import customerIcon1 from "../../../../app/assets/images/plug-and-play/icon-1.png";
import customerIcon2 from "../../../../app/assets/images/plug-and-play/icon-2.png";
import customerIcon3 from "../../../../app/assets/images/plug-and-play/icon-3.png";

const CustomerExcellence = () => {
  return (
    <>
      <div class="service_area custom_animation mobile-res-mb position-relative cust-padding cust-pad-btm-100">
        <div class="gradient-wrapper">
          <div class="purple-grad-shape"></div>
        </div>

        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-md-12">
              <div class="section_wrapper service_wraper mb-40">
                <h2
                  class="title wow animate__bounceInn text-white animated fw-normal"
                  data-animation="fadeInUp"
                  data-delay="1.5s"
                >
                  Transformative Solutions <br />
                  for <span class="grad-text">Customer Excellence</span>
                </h2>
              </div>
            </div>
          </div>
          <div class="row position-relative">
            <div class="col-md-12">
              <div class="row g-4 position-relative">
                <div class="col-lg-4">
                  <div class="service_card h-100">
                    <div class="service_img service_img_one text-center">
                      <img src={customerIcon1} alt="service_img" />
                    </div>
                    <div class="service_content">
                      <h3 class="service_title text-white">
                        Superior Customer Support
                      </h3>
                      <p class="light_color mb-0">
                        Outsource your customer support to nearshore locations
                        like El Salvador for cost-efficient solutions and a
                        skilled bilingual workforce. Our tailored support covers
                        every touchpoint, allowing you to focus on core
                        operations while enhancing brand reputation with
                        dedicated support.
                      </p>

                      <div class="mt-4">
                        <h5>Tailored superior customer support solutions</h5>
                        <ul>
                          <li>
                            Elevate support with tailored solutions from
                            nearshore locations.
                          </li>
                          <li>
                            Gain a strategic advantage by outsourcing to
                            locations like El Salvador.
                          </li>
                          <li>
                            Enjoy comprehensive solutions covering every
                            customer touchpoint.
                          </li>
                          <li>
                            Partner with us for dedicated support, satisfaction,
                            and brand reputation.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="service_card h-100">
                    <div class="service_img service_img_two text-center">
                      <img src={customerIcon2} alt="service_img" />
                    </div>
                    <div class="service_content">
                      <h3 class="service_title text-white">
                        Expert Tech Support
                      </h3>
                      <p class="light_color mb-0">
                        For tech startups, quality tech support is integral to
                        the product experience. Plug and Play Call Center offers
                        specialized services tailored for startups' dynamic
                        needs, including Product Demos and Software Tech
                        Support, ensuring timely and effective assistance.
                      </p>

                      <div class="mt-4">
                        <h5>
                          Our specialized tech support services for startups
                        </h5>
                        <ul>
                          <li>
                            Comprehensive guidance for product demos, enhancing
                            user understanding.
                          </li>
                          <li>
                            Tailored assistance for software startups,
                            addressing technical inquiries.
                          </li>
                          <li>
                            Flexible services adapting to startup needs and
                            customer demands.
                          </li>
                          <li>
                            Freeing startups to concentrate on core innovation
                            and growth.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="service_card h-100">
                    <div class="service_img service_img_three text-center">
                      <img src={customerIcon3} alt="service_img" />
                    </div>
                    <div class="service_content">
                      <h3 class="service_title text-white">
                        Dynamic Inside Sales Support
                      </h3>
                      <p class="light_color mb-0">
                        Dynamic inside sales support is crucial for seizing
                        opportunities and converting leads effectively.
                        Nearshore support from El Salvador offers seamless
                        integration with North American markets, cost-effective
                        scalability, and skilled professionals.
                      </p>

                      <div class="mt-4">
                        <h5>Our Inside Sales Support Solutions</h5>
                        <ul>
                          <li>
                            Targeted lead identification and nurturing for high
                            conversion potential.
                          </li>
                          <li>
                            Tailored sales support campaigns aligned with your
                            business objectives.
                          </li>
                          <li>
                            Efficient CRM-driven pipeline management for
                            seamless lead progression.
                          </li>
                          <li>
                            Integrated sales team extension dedicated to
                            achieving your objectives.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----customer excellence end---- */}
    </>
  );
};

export default CustomerExcellence;
