import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// cds product logo imports
import slide1 from "../../../../../app/assets/images/idea-booster/products/iledger.png";
import slide2 from "../../../../../app/assets/images/idea-booster/products/Intellicue.png";
import slide3 from "../../../../../app/assets/images/idea-booster/products/J&P.png";
import slide4 from "../../../../../app/assets/images/idea-booster/products/Clude.in.png";
import slide5 from "../../../../../app/assets/images/idea-booster/products/mockup.png";
import slide6 from "../../../../../app/assets/images/idea-booster/products/social.png";
import slide7 from "../../../../../app/assets/images/idea-booster/products/bussopi.png";
import slide8 from "../../../../../app/assets/images/idea-booster/products/attesta.png";

const ourInovations = () => {
  const properties = {
    prevArrow: (
      <button className="cs-btn ib-btn-left">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 14L5 8L11 2"
            stroke="black"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button className="cs-btn btn-right ib-btn-right">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 14L11 8L5 2"
            stroke="black"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    ),
  };
  const responsiveSettings = [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  return (
    <>
      {/* Our Innovations Section Start Here */}
      <div className="wg-create-design bg-gray cust-padding border-line-btm">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="content text-center">
              <div className="heading-section wow fadeInUp style-2 pb-0 mb-0">
                <div className="main-title sectiontitle-elements">
                  <h2 class="fw-normal pb-0 mb-0">
                    Our Innovations By Our Experts
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="carousel_se_01 bg-gray">
          <div className="container cust-slider-cont mb-5">
            <Slide
              className="brand-slider"
              {...properties}
              slidesToScroll={1}
              slidesToShow={2}
              indicators={false}
              duration={700}
              infinite={true}
              pauseOnHover={false}
              autoplay={true}
              responsive={responsiveSettings}>
              <div className="card">
                <img src={slide1} alt="Logo" class="img-fluid mb-1" />
                <div className="product-details">
                  <h3 class="text-dark fw-bold">Iledgers (B2B)</h3>
                  <p className="text-dark">
                    Features customizable checklists, photo capture, real-time
                    reporting, and fleet management to improve inspection
                    efficiency.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={slide2} alt="Logo" class="img-fluid mb-1" />
                <div className="product-details">
                  <h3 class="text-dark fw-bold">Intellicue (B2B)</h3>
                  <p className="text-dark">
                    An education app is a digital tool that enhances learning
                    through interactive lessons, quizzes, and personalized
                    content.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={slide3} alt="Logo" class="img-fluid mb-1" />
                <div className="product-details">
                  <h3 class="text-dark fw-bold">J&P Hospitality (B2B)</h3>
                  <p className="text-dark">
                    Enhance guest experiences with exceptional service,
                    efficient operations, staff training, resource optimization,
                    and engagement.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={slide4} alt="Logo" class="img-fluid mb-1" />
                <div className="product-details">
                  <h3 class="text-dark fw-bold">Clued.in (B2B)</h3>
                  <p className="text-dark">
                    Closet: the ultimate app to organize clothes, plan outfits,
                    and enhance your personal style.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={slide5} alt="Logo" class="img-fluid mb-1" />
                <div className="product-details">
                  <h3 class="text-dark fw-bold">Carfidante (B2B)</h3>
                  <p className="text-dark">
                    Streamline car inspections with custom checklists, real-time
                    reporting, photo capture, and fleet management integration.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={slide6} alt="Logo" class="img-fluid mb-1" />
                <div className="product-details">
                  <h3 class="text-dark fw-bold">Social lite (B2B)</h3>
                  <p className="text-dark">
                    Make new friends, join events, and find communities. Safe,
                    simple, and personal. Start connecting today!
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={slide7} alt="Logo" class="img-fluid mb-1" />
                <div className="product-details">
                  <h3 class="text-dark fw-bold">Bussopi (B2B)</h3>
                  <p className="text-dark">
                    Bussopi is a reliable app for managing and validating
                    personal information, including legal documents, health
                    data, and personality behavior.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={slide8} alt="Logo" class="img-fluid mb-1" />
                <div className="product-details">
                  <h3 class="text-dark fw-bold">AttestaAI (Product)</h3>
                  <p className="text-dark">
                    A document reader is a software application for displaying
                    and managing digital documents in various formats like PDF
                    and DOCX.
                  </p>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>
        {`
          .cust-slider-cont .card {
            border-radius: 15px;
            background: 0 0;
            box-shadow: none;
            padding: 25px;
            margin-right: 20px;
          }
          .cust-slider-cont .card h3 {
            font-size: 24px;
            line-height: 26px;
            color: #fff;
            margin-bottom: 10px;
          }
          .cust-slider-cont .card img {
            height: 300px;
            object-fit: contain;
            margin-bottom: 20px !important;
          }
          button.ib-btn-left.nav svg,
          button.ib-btn-right.nav svg {
            width: 20px;
            height: 20px;
          }
          button.ib-btn-left.nav,
          button.ib-btn-right.nav {
            background: #fff;
            border-radius: 50%;
            color: #030014;
            padding: 10px;
            height: 38px;
            width: 38px;
            justify-content: center;
            align-content: center;
            display: flex;
          }
          button.cs-btn.ib-btn-right.nav {
            margin-right: 0;
            right: 50%;
            bottom: -50px;
            transform: translateX(120%);
          }
          button.cs-btn.ib-btn-left.nav {
            margin-right: 0;
            left: 50%;
            bottom: -50px;
            transform: translateX(-120%);
          }
          .product-details p {
            font-size: 20px;
            line-height: normal !important;
          }
          .bg-gray {
            background: #f5f5f5;
          }
        `}
      </style>
    </>
  );
};

export default ourInovations;
