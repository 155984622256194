import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaPhone } from "react-icons/fa6";

import HeaderDark from "../../common/header/HeaderDark";
import FooterOne from "../../common/footer/FooterOne";

import Meta from "../Meta/Contactus";

const Contactus = () => {
  useEffect(() => {
    document.title = "Contact Us | CAP Digisoft ";
  }, []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = filteredValue;
  };
  const handleKeyPress = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  const [countryCode, setCountryCode] = useState("");
    useEffect(() => {
    // Fetch the user's country based on their IP
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        // ipapi returns the country code in ISO Alpha-2 format, which react-phone-input-2 expects
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
      })
      .catch((error) => {
        setCountryCode("in")
        console.error("Error fetching IP information:", error);
        // Optionally set a default or handle the error
      });
  }, []);

  //email key down
  // Assuming you have an input field with id "email"
 
  
   const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .test('no-first-space', 'First letter should not be a space', value => value && !/^ /.test(value))
      .required("Please enter your name")   
      .test('only-one-space-between', 'Only one space allowed', value => value && !/ {2,}/.test(value))
      .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
      .min(3, "Name must be at least 3 characters long")
      .max(50, "Name should not exceed 50 characters long"),

      email: Yup.string().email("Please enter a valid email address")
      .test('no-com.co', 'Please enter a valid email address', value => !/\.com\.co$/.test(value))
      .test('no-single-letter-after-com', 'Please enter a valid email address', value => !/\.com\.[a-zA-Z]$/.test(value))
      .test('no-spaces', 'Please enter a valid email address', value => !/\s/.test(value))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Please enter a valid email address'
      )
      .test('only-one-dot-com', 'Please enter a valid email address', value => (value.match(/\.com/g) || []).length <= 1)
      .test('no-repeated-domain', 'Please enter a valid email address', value => {
        const domainExtensions = ['.com', '.net', '.in', '.co'];
        const matches = value.match(/\.\w+/g);
        if (matches) {
          const uniqueExtensions = new Set(matches.map(match => match.toLowerCase()));
          return uniqueExtensions.size === matches.length;
        }
        return true;
      })
      .test('laxEmail_new', 'Please enter a valid email address.', function(value) {
        if (value) {
          if (value.indexOf("@") > 0 && value.indexOf("@") === value.lastIndexOf("@")) {
            const [localPart, domain] = value.split("@");
            const firstChar = localPart[0];
            const lastChar = localPart.charAt(localPart.length - 1);
            const afterAt = domain[0];
    
            if (firstChar === '.' || lastChar === '.' || (/^[A-Za-z]$/.test(afterAt) === false)) {
              return false;
            }
    
            const domainParts = domain.split(".");
            if (domainParts.length < 2) {
              return false;
            }
    
            const topLevelDomain = domainParts.pop();
            if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
              return false;
            }
    
            return domainParts.every(part => part.length > 1);
          } else {
            return false;
          }
        }
        return true;
      })
      .required("Please enter your email address"),
     
    phone: Yup.string().required("Please enter phone number")
    .min(9,"Please enter valid phone number"), 
    company: Yup.string()
      .test('no-first-space', 'First letter should not be a space', value => value && !/^ /.test(value))
      .test('only-one-space-between', 'Only one space allowed between words', value => value && !/ {2,}/.test(value))
      .min(3, "Company name must be at least 3 characters")
      .max(50, "Company name should not exceed 50 characters")
      .required("Please enter Company name"),
    message: Yup.string()
      .test('no-first-space', 'First letter should not be a space', value => value && !/^ /.test(value))
      .test('only-one-space-between', 'Only one space allowed between words', value => value && !/ {2,}/.test(value))
      .min(3, "Message must be at least 3 characters")
      .max(500, "Message should not exceed 500 characters")
      .required("Please enter your message"),
  });
  
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      pageLink: window.location.href,
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      try {
        const response = await axios.post("backend/submitForm.php", {
          mode: "addCenterModuleData_new",
          ...values,
        });

        if (response.status === 200) {
          setTimeout(() => {
            formik.resetForm();
            const backdrop = document.querySelector(".modal-backdrop");
            if (backdrop) {
              //alert('hi');
              backdrop.remove();
            }
            navigate("/thankyou"); // Redirect to thank you page
            setIsSubmitting(false); // Resetting the submitting state if needed
          }, 50);
        } else {
          setIsSubmitting(false);
          console.error("Error submitting form data");
        }
      } catch (error) {
        setIsSubmitting(false);
        console.error("Error:", error);
      }
    },
  });

  return (
    <div className="contactus-page">
      <HeaderDark />
      <Meta/>
      <div className="contact-bg">
        <section className="banner-sec text-center inner pb-0 pt-4">
          <h2 className="pt-xl-3">Contact Us</h2>
        </section>

        <section className="pt-lg-5 section-padding">
          <div className="container custom-cn-width">
            <div className="row g-4 text-start">
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <h5 className="text-uppercase">USA</h5>
                  <ul className="contact-info mb-0 mt-lg-3 mt-2">
                    <li>
                      <strong>
                        {" "}
                        <i className="fa fa-envelope mt-0"></i>
                      </strong>
                      <a href="mailto:info@capdigisoft.com">
                        info@capdigisoft.com
                      </a>
                    </li>
                    <li>
                      <FaPhone />
                      <a href="tel:2149889484">(214) 988-9484 </a>
                    </li>
                    <li>
                      <i className="fa fa-map-marker-alt mt-0"></i>CAP Digisoft
                      Solutions, Inc. <br />
                      2770 Main Street, Suite # 93,
                      <br />
                      Frisco, TX 75033
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <h5 className="text-uppercase">El Salvador</h5>
                  <ul className="contact-info mb-0 mt-lg-3 mt-2">
                    <li>
                      <strong>
                        {" "}
                        <i className="fa fa-envelope mt-0"></i>
                      </strong>
                      <a href="mailto:info@capdigisoft.com">
                        info@capdigisoft.com
                      </a>
                    </li>
                    <li>
                      <FaPhone />
                      <a href="tel:2149889484">(214) 988-9484 </a>
                    </li>
                    <li>
                      <i className="fa fa-map-marker-alt mt-0"></i>Bambu City
                      Center, Centro Corporativo, Nivel 4, Oficina 5.
                      <br />
                      Blvd. El Hipódromo y Ave. Las Magnolias Col. San Benito,
                      San Salvador, El Salvador.
                      {/*
                                    <!-- Colonia Lomas de San Francisco. Final Calle 1. Antiguo Cuscatlan, La Libertad. El Salvador. C.A -->
                                    */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <h5 className="text-uppercase">india</h5>
                  <ul className="contact-info mb-0 mt-lg-3 mt-2">
                    <li>
                      <i className="fa fa-envelope mt-0"></i>
                      <a href="mailto:info@capdigisoft.com">
                        info@capdigisoft.com
                      </a>
                    </li>
                    <li>
                      <FaPhone />
                      <a href="tel:04224365975">(0422) 436-5975 </a>/{" "}
                      <a href="tel:04224369975">9975</a>
                    </li>
                    <li>
                      <i className="fa fa-map-marker-alt mt-0"></i>LPK Complex,
                      <br /># 409, MTP Road,
                      <br />
                      Coimbatore 641 002,
                      <br />
                      Tamilnadu, India.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <h5 className="text-uppercase">&nbsp;</h5>
                  <ul className="contact-info mb-0 mt-lg-3 mt-2">
                    <li>
                      <i className="fa fa-envelope mt-0"></i>
                      <a href="mailto:info@capdigisoft.com">
                        info@capdigisoft.com
                      </a>
                    </li>
                    <li>
                      <FaPhone />
                      <a href="tel:04224216875">(0422) 421-6875 </a>/{" "}
                      <a href="tel:04224216975">6975</a>
                    </li>
                    <li>
                      <i className="fa fa-map-marker-alt mt-0"></i>MD Tower, #
                      4, S.R Iyer Layout,
                      <br />
                      Trichy Road, Ramanathapuram,
                      <br /> Coimbatore 641 045,
                      <br />
                      Tamilnadu, India.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-lg-5 mt-4 bg-white shadow b-r-6">
              <div className="row g-0 flex-row-reverse">
                <div className="col-lg-4 col-xl-5 contact-img d-none d-lg-flex"></div>
                <div className="col-lg-8 col-xl-7">
                  <div className="p-lg-5 p-4">
                    <form
                      className="row g-3 text-start"
                      id="contact_form"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="col-12 col-md-6 col-lg-6">
                        <label className="form-label" for="first">
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          onChange={formik.handleChange}
                          onInput={handleNameInput}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                          placeholder="Name"
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="error-msg text-danger">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <label className="form-label" for="email">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          onKeyDown={handleKeyPress}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          placeholder="Email"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error-msg text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <label className="form-label" for="phone">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <PhoneInput
                          country={countryCode}
                          placeholder="Phone"
                          value={formik.values.phone}
                          onChange={(phone) =>
                            formik.setFieldValue("phone", phone)
                          }
                          containerStyle={{
                            borderRadius: "4px",
                          }}
                          inputStyle={{
                            width: "100%",
                            height: "38px",
                          }}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="error-msg text-danger">
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-12 col-md-6 col-lg-6">
                        <label className="form-label" for="company">
                          Company <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company"
                          name="company"
                          placeholder="Company"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.company}
                        />
                        {formik.touched.company && formik.errors.company ? (
                          <div className="error-msg text-danger">
                            {formik.errors.company}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-12 col-lg-12">
                        <label className="form-label" for="add">
                          Message <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          id="message"
                          name="message"
                          rows="4"
                          placeholder="Message"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                        ></textarea>
                        {formik.touched.message && formik.errors.message ? (
                          <div className="error-msg text-danger">
                            {formik.errors.message}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-12 col-lg-12">
                        <button
                          type="submit"
                          className="btn cust-btn-primary secondary-main mt-3"
                          id="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-none mt-4"></div>
          </div>
        </section>
      </div>
      <FooterOne />
    </div>
  );
};

export default Contactus;
