import React from "react";
import { Slide } from "react-slideshow-image";

// marquee images imports
import mc1 from "../../../../../app/assets/images/idea-booster/marquee-media/m1.png";
import mc2 from "../../../../../app/assets/images/idea-booster/marquee-media/m2.png";
import mc3 from "../../../../../app/assets/images/idea-booster/marquee-media/m3.png";
import mc4 from "../../../../../app/assets/images/idea-booster/marquee-media/m4.png";
import mc5 from "../../../../../app/assets/images/idea-booster/marquee-media/m5.png";
import mc6 from "../../../../../app/assets/images/idea-booster/marquee-media/m6.png";
import mc7 from "../../../../../app/assets/images/idea-booster/marquee-media/m7.png";
import mc8 from "../../../../../app/assets/images/idea-booster/marquee-media/m8.png";
import mc9 from "../../../../../app/assets/images/idea-booster/marquee-media/m9.png";
import mc10 from "../../../../../app/assets/images/idea-booster/marquee-media/m10.png";
import mc11 from "../../../../../app/assets/images/idea-booster/marquee-media/m11.png";

const Hero = () => {
  const responsiveSettings2 = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        indicators: false,
        autoplay: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        indicators: false,
        autoplay: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        indicators: false,
        autoplay: true,
      },
    },
  ];
  /*Scrol slider ends */
  const properties = {
    prevArrow: (
      <button className="cs-btn btn-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-left"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button className="cs-btn btn-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-right"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
          />
        </svg>
      </button>
    ),
  };
  return (
    <>
      {/* Apps We've Worked with Section Start Here */}
      <div className="wg-result cust-padding border-line-btm pb-0 pb-lg-5">
        <div className="container custom-cn-width">
          <div className="content text-center">
            <div className="sectiontitle-element--center wow fadeInUp style-2">
              <div className="main-title sectiontitle-elements pb-0">
                <h2 class="fw-normal mb-0 pb-0">Apps We've Worked with</h2>
              </div>
            </div>
          </div>
          <Slide
            className="brand-slider"
            {...properties}
            responsive={responsiveSettings2}>
            <div className="each-slide-effect mx-3 align-items-center justify-content-center">
              <div class="language-item">
                <img src={mc1} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc2} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc3} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc4} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc5} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc6} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc7} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc8} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc9} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc10} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
            <div className="each-slide-effect mx-3">
              <div class="language-item">
                <img src={mc11} alt="Logo" class="img-fluid mb-1" />
              </div>
            </div>
          </Slide>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>
        {`
          .language-item {
            display: block;
            width: 300px;
            text-align: center;
            border-radius: 16px;
            padding: 30px;
          }
          .language-item .title a:hover {
            color: #030014;
          }
          .language-item img {
            margin-bottom: 20px;
            border-radius: 0;
          }
          .fura-logo-text,
          .jugl-logo-text,
          .logo-text {
            width: 100px;
          }
          .language-item .title {
            font-size: 22px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: -0.66px;
          }
          .language-item .title a {
            color: #030014;
            font-weight: 600;
            text-decoration: none;
          }
          .marquee {
            overflow: hidden;
            user-select: none;
          }
          .marquee,
          .marquee__group {
            display: flex;
            gap: var(--gap);
          }
          .marquee__group {
            flex-shrink: 0;
            align-items: center;
            justify-content: space-around;
            min-width: 90%;
            animation: scroll-x var(--duration) linear infinite;
          }
          @media (prefers-reduced-motion: reduce) {
            .marquee__group {
              animation-play-state: paused;
            }
          }
          .marquee--vertical {
            --mask-direction: to bottom;
          }
          .marquee--vertical .marquee__group {
            animation-name: scroll-y;
          }
          .marquee--reverse .marquee__group {
            animation-direction: reverse;
            animation-delay: -3s;
          }
          @keyframes scroll-x {
            from {
              transform: translateX(var(--scroll-start));
            }
            to {
              transform: translateX(var(--scroll-end));
            }
          }
          @keyframes scroll-y {
            from {
              transform: translateY(var(--scroll-start));
            }
            to {
              transform: translateY(var(--scroll-end));
            }
          }
          .marquee svg {
            width: var(--size);
            aspect-ratio: 16/9;
            padding: calc(var(--size) / 10);
            border-radius: 0.5rem;
          }
          .marquee--vertical .product-wrapper,
          .marquee--vertical svg {
            aspect-ratio: 1;
            width: calc(var(--size) / 1.5);
            padding: calc(var(--size) / 6);
          }
          .marquee .product-wrapper {
            width: var(--size);
            aspect-ratio: 16/9;
            padding: calc(var(--size) / 10);
            border-radius: 0.8rem;
          }
          @media (max-width: 575px) {
            .language-item {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              text-align: center;
              border-radius: 16px;
              padding: 30px;
              margin-right: auto;
              margin-left: auto;
            }
          }
        `}
      </style>
    </>
  );
};

export default Hero;
