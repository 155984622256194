import React, { useEffect } from 'react';

import { Helmet } from "react-helmet";

const ProductsPage = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = 'Recruitment Process Outsourcing by Capdigisoft: Efficiency, Expertise, Excellence'; // Reset title if necessary
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <Helmet>
      <title>
        Recruitment Process Outsourcing by Capdigisoft: Efficiency, Expertise, Excellence
      </title>
      <meta
        name="description"
        content="Discover the power of strategic outsourcing with Capdigisoft. Our Recruitment Process Outsourcing services redefine your hiring process for optimal efficiency and talent acquisition"
      />
      <meta name="author" content="https://capdigisoft.com" />
      <meta name="robots" content="index, follow" />

      <link
        rel="alternate"
        hreflang="x-default"
        href="https://capdigisoft.com/staffing-support-service"
      />

      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Recruitment Process Outsourcing by Capdigisoft: Efficiency, Expertise, Excellence"
      />
      <meta
        property="og:url"
        content="https://capdigisoft.com/staffing-support-service"
      />
      <meta
        property="og:image"
        content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
      />
      <meta
        property="og:description"
        content="Capdigisoft is your trusted ally in recruitment success. Our Recruitment Process Outsourcing services are tailored to optimize your hiring process and drive organizational growth."
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="CAP Digisoft - IT Services and Solutions Company"
      />
      <meta
        name="twitter:description"
        content="CAP Digisoft - A technology innovation company. Our Team comprises of the technology leaders and well experienced professionals from various domains."
      />
      <meta
        name="twitter:image"
        content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
      />
      <meta name="twitter:site" content="@CAPDigisoft" />
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "capdigisoft",
          "url": "https://capdigisoft.com/",
          "logo": "https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png",
          "sameAs": [
            "https://www.facebook.com/capdigisoftsolutions/",
            "https://x.com/CAPDigisoft",
            "@capdigisoftsolutions",
            "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw",
            "https://www.linkedin.com/company/cap-digisoft-solutions-inc"
          ]
        }`}
      </script>
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Capdigisoft",
              "item": "https://capdigisoft.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "staffing-support-service",
              "item": "https://capdigisoft.com/staffing-support-service"
            }
          ]
        }`}
      </script>
    </Helmet>
  );
};

export default ProductsPage;
