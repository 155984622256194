import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/verific/verific1.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/verific/verific2.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/verific/verific3.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/verific/verific4.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/xampp.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/js.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech8 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/smart-swatcher1.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/paperboy-1.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/edexa1.png";

const Verific = () => {
  useEffect(() => {
    document.title = "Verific | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div className="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div className="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Verific</h2>
          <p class="text-white text-center pb-4 pt-3 px-4">
            Transform customer feedback into actionable insights; identify and
            dissect negative reviews for swift and effective resolution
          </p>
          <div className="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div className="about py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Verific?</p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12">
              <p className="p-4 p-lg-0">
                Verific is an innovative app designed to transform customer
                feedback into actionable insights for businesses. It employs
                advanced sentiment analysis to categorize feedback and identify
                specific issues mentioned in negative reviews. With a real-time
                resolution dashboard and built-in communication tools, Verific
                enables businesses to swiftly address concerns and enhance
                customer satisfaction, contributing to improved brand perception
                and operational efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="picture">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="picture-img text-center">
                <img class="tracky-mockup img-fluid" src={cutout1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                The e-commerce platform encountered a significant increase in
                unfavorable reviews, specifically concerning shipping delays and
                product quality issues. These issues directly affected customer
                satisfaction levels, potentially leading to customer churn and a
                negative impact on the brand's reputation.
              </p>
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                To address these challenges, the platform integrated Verific, a
                solution that streamlined the identification of issues and
                facilitated targeted improvements. Swift resolutions and direct
                engagement led to a substantial reduction in negative reviews,
                resulting in a notable improvement in customer satisfaction.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="picture1">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="impact py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12  ">
              <ul class="impact pt-2 list-styled">
                <li>Improved Issue Resolution</li>
                <li>Swift Response to Urgent Matters</li>
                <li>Enhanced Customer Engagement</li>
                <li>Proactive Issue Mitigation</li>
                <li>Customer Retention</li>
                <li>Operational Efficiency</li>
                <li>Positive Brand Perception</li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-12 pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-0 pt-lg-5">
              <div className="impact-img4 d-flex flex-wrap align-items-center">
                <div className="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech7} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech8} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12  pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p className="pt-1 pt-lg-4 p-4 p-lg-0">
                Verific has proven to be a game-changer for businesses seeking
                to transform customer feedback into actionable insights. By
                addressing the specific needs of the e-commerce platform,
                Verific not only enhanced issue resolution and customer
                engagement but also contributed to overall operational
                efficiency and positive brand perception. The app stands as a
                testament to the power of technology in elevating customer
                satisfaction and business success.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-cases pb-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center py-4 g-4">
            <div className="col-lg-4 col-sm-12 ">
              <Link to="/smart-swatchers">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4>Smart Swatcher</h4>
                  </div>
                  <p class="pt-2">
                    Empowering Hair Color Enthusiasts with a Mobile App{" "}
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/paper_boy">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Paper Boy</h4>
                  </div>
                  <p class="pt-2">
                    Sharing knowledge while earning through MLM
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/edexa">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Edexa</h4>
                  </div>
                  <p class="pt-2">AI delivers solutions from books, images</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Verific;
