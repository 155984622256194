import React, {useState} from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import c1 from "../../app/assets/images/banner/c1.png";
import c2 from "../../app/assets/images/banner/c2.png";
import c3 from "../../app/assets/images/banner/c3.png";
import c4 from "../../app/assets/images/banner/c4.png";
import c5 from "../../app/assets/images/banner/c5.png";
import c6 from "../../app/assets/images/banner/c6.png";
import {FaChevronLeft, FaChevronRight } from "react-icons/fa6";

const testimonials = [
  {
    name: "Jim Walker",
    image: c3,
    text: `As a lawyer, I have been very careful about the internet. I knew I needed to get a new website when I was shifting the focus of my practice. They did an amazing job-giving me a lot more than I expected. They gave me what I didn't know I needed until I saw it. They even did a complete redesign free when they decided their first design wasn't strong enough. I didn't even have to ask. And finally, having a lawyer on their team helped from my perspective...`,
  },
  {
    name: "Cody Ngo",
    image: c4,
    text: `Thank you and your team for such an outstanding job with the website and met all our requirements. We appreciate the professionalism and quick turnaround time!. I enjoyed working with you and your team and will definitely keep you guys in mind for future project.`,
  },
  {
    name: "Chris",
    image: c5,
    text: `We have been a customer of CAP Digisoft since 2010 and can tell you that we have been extremely happy with their work. Not only has their team been able to deliver on their assigned tasks, they also have shown a strong desire to perform at the best level possible. Often offering suggestions to improve upon current processes, CAP Digisoft has become an invaluable partner for my business and an integral part of our success. I would highly recommend them to any company looking for BPO support.`,
  },
  {
    name: "Anil Keswani",
    image: c1,
    text: `CAP Digisoft Team has worked on several websites for me. I have not found a better company to work with. They are fast and thorough. CEO has built a strong team.`,
  },
  {
    name: "Paul Bucky",
    image: c2,
    text: `You guys just did amazing work for us, which is already paying off. Thanks`,
  },
  {
    name: "Terry Johnson",
    image: c6,
    text: `I want to thank you for all that you do, week in and week out, helping us fight the battle to bring in revenue and new clients. You work with diligence, excellence and integrity, and I’m grateful for you. You help us make a difference in the lives of business owners and their families across North America, in the lives of our team, and in my life as well. I am most appreciative, and thankful.`,
  },
];

const ReadMore = ({ children }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text-dark">
      {isReadMore ? `${children.slice(0, 80)}...` : children}
      <span
        onClick={toggleReadMore}
        className="read-or-hide"
        style={{ color: "#0c5b79", cursor: "pointer", fontWeight: "500" }}
      >
        {isReadMore ? "Read More" : " Show Less"}
      </span>
    </p>
  );
};

const Slider = () => {
  const properties = {
    prevArrow: (
      <button className="custom-arrow testi-prev">
        <FaChevronLeft className="text-white"/>
      </button>
    ),
    nextArrow: (
      <button className="custom-arrow testi-next">
        <FaChevronRight className="text-white"/>
      </button>
    ),
    indicators: true,
    autoplay: false,
  };

  return (
    <>
      <div className="custom-slide ">
        <Slide
          className=""
          easing="ease"
          {...properties}
          responsive={responsiveSettings}
        >
          {testimonials.map((testimonial, index) => (
            <div className="each-slide" key={index}>
              <div className="sl-wrapper text-start">
                <div className="card">
                  <div className="testi-box">
                    <div className="testi-img z-index-1 position-relative">
                      <img
                        width={90}
                        src={testimonial.image}
                        alt="Logo"
                        className="img-fluid mb-1 rounded-circle"
                      />
                    </div>
                    <h5 className="mt-2 mb-1 text-dark">{testimonial.name}</h5>
                    <ul className="list-unstyled list-inline mb-3 f-14">
                      <li className="list-inline-item text-yellow me-0">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="list-inline-item text-yellow me-0">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="list-inline-item text-yellow me-0">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="list-inline-item text-yellow me-0">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="list-inline-item text-yellow">
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <ReadMore>{testimonial.text}</ReadMore>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slide>
      </div>
    </>
  );
};

const responsiveSettings = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
export default Slider;
