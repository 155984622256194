import React, { useEffect } from "react";

import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";

import {
  Banner,
  Certificates,
  OurSolutions,
  GlobalReach,
  Testimonial,
  Features,
  Blogs,
} from "../layouts/home01/index";

// import { HelmetProvider } from "react-helmet-async";

import Meta from "../Meta/Home";

const HomeOne = () => {
  // useEffect(() => {
  //   document.title =
  //     "CAP Digisoft - Software Development | Artificial Intelligence | Web Solutions";
  // }, []);

  return (
    <div className="home-page">
        
      <HeaderOne />
      <Meta/>
      <Banner />
      <Certificates />
      <OurSolutions />
      <GlobalReach />
      <Testimonial />
      <Blogs/>
      <Features />
      <FooterOne />
    </div>
  );
};

export default HomeOne;
