import React from "react";

//service icons imports
import serviceIcon1 from "../../../../app/assets/images/plug-and-play/serv-1.png";
import serviceIcon2 from "../../../../app/assets/images/plug-and-play/serv-2.png";
import serviceIcon3 from "../../../../app/assets/images/plug-and-play/serv-3.png";

const Tailored = () => {
  return (
    <>
      <div class="cust-padding-2 exp-serv bg-white">
        <div class="container custom-cn-width">
          <div class="text-center text-white cust-head">
            <div class="main-title sectiontitle-elements">
              <h2 class="fw-normal wow animate__bounceInn mb-4 animated">
                Tailored Solutions <br />
                for Immediate Impact
              </h2>
            </div>

            <p class="text-dark grad-cont-para">
              Plug and Play Call Center provides customized solutions for unique
              business challenges. Our approach ensures rapid integration and
              scalable solutions for immediate impact.{" "}
            </p>

            <h4 class="text-dark">Here’s how we make this happen:</h4>
          </div>

          <div class="row align-items-center">
            <div class="col-md-12 ">
              <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6">
                  <div class="process_card wow animate__fadeIn px-3 mt-5">
                    <div class="serv-icons sec-primary-ic">
                      <img src={serviceIcon1} alt="" />
                    </div>

                    <div class="process_content">
                      <h4 class="text-dark">Customized Expertise</h4>
                      <h6 class="sub-head-grad">A Foundation for Success:</h6>

                      <ul class="grad-list">
                        <li>
                          <b>Exclusive Customer Success Team:</b> We assign a
                          dedicated team to each client, fostering a deep
                          partnership.
                        </li>
                        <li>
                          <b>Industry-Specific Knowledge:</b> Our teams
                          specialize in various industries, offering tailored
                          insights and solutions.
                        </li>
                        <li>
                          <b>Personalized Strategy Development:</b> We
                          collaborate closely to align strategies with your
                          brand's voice.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-6">
                  <div
                    class="process_card wow animate__fadeIn px-3 mt-5"
                    data-animation="fadeInUp"
                    data-delay="1.5s"
                    data-wow-duration="1.2s">
                    <div class="serv-icons sec-primary-ic">
                      <img src={serviceIcon2} alt="" />
                    </div>
                    <div class="process_content">
                      <h4 class="text-dark">Quick Integration</h4>
                      <h6 class="sub-head-grad">
                        Seamless Transition for Swift Results:
                      </h6>

                      <ul class="grad-list">
                        <li>
                          <b>Streamlined Onboarding Process:</b> Quick and
                          efficient onboarding for rapid implementation.
                        </li>
                        <li>
                          <b>Advanced Technological Infrastructure:</b>{" "}
                          Cutting-edge tech integration for seamless service.
                        </li>
                        <li>
                          <b>Immediate Operational Uplift:</b> Day one sees a
                          boost in operational capabilities.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-6">
                  <div
                    class="process_card no-bt wow animate__fadeIn px-3 mt-5"
                    data-animation="fadeInUp"
                    data-delay="2s"
                    data-wow-duration="1.5s">
                    <div class="serv-icons sec-primary-ic">
                      <img src={serviceIcon3} alt="" />
                    </div>
                    <div class="process_content">
                      <h4 class="text-dark">Adaptive Scaling</h4>
                      <h6 class="sub-head-grad">Growing with Your Business:</h6>

                      <ul class="grad-list">
                        <li>
                          <b>Flexible Service Offerings:</b> Easily scalable
                          solutions to meet evolving business needs.
                        </li>
                        <li>
                          <b>Resource Allocation Optimization:</b> Continuous
                          monitoring ensures efficient resource utilization.
                        </li>
                        <li>
                          <b>Long-term Partnership for Growth:</b> Committed to
                          adapting services for sustained success and growth.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------immediate impacts end -------- */}
      <style jsx>{`
        .exp-serv {
          background: #f5f5f5;
        }
        .cust-head {
          margin-bottom: 22px;
        }
        .grad-cont-para {
          padding: 0px 200px;
          margin-top: 30px;
        }
        .process_card {
          margin-bottom: 0;
          margin-right: 0;
        }
        .serv-icons {
          padding: 20px;
          width: 85px;
          border-radius: 150px;
          margin-bottom: 20px;
        }
        .process_content h4 {
          font-weight: 700;
          font-size: 28px;
          margin-bottom: 12px;
          text-transform: unset;
        }
        .sub-head-grad {
          font-size: 20px;
          font-weight: 400;
        }
        .grad-list {
          margin-left: 20px;
          margin-top: 20px;
          list-style-type: disc;
        }
        .grad-list li {
          color: #000;
          font-size: 18px;
          margin-bottom: 10px;
        }
        .serv-icons img {
          width: 45px;
        }
        @media (max-width: 991px) {
          .grad-cont-para {
            padding: 0px;
          }
        }
      `}</style>{" "}
    </>
  );
};

export default Tailored;
