import React from 'react';

import HeaderDark from '../../../common/header/HeaderDark';
import FooterOne from '../../../common/footer/FooterOne';

import company from '../../../../src/app/assets/images/company.jpg'

import t1w from '../../../../src/app/assets/images/company/t1-w.png'
import t2w from '../../../../src/app/assets/images/company/t2-w.png'
import t3w from '../../../../src/app/assets/images/company/t3-w.png'
import t4w from '../../../../src/app/assets/images/company/t3-w.png'

import Meta from "../../Meta/Our-company";

const Ourcompany = () => {
return (
<div className="ourcompany-page">
    <HeaderDark />
    <Meta/>
    <section class="d-flex align-items-center justify-content-center position-relative banner-sec pt-0"
        style={{marginTop:"21px", paddingBottom:"120px"}}>
        <div className="container custom-cn-width position-relative">
            <div className="row position-relative justify-content-center align-items-center g-4 g-xl-5">
                <div className="col-lg-7 col-md-6 md-mb-5 order-2 order-md-1">
                    <div className="about-border">
                        <img src={company} alt="product" class="img-fluid mb-1" />
                        <div className="about-bg"></div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 ps-md-4 ps-lg-5 order-1 order-md-2">
                    <h2>About CAP Digisoft Solutions</h2>
                    <p class="mt-3">CAP Digisoft Solutions has been delivering Software and KPO services since 2003,
                        servicing 100+ small, medium and larger corporations across USA.</p>
                    <p class="mt-3">50+ Software Engineers and 300+ Data Experts with US client support</p>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-light section-padding">
        <div className="container custom-cn-width">
            <div className="row g-xl-5 g-4 justify-content-center text-center">
                <div className="col-lg-12 mb-4 mb-lg-5">
                    <h1 class="fw-light">Our Journey</h1>
                </div>
            </div>
            <div className="row justify-content-center g-xl-5 g-4 align-items-center">
                <div className="col-lg-7">
                    <div className="timeline">
                        <div className="timeline__event">
                            <div className="timeline__event__icon sec-primary-ic">
                            <img src={t1w} alt="product" class="img-fluid mb-1" />
                            </div>
                            {/* <div className="timeline__event__date">2003</div> */}
                            <div className="timeline__event__content shadow">
                                <p class="h4 mb-lg-3 mb-2 font-size-25 text-dark">Since 2003, Data Services</p>
                                <p className='font-size-17 text-dark'>Since establishment, CDS has handled <span class="fw-500">various Document and Data
                                        services for Legal, Healthcare, and Mortgage industries.</span></p>
                            </div>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__event__icon sec-primary-ic">
                            <img src={t2w} alt="product" class="img-fluid mb-1" />
                            </div>
                            {/* <div className="timeline__event__date">2007</div> */}
                            <div className="timeline__event__content shadow">
                                <p class="h4 mb-lg-3 mb-2 font-size-25 text-dark">Since 2007, Knowledge Services</p>
                                <p className='font-size-17 text-dark'>Beginning in 2007 CDS expanded its services in the knowledge industry to support 
                                    <span class="fw-500">{" "} various intellectual business processes. Supported the Legal
                                        and Software Industries.</span></p>
                            </div>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__event__icon sec-primary-ic">
                            <img src={t3w} alt="product" class="img-fluid mb-1" />
                            </div>
                            {/* <div className="timeline__event__date">2012</div> */}
                            <div className="timeline__event__content shadow">
                                <p class="h4 mb-lg-3 mb-2 font-size-25 text-dark">Since 2012, Intellectual Processing Tools</p>
                                <p className='font-size-17 text-dark'>CDS formed an exclusive software and research team to develop its prosperity software
                                    tools to enhance the process. <span class="fw-500">This enabled our clients to get
                                        the most accurate data services and API interface for direct data
                                        exchange.</span></p>
                            </div>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__event__icon sec-primary-ic scope-none">
                            <img src={t4w} alt="product" class="img-fluid mb-1" />
                            </div>
                            {/* <div className="timeline__event__date">2013</div> */}
                            <div className="timeline__event__content shadow">
                                <p class="h4 mb-lg-3 mb-2 font-size-25 text-dark">Since 2013, Legal Process Outsourcing</p>
                                <p className='font-size-17 text-dark'>Supporting more than 900 law firms across the USA for their medical legal cases.
                                    <span class="fw-500">Operating under the Medical Legal Spider brand.</span>Processed
                                    more than 10 million case documents.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <section class="pb-0 section-padding">
		<div className="container custom-cn-width ">
			<div className="row g-xl-5 g-4 align-items-center justify-content-center text-center">
				<div className="col-xxl-7 col-xl-8 col-lg-9">	
					<h1 class="fw-light mb-lg-4 mb-3 text-dark">Discover the CAP Digisoft Difference</h1>
					<p class="px-lg-5 text-dark font-size-17">Our expert Marketing Consulting team can create the perfect, custom marketing strategy to help enable your buyers, boost conversions, and save you money.</p>
				</div>
			</div>
		</div>
	</section>

    <section class="text-center text-lg-start section-padding">
		<div className="container custom-cn-width ">
			<div className="cta-block cta-blue b-r-6">
				<div className="row g-xl-5 g-4 align-items-center justify-content-center">
					<div className="col-lg-9">				
						<h2 class="fw-light text-white font-size-34">Tell us your marketing issue or growth objective! We will help you solve it and grow your business.</h2>
					</div>
					<div className="col-lg-3 text-lg-end">
						<a href="javascript:;" class="btn cust-btn-primary" data-bs-toggle="modal" data-bs-target="#meeting">Let's Talk</a>
					</div>
				</div>
			</div>
		</div>
	</section>

    <FooterOne />
</div>
)
}

export default Ourcompany;