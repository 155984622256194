import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/e-sort/banner.jpg";

import icon1 from "../../../../src/app/assets/images/casestudy/common/icon1.png";
import icon2 from "../../../../src/app/assets/images/casestudy/common/icon2.png";

import PDF from "../../../app/assets/casestudypdf/e-sort-desk-case-study.pdf";

const Esort = () => {
  useEffect(() => {
    document.title = "E-sort Desk System | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-logistics-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div className="container custom-cn-width position-relative">
          <div className="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div className="col-lg-9">
              <h5 class="text-white">Case Study</h5>
              <h1 class="text-white fw-semibold lh-sm">E-Sort Desk</h1>
            </div>
            <div className="col-lg-3 text-lg-end">
              <a href={PDF} class="default-btn orange-btn" target="_blank" rel="noreferrer">
                <i class="fa fa-file-pdf me-1"></i>Download PDF
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row justify-content-center g-lg-5 g-4 align-items-center">
            <div className="col-lg-6">
              <h1 class="fw-light mb-lg-4 mb-3">Scenario</h1>
              <p>
                A client who runs a law firm approached us to discuss the
                possibility of sorting and deduplicating their Medical records
                as they found it extremely time-consuming and required an
                excessive amount lots of human resources.
              </p>
            </div>
            <div className="col-lg-6">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 text-center text-lg-start section-padding-btm">
        <div className="container custom-cn-width">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-7 mb-4 mb-lg-5">
              <h1 class="fw-light">CDS Solution Approach</h1>
            </div>
          </div>
          <div className="row g-xl-5 g-4">
            <div className="col-lg-6 ">
              <div className="features-item h-100 justify-content-start">
                <div className="icons sec-primary-ic mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon1}
                    class="img-fluid"
                    alt="Prospect Database Cleansing"
                  />
                </div>
                <h4>Approach</h4>
                <p class="mt-3">
                  Our approach was to make it easier for law firms to retrieve
                  their records. To reduce the time and resources involved in
                  the sorting process, an E-Sorting application was developed to
                  enable easy and quick retrieval of records for litigation
                  processes.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="New Prospect Identification"
                  />
                </div>
                <h4>Strategy</h4>
                <p class="mt-3">
                  The files are fed as input into the system where they are
                  assigned to appropriate human resources with the E-sort app.
                  These users identify the parameters to code the record for
                  sorting via a user-friendly Interface. Once the coding is
                  done, the system automatically identifies the duplicates and
                  creates deduplicated records for sorting., The system also has
                  options for quality control, which is performed by a separate
                  set of users. Once the Q.C check is done, the system creates
                  the final de duplicated document for the law firm’s review.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding ">
        <div className="container custom-cn-width">
          <div className="row g-lg-0 g-4 justify-content-center">
            <div className="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div className="p-lg-5 p-4">
                <h1 class="fw-light text-white mb-3">Results</h1>
                <p class="text-white">
                  This system reduced the time involved in the manual
                  deduplication and management of the sorting processes.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div className="p-lg-5 p-4">
                <h1 class="fw-light  mb-3">Conclusion</h1>

                <p className="">
                  The clients saw the benefits of the e-sorting application that
                  provided them with ability to quickly retrieve data for their
                  litigation cases, within a click.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div className="container custom-cn-width">
          <div className="row g-5 align-items-center justify-content-center text-center">
            <div className="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div className="container custom-cn-width">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div className="container custom-cn-width">
          <div className="row g-5 text-center">
            <div className="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Case Studies</h1>
            </div>
          </div>
          <div className="">
            <div className="row g-4 g-xl-5 text-center text-xl-start">
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">Liti-code System</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/liti-code-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Medico – Risk Chart App
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/medico-risk-chart-app-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Medico AI – Chrono Sort App (Attesta)
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/medico-ai-chrono-sort-app-attesta-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterOne />
    </div>
  );
};

export default Esort;
