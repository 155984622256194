import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
// import HeaderLight from '../../../common/header/HeaderLight';
import FooterOne from "../../../common/footer/FooterOne";
import HeaderDark from "../../../common/header/HeaderDark";

import book1 from "../../../../src/app/assets/images/free-download//1.png";
import book2 from "../../../../src/app/assets/images/free-download/2.jpg";
import book3 from "../../../../src/app/assets/images/free-download/3.png";
import book4 from "../../../../src/app/assets/images/free-download/4.png";
import book5 from "../../../../src/app/assets/images/free-download/5.jpg";
import book6 from "../../../../src/app/assets/images/free-download/6.jpg";
import book7 from "../../../../src/app/assets/images/free-download/7.jpg";
import book8 from "../../../../src/app/assets/images/free-download/8.jpg";
import book9 from "../../../../src/app/assets/images/free-download/9.jpg";
import book10 from "../../../../src/app/assets/images/free-download/10.jpg";
import book11 from "../../../../src/app/assets/images/free-download/11.jpg";
import book12 from "../../../../src/app/assets/images/free-download/12.jpg";
import book13 from "../../../../src/app/assets/images/free-download/13.jpg";
import book14 from "../../../../src/app/assets/images/free-download/14.jpg";
import book15 from "../../../../src/app/assets/images/free-download/15.jpg";
import book16 from "../../../../src/app/assets/images/free-download/16.jpg";
import Meta from "../../Meta/Free_downloads";

const FreeDownloads = () => {
  const books = [
    {
      id: 1,
      title: " The Outbound Prospecting Playbook",
      bookcover: book16,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/the-outbound-prospecting-playbook.pdf",
      pagename: "the-outbound-prospecting-playbook",
    },
    {
      id: 2,
      title: "Writing a business plan for your app or software idea",
      bookcover: book1,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/writing-a-business-plan-for-your-app-or-software-idea.pdf",
      pagename: "writing-a-business-plan-for-your-app-or-software-idea",
    },
    {
      id: 3,
      title: "Idea to product workbook",
      bookcover: book2,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/idea-to-product-workbook.pdf",
      pagename: "idea-to-product-workbook",
    },
    {
      id: 4,

      title: "Mobile app & software development",
      bookcover: book3,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/mobile-app-and-software-development.pdf",
      pagename: "mobile-app-and-software-development",
    },

    {
      id: 5,
      title: "Strategic framework for email marketing",
      bookcover: book4,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/strategic-framework-for-email-marketing.pdf",
      pagename: "strategic-framework-for-email-marketing",
    },

    {
      id: 6,
      title: "The secret to activating your marketing plan",
      bookcover: book5,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/the-secret-to-activating-your-marketing-plan.pdf",
      pagename: "the-secret-to-activating-your-marketing-plan",
    },

    {
      id: 7,
      title: "Social media best practices",
      bookcover: book6,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/social-media-best-practices.pdf",
      pagename: "social-media-best-practices",
    },

    {
      id: 8,
      title: "Best practices for UI and UX designers",
      bookcover: book7,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/best-practices-for-ui-and-ux-designers.pdf",
      pagename: "best-practices-for-ui-and-ux-designers",
    },

    {
      id: 9,
      title: "The myth of build it and they will come",
      bookcover: book9,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/the-myth-of-build-it-and-they-will-come.pdf",
      pagename: "the-myth-of-build-it-and-they-will-come",
    },
    {
      id: 10,
      title: "The next generation of lead generation",
      bookcover: book8,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/the-next-generation-of-lead-generation.pdf",
      pagename: "the-next-generation-of-lead-generation",
      bookdesc: "",
    },

    {
      id: 11,
      title: "Social media image sizes cheat sheet",
      bookcover: book10,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/social-media-image-sizes-cheat-sheet.pdf",
      pagename: "social-media-image-sizes-cheat-sheet",
    },

    {
      id: 12,
      title: "Website relaunch SEO checklist",
      bookcover: book11,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/website-relaunch-seo-checklist.pdf",
      pagename: "website-relaunch-seo-checklist",
    },

    {
      id: 13,
      title: "Technical SEO site audit",
      bookcover: book12,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/technical-seo-site-audit.pdf",
      pagename: "technical-seo-site-audit",
    },
    {
      id: 14,
      title: "Best content management system WordPress",
      bookcover: book13,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/best-content-management-system-wordpress.pdf",
      pagename: "best-content-management-system-wordpress",
    },
    {
      id: 15,
      title: "Brand checklist",
      bookcover: book14,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/brand-checklist.pdf",
      pagename: "brand-checklist",
    },
    {
      id: 16,
      title: "ADA compliance",
      bookcover: book15,
      downloadUrl:
        "https://capdigisoft.com/backend/freedownloadbooks/resource/ada-compliance.pdf",
      pagename: "ada-compliance",
    },
  ];

  return (
    <div className="free-downloads-page">
      <HeaderDark />
      <Meta />
      <section className="d-flex align-items-center banner-sec ebook-sec text-center text-lg-start pb-5">
        <div className="container custom-cn-width position-relative z-index-1">
          <div className="row align-items-center justify-content-center text-center g-lg-5 g-4 justify-content-end mt-0">
            <div className="col-lg-6 mt-3 ">
              <h2>Free Downloads</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="text-lg-start ebook-sec text-center pt-0 section-padding-btm">
        <div className="container custom-cn-width">
          <div className="row g-lg-5 g-5 justify-content-center">
            {/* Iterate over books and display each */}
            {books.map((book) => (
              <div key={book.id} className="col-xl-3 col-lg-12">
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    {/* Link to the book's download page with the book ID and pagename */}
                    <Link
                      to={`/downloadbook/${book.id}?pagename=${book.pagename}`}>
                      <img
                        src={book.bookcover}
                        alt="team"
                        className="img-fluid bg-shadow b-r-6"
                      />
                      <h4 className="mt-lg-4 mt-2">{book.title}</h4>
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="btn cust-btn-primary secondary-main sm-btn mt-lg-3 mt-2"
                      to={`/downloadbook/${book.id}?bn=${book.pagename}`}>
                      Download
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default FreeDownloads;
