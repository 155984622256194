// src/data/blogData.js
import img1 from "../../../../app/assets/images/cds-blog/blog-24.jpg";
import img2 from "../../../../app/assets/images/cds-blog/blog-23.jpg";
import img3 from "../../../../app/assets/images/cds-blog/blog-22.jpg";
import img4 from "../../../../app/assets/images/cds-blog/blog-20.jpg";
import img5 from "../../../../app/assets/images/cds-blog/blog-1.jpg";
import img6 from "../../../../app/assets/images/cds-blog/blog-21.jpg";
import img7 from "../../../../app/assets/images/cds-blog/blog-18.jpg";
import img8 from "../../../../app/assets/images/cds-blog/blog-19.jpg";
import img9 from "../../../../app/assets/images/cds-blog/blog-20.jpg";
import img10 from "../../../../app/assets/images/cds-blog/blog-12.jpg";
import img11 from "../../../../app/assets/images/cds-blog/blog-16.jpg";
import img12 from "../../../../app/assets/images/cds-blog/blog-15.jpg";
import img13 from "../../../../app/assets/images/cds-blog/blog-11.jpg";
import img14 from "../../../../app/assets/images/cds-blog/blog-14.jpg";
import img15 from "../../../../app/assets/images/cds-blog/blog-13.jpg";
import img16 from "../../../../app/assets/images/cds-blog/blog-8.jpg";
import img17 from "../../../../app/assets/images/cds-blog/blog-10.jpg";
import img18 from "../../../../app/assets/images/cds-blog/blog-9.jpg";
import img19 from "../../../../app/assets/images/cds-blog/blog-7.jpg";
import img20 from "../../../../app/assets/images/cds-blog/blog-6.jpg";

const blogData = [
    {
        id: 1,
        img: img1,
        title: "Marketing Strategies for the Age of Everything Online",
        author: "Barath Kumar",
        date: "May 8, 2024",
        description: "Strategic Digital Marketing Strategies for the Age of Everything Online. The digital age has...",
        link: "/marketing-strategies-for-the-age-of-everything-online"
    },
    {
        id: 2,
        img: img2,
        title: "The Impact of Knowledge Processing on Business Intelligence",
        author: "Prakash Natraj",
        date: "May 6, 2024",
        description: "Transforming Data into Business Intelligence. The Impact of Knowledge Processing on Business Intelligence. The business world...",
        link: "/the-impact-of-knowledge-processing-on-business-intelligence"
    },
    {
        id: 3,
        img: img3,
        title: "The Importance of UI/UX Design in Today’s Digital Landscape",
        author: "Arun Kumar",
        date: "May 2, 2024",
        description: "Mastering UI/UX Design. The Importance of UI/UX Design in Today’s Digital Landscape. Imagine walking into a store where ...",
        link: "/the-importance-of-ui-ux-design-in-todays-digital-landscape"
    },
    {
        id: 4,
        img: img4,
        title: "Building a Fortress: Enhancing Web Security in 2024",
        author: "Arun Kumar",
        date: "April 24, 2024",
        description: "Web Security in Today’s Digital Landscape Building a Fortress: Enhancing Web Security in 2024 The internet is like...",
        link: "/building-a-fortress-enhancing-web-security-in-2024"
    },
    {
        id: 5,
        img: img5,
        title: "Optimizing Customer Service with AI-powered Solutions",
        author: "Prakash Natraj",
        date: "April 26, 2024",
        description: "Elevate your Customer Service with AI Optimizing Customer Service with AI-powered Solutions Imagine a world where customer service...",
        link: "/optimizing-customer-service-with-ai-powered-solutions"
    },
    {
        id: 6,
        img: img6,
        title: "The Office Evolved: How Technology is Reshaping the Workplace",
        author: "Barath Kumar",
        date: "April 29, 2024",
        description: "Embracing Innovation The Office Evolved: How Technology is Reshaping the Workplace The workplace is no longer just a physical...",
        link: "/the-office-evolved-how-technology-is-reshaping-the-workplace"
    },
    {
        id: 7,
        img: img7,
        title: "Scaling Success: The Top 5 Customer Success Strategies Winning Today’s Tech Race",
        author: "Prakash Natraj",
        date: "April 15, 2024",
        description: "Customer Success Strategies Scaling Success: The Top 5 Customer Success Strategies Winning Today’s Tech Race In today’s...",
        link: "/scaling-success-the-top-5-customer-success-strategies-winning-todays-tech-race"
    },
    {
        id: 8,
        img: img8,
        title: "The Digital Craftsmen: Essential Tech for Next-Gen Product Development",
        author: "Barath Kumar",
        date: "April 17, 2024",
        description: "App Idea Booster and Product Development The Digital Craftsmen: Essential Tech for Next-Gen Product Development Stepping...",
        link: "/the-digital-craftsmen-essential-tech-for-next-gen-product-development"
    },
    {
        id: 9,
        img: img9,
        title: "Customer Success: The Engine of Enduring Business Triumph",
        author: "Arun Kumar",
        date: "April 12, 2024",
        description: "Customer Success as a Service Customer Success: The Engine of Enduring Business Triumph In the high-speed,...",
        link: "/customer-success-the-engine-of-enduring-business-triumph"
    },
    {
        id: 10,
        img: img10,
        title: "Empathy as Strategy: How Customer Success Is Redefining Tech’s Winners",
        author: "Arun Kumar",
        date: "April 5, 2024",
        description: "Customer Success Empathy as Strategy: How Customer Success Is Redefining Tech’s Winners In the tech ecosystem’s relentless...",
        link: "/empathy-as-strategy-how-customer-success-is-redefining-techs-winners"
    },
    {
        id: 11,
        img: img11,
        title: "Innovation Unleashed: How Idea Booster Initiatives Are Transforming Corporate Culture",
        author: "Barath Kumar",
        date: "April 10, 2024",
        description: "Software App Idea Booster Innovation Unleashed: How Idea Booster Initiatives Are Transforming Corporate Culture In an era where...",
        link: "/innovation-unleashed-how-idea-booster-initiatives-are-transforming-corporate-culture"
    },
    {
        id: 12,
        img: img12,
        title: "Accelerating Market Launch: The Power of Expertise",
        author: "Barath Kumar",
        date: "April 8, 2024",
        description: "Market Launch Mastery Accelerating Market Launch: The Power of Expertise In the adrenaline-fueled tech ecosystem,...",
        link: "/accelerating-market-launch-the-power-of-expertise"
    },
    {
        id: 13,
        img: img13,
        title: "Decoding Success: Vital Metrics for Gauging Customer Impact and Business Growth",
        author: "Prakash Natraj",
        date: "March 25, 2024",
        description: "Streamlining for Growth Decoding Success: Vital Metrics for Gauging Customer Impact and Business Growth Navigating...",
        link: "/decoding-success-vital-metrics-for-gauging-customer-impact-and-business-growth"
    },
    {
        id: 14,
        img: img14,
        title: "Crafting Market Leaders: How Idea to Product Services Forge the Future",
        author: "Barath Kumar",
        date: "March 27, 2024",
        description: "Navigating the Future Crafting Market Leaders: How Idea to Product Services Forge the Future Diving headfirst into ...",
        link: "/crafting-market-leaders-how-idea-to-product-services-forge-the-future"
    },
    {
        id: 15,
        img: img15,
        title: "Navigating the Software Development Boom: A TechCrunch Deep Dive",
        author: "Barath Kumar",
        date: "April 4, 2024",
        description: "The Software Development Surge Navigating the Software Development Boom: A TechCrunch Deep Dive In the fast-paced...",
        link: "/navigating-the-software-development-boom-a-techcrunch-deep-dive"
    },
    {
        id: 16,
        img: img16,
        title: "The Tech-Infused Outsourcing Strategy: Revolutionizing Product Development",
        author: "Gerald Valentine",
        date: "February 26, 2024",
        description: "Software Product Development The Tech-Infused Outsourcing Strategy: Revolutionizing Product Development In...",
        link: "/the-tech-infused-outsourcing-strategy-revolutionizing-product-development"
    },
    {
        id: 17,
        img: img17,
        title: "AI and Personalization: The New Frontier in SaaS Customer Success",
        author: "Arun Kumar",
        date: "March 1, 2024",
        description: "Artificial Intelligence (AI) AI and Personalization: The New Frontier in SaaS Customer Success In the realm of...",
        link: "/ai-and-personalization-the-new-frontier-in-saas-customer-success"
    },
    {
        id: 18,
        img: img18,
        title: "Mastering the Product Development Lifecycle: Leveraging Stats for Success",
        author: "Arun Kumar",
        date: "February 28, 2024",
        description: "Software Product Development Lifecycle Mastering the Product Development Lifecycle: Leveraging Stats for Success...",
        link: "/mastering-the-product-development-lifecycle-leveraging-stats-for-success"
    },
    {
        id: 19,
        img: img19,
        title: "How CSaaS is Rewriting the Rulebook on Customer Experience and Revenue Growth",
        author: "Barath Kumar",
        date: "February 23, 2024",
        description: "Customer Success as a Service How CSaaS is Rewriting the Rulebook on Customer Experience and Revenue Growth In...",
        link: "/how-csaas-is-rewriting-the-rulebook-on-customer-experience-and-revenue-growth"
    },
    {
        id: 20,
        img: img20,
        title: "AI’s Cash Tsunami and the Innovation Race: How Tech’s Betting Big and Why Millennials Are All In",
        author: "Arun Kumar",
        date: "February 21, 2024",
        description: "Artificial intelligence’s power AI’s Cash Tsunami and the Innovation Race: How Tech’s Betting Big and Why...",
        link: "/ais-cash-tsunami-and-the-innovation-race-how-techs-betting-big-and-why-millennials-are-all-in"
    },
];

export default blogData;
