import React from "react";

import streamLine from "../../../../app/assets/images/plug-and-play/streamline.jpg";

const Challenges = () => {
  return (
    <>
      <div class="cust-padding position-relative bg-white cust-pad-btm-100">
        <div class="gradient-wrapper">
          <div class="aqua-grad-shape"></div>
        </div>
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6 order-lg-1 order-2 pt-4 pt-lg-0">
              <div class="stream-sec text-center">
                <img src={streamLine} alt="pp-img" class="img-fluid" />
              </div>
            </div>

            <div class="col-lg-6 order-lg-2 order-1">
              <div class="strm-cont">
                <div class="main-title sectiontitle-elements">
                  <h2 class="fw-normal wow animate__bounceInn mb-4 animated">
                    Optimized Solutions for Call Center Challenges
                  </h2>
                </div>

                <p class="text-dark">
                  Plug and Play Call Center offers a targeted solution to the
                  common challenges within the call center sector. By partnering
                  your customer service needs to our{" "}
                  <span class="text-highlight">
                    proficient Central American team
                  </span>
                  , you effortlessly overcome the hurdles associated with
                  staffing, training, and the associated financial commitments
                  of infrastructure and operational activities.
                </p>
                <p class="text-dark">
                  Our inclusive service model comprehensively{" "}
                  <span class="text-highlight">
                    addresses all dimensions of call center including project
                    coordination, hiring, training, infrastructure, service
                    management, including performance evaluation and payroll
                    administration
                  </span>
                  , enabling you to devote your energies to your primary
                  business operations.
                </p>
                <p class="text-dark">
                  Our adaptable service structure facilitates the{" "}
                  <span class="text-highlight">
                    efficient scaling of your customer service capabilities to
                    meet varying demand levels, ensuring both cost efficiency
                  </span>{" "}
                  and exceptional service quality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---- challange end ---- */}
    </>
  );
};

export default Challenges;
