import React, { useEffect } from 'react';

import { Helmet } from "react-helmet";

const ProductsPage = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = 'Capdigisoft | Redefining Success with Client-Centric Solutions'; // Reset title if necessary
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <Helmet>
      <title>
        Capdigisoft - Redefining Success with Client-Centric Solutions
      </title>
      <meta
        name="description"
        content="Capdigisoft is dedicated to your success. Explore our Client Success Services, designed to optimize your growth and ensure unparalleled customer satisfaction."
      />
      <meta name="author" content="https://capdigisoft.com" />
      <meta name="robots" content="index, follow" />

      <link
        rel="alternate"
        hreflang="x-default"
        href="https://capdigisoft.com/client-success"
      />

      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Capdigisoft - Redefining Success with Client-Centric Solutions"
      />
      <meta
        property="og:url"
        content="https://capdigisoft.com/client-success"
      />
      <meta
        property="og:image"
        content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
      />
      <meta
        property="og:description"
        content="Experience a new level of success with Capdigisoft Client Success Services. We are dedicated to driving your business forward through tailored strategies and support."
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Dominate Your Market: Expert B2B Lead Generation"
      />
      <meta
        name="twitter:description"
        content="Scale your business with our proven B2B lead generation strategies. Reach high-quality prospects and boost your sales pipeline. Contact us today!"
      />
      <meta
        name="twitter:image"
        content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
      />
      <meta name="twitter:site" content="@CAPDigisoft" />

      <script type="application/ld+json">
        {`{
			"@context": "https://schema.org",
			"@type": "Organization",
			"name": "capdigisoft",
			"url": "https://capdigisoft.com/",
			"logo": "https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png",
			"sameAs": [
				"https://www.facebook.com/capdigisoftsolutions/",
				"https://x.com/CAPDigisoft",
				"@capdigisoftsolutions",
				"https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw",
				"https://www.linkedin.com/company/cap-digisoft-solutions-inc"
			]
			}`}
      </script>
      <script type="application/ld+json">
        {`{
			"@context": "http://schema.org/",
			"@type": "BreadcrumbList",
			"itemListElement": [
				{
					"@type": "ListItem",
					"position": 1,
					"name": "Capdigisoft",
					"item": "https://capdigisoft.com/"
				},
				{
					"@type": "ListItem",
					"position": 2,
					"name": "client-success",
					"item": "https://capdigisoft.com/client-success"
				}
			]
		}`}
      </script>
    </Helmet>
  );
};

export default ProductsPage;
