import React, { useEffect } from 'react';

import { Helmet } from "react-helmet";
import LogoDark from "../../app/assets/images/logo-dark.png";

const ProductsPage = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = 'Looking to develop your career in a passionate professional environment with dedicated workforce? Join our Team'; // Reset title if necessary
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <Helmet>
      <title>Looking to develop your career in a passionate professional environment with dedicated workforce? Join our Team</title>
      <meta
        name="description"
        content="Looking to develop your career in a passionate professional environment with dedicated workforce? Join our Team."
      />
      <meta name="author" content="https://capdigisoft.com" />
      <meta name="robots" content="index, follow" />

      <link
        rel="alternate"
        hreflang="x-default"
        href="https://capdigisoft.com/careers"
      />

      <link rel="canonical" href="https://capdigisoft.com" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="CAP Digisoft Solutions|Careers" />
      <meta
        property="og:description"
        content="Looking to develop your career in a passionate professional environment with dedicated workforce? Join our Team."
      />
      <meta property="og:url" content="https://capdigisoft.com/" />
      <meta property="og:site_name" content="CAP Digisoft" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/capdigisoftsolutions/"
      />
      <meta
        property="article:modified_time"
        content="2021-08-23T14:13:42+00:00"
      />
      <meta
        property="og:image"
        content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="CAP Digisoft Solutions|Careers" />
      <meta
        name="twitter:description"
        content="Looking to develop your career in a passionate professional environment with dedicated workforce? Join our Team."
      />
      <meta
        name="twitter:image"
        content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
      />
      <meta name="twitter:site" content="@CAPDigisoft" />

      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "capdigisoft",
          "url": "https://capdigisoft.com/",
          "logo": "https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png",
          "sameAs": [
            "https://www.facebook.com/capdigisoftsolutions/",
            "https://x.com/CAPDigisoft",
            "@capdigisoftsolutions",
            "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw",
            "https://www.linkedin.com/company/cap-digisoft-solutions-inc"
          ]
        }`}
      </script>

      <script type="application/ld+json">
        {`{
      "@context": "http://schema.org/",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Capdigisoft",
          "item": "https://capdigisoft.com"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "careers",
          "item": "https://capdigisoft.com/careers/"
        }
      ]
    }`}
      </script>
    </Helmet>
  );
};

export default ProductsPage;
