import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/practica/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/practica/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/practica/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/practica/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/linux.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/apache.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/bussopi.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/genie.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/jugl.png";

const Practica = () => {
  useEffect(() => {
    document.title = "Practica | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div className="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div className="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Practica</h2>
          <p class="text-white text-center pb-4 pt-3 px-4">
            Simple Learning Starts Here
          </p>
          <div className="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div className="about py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-xl-3 col-md-4 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Practica?</p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12">
              <p className="p-4 p-lg-0">
                Practica is an easy to use all in one place App designed for
                schools & colleges to help educators manage, measure, and
                enhance learning experiences using simple chat based system.
                This digitally powered App can empower and improvise teaching
                and learning methods for students and teachers to connect from
                anywhere, on any device that gives the class more flexibility
                and mobility.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="picture">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                In this scenario, Fura, the AI-powered specialty pharmacy
                prescription tool, plays a crucial role in improving the access
                and delivery of specialty medications for pediatric patients
                facing unique healthcare challenges.
              </p>
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                Fura employs predictive analytics to foresee adherence
                challenges and enable proactive intervention. Precision
                prescribing tailors specialty medications based on individual
                patient data for optimal treatment outcomes. Real-time drug
                interaction monitoring ensures safe co-administration and
                prevents adverse effects. Automated prior authorization
                expedites processes, reducing administrative burden and ensuring
                timely medication access.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="picture1">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="impact py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12  ">
              <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled ps-4">
                <li>Schedule online classes</li>
                <li>Manage multiple classes</li>
                <li>View student profile</li>
                <li>Pay student fees</li>
                <li>Mark attendance</li>
                <li>Upload lessons</li>
                <li>Schedule assessments online</li>
                <li>Evaluate assessments</li>
                <li>Reward Marks</li>
                <li>View progress card</li>
                <li>
                  Responsive Chat interface (Parents, Teachers & Student chat)
                </li>
                <li>Share Notification in advance</li>
                <li>Private & Secured</li>
                <li>Zero Maintenance</li>
              </ul>
            </div>

            <div className="col-lg-3 col-sm-12 pb-3 pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5">
              <div className="impact-img4 d-flex flex-wrap align-items-center">
                <div className="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12  pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p className="pt-1 pt-lg-4 p-4 p-lg-0">
                Practica a practical solution for simple learning has led to
                Integrated and connected many school management that not only
                connect and serve every educator and student user needs but also
                seamlessly changed the Offline and Online learning worlds.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-cases pb-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center py-4 g-4">
            <div className="col-lg-4 col-sm-12">
              <Link to="/bussopi">
                <div className="case rounded-3 h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Bussopi</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    Efficiently organize your closet within the app.{" "}
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12 ">
              <Link to="/genie">
                <div className="case rounded-3 h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Genie</h4>
                  </div>
                  <p class=" pt-2">School app for learning</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/jugl">
                <div className="case rounded-3 h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Jugl</h4>
                  </div>
                  <p class=" pt-2">Workforce communication made simple</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Practica;
