import React, { useEffect } from 'react';

import { Helmet } from "react-helmet";
import LogoDark from "../../app/assets/images/logo-dark.png";

const ProductsPage = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = 'Innovative IT Solutions: Boost Your Business with Our Expertise|CAP Digisoft'; // Reset title if necessary
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <Helmet>
      <title>
        Innovative IT Solutions: Boost Your Business with Our Expertise|CAP
        Digisoft
      </title>
      <meta
        name="description"
        content="Unlock growth potentials with our cutting-edge IT solutions. Tailored strategies to elevate your business performance and stay ahead in the competitive tech landscape."
      />
      <meta name="author" content="https://capdigisoft.com" />
      <meta name="robots" content="index, follow" />

      <link
        rel="alternate"
        hreflang="x-default"
        href="https://capdigisoft.com/ourcompany"
      />

      <link rel="canonical" href="https://capdigisoft.com" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Innovative IT Solutions:Boost Your Business with Our Expertise|CAP Digisoft"
      />
      <meta
        property="og:description"
        content="Unlock growth potentials with our cutting-edge IT solutions. Tailored strategies to elevate your business performance and stay ahead in the competitive tech landscape."
      />
      <meta property="og:url" content="https://capdigisoft.com/" />
      <meta property="og:site_name" content="CAP Digisoft" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/capdigisoftsolutions/"
      />
      <meta
        property="article:modified_time"
        content="2021-08-23T14:13:42+00:00"
      />
      <meta
        property="og:image"
        content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Leading IT Services Provider: Elevate Your Business with Our Expert Solutions|CAP Digisoft"
      />
      <meta
        name="twitter:description"
        content="Empower your business with our top-notch IT services.we've got you covered. Drive success today!"
      />
      <meta
        name="twitter:image"
        content="https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png"
      />
      <meta name="twitter:site" content="@CAPDigisoft" />

      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "capdigisoft",
          "url": "https://capdigisoft.com/",
          "logo": "https://capdigisoft.com/static/media/logo-light.7ce76383d4689c9800e7.png",
          "sameAs": [
            "https://www.facebook.com/capdigisoftsolutions/",
            "https://x.com/CAPDigisoft",
            "@capdigisoftsolutions",
            "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw",
            "https://www.linkedin.com/company/cap-digisoft-solutions-inc"
          ]
        }`}
      </script>

      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Capdigisoft",
              "item": "https://capdigisoft.com"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "ourcompany",
              "item": "https://capdigisoft.com/ourcompany/"
            }
          ]
        }`}
      </script>
    </Helmet>
  );
};

export default ProductsPage;
