
import React from "react";
import { Slide } from "react-slideshow-image";


// brand logo imports
import c1 from "../app/assets/images/brand-logo/c1.jpg";
import c2 from "../app/assets/images/brand-logo/c2.jpg";
import c3 from "../app/assets/images/brand-logo/c3.jpg";
import c4 from "../app/assets/images/brand-logo/c4.jpg";
import c5 from "../app/assets/images/brand-logo/c5.jpg";
import c6 from "../app/assets/images/brand-logo/c6.jpg";
import c7 from "../app/assets/images/brand-logo/c7.jpg";
import c8 from "../app/assets/images/brand-logo/c8.jpg";
import c9 from "../app/assets/images/brand-logo/c9.jpg";
import c10 from "../app/assets/images/brand-logo/c10.jpg";
import c11 from "../app/assets/images/brand-logo/c11.jpg";
import c12 from "../app/assets/images/brand-logo/c12.jpg";
import c13 from "../app/assets/images/brand-logo/c13.jpg";
import c14 from "../app/assets/images/brand-logo/c14.jpg";
import c15 from "../app/assets/images/brand-logo/c15.jpg";
import c16 from "../app/assets/images/brand-logo/c16.jpg";
import c17 from "../app/assets/images/brand-logo/c17.jpg";
import c19 from "../app/assets/images/brand-logo/c19.jpg";
import c20 from "../app/assets/images/brand-logo/c20.jpg";
import c21 from "../app/assets/images/brand-logo/c21.png";
import c22 from "../app/assets/images/brand-logo/c22.png";
import c23 from "../app/assets/images/brand-logo/c23.png";
import c24 from "../app/assets/images/brand-logo/c24.png";
import c25 from "../app/assets/images/brand-logo/c25.png";
import c26 from "../app/assets/images/brand-logo/c26.png";
import c27 from "../app/assets/images/brand-logo/c27.png";
import c28 from "../app/assets/images/brand-logo/c28.png";
import c29 from "../app/assets/images/brand-logo/c29.png";
import c30 from "../app/assets/images/brand-logo/c30.png";
import c31 from "../app/assets/images/brand-logo/c31.png";
//brand logo end


const BrandLogoSlide = () =>{
  const properties = {
    prevArrow: (
      <button className="cs-btn btn-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button className="cs-btn btn-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
          />
        </svg>
      </button>
    ),
  };
  return (
    
    <Slide
    className="brand-slider"
    {...properties}
    slidesToScroll={1}
    slidesToShow={4}
    indicators={false}
    autoplay={true}
  >
    <div className="each-slide-effect">
      <img src={c1} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c2} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c3} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c4} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c5} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c6} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c7} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c8} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c9} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c10} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c11} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c12} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c13} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c14} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c15} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c16} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c17} alt="Logo" class="img-fluid mb-1" />
    </div>

    <div className="each-slide-effect">
      <img src={c19} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c20} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c21} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c22} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c23} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c24} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c25} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c26} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c27} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c28} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c29} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c30} alt="Logo" class="img-fluid mb-1" />
    </div>
    <div className="each-slide-effect">
      <img src={c31} alt="Logo" class="img-fluid mb-1" />
    </div>
  </Slide>
  );
};

export default BrandLogoSlide;
