/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";

import cutout1 from "../../../../src/app/assets/images/kpo-media/cutout1.jpg";
import cutout2 from "../../../../src/app/assets/images/kpo-media/cutout2.jpg";

// brand logo imports

import c1 from "../../../app/assets/images/brand-logo/c1.jpg";
import c2 from "../../../app/assets/images/brand-logo/c2.jpg";
import c3 from "../../../app/assets/images/brand-logo/c3.jpg";
import c4 from "../../../app/assets/images/brand-logo/c4.jpg";
import c5 from "../../../app/assets/images/brand-logo/c5.jpg";
import c6 from "../../../app/assets/images/brand-logo/c6.jpg";
import c7 from "../../../app/assets/images/brand-logo/c7.jpg";
import c8 from "../../../app/assets/images/brand-logo/c8.jpg";
import c9 from "../../../app/assets/images/brand-logo/c9.jpg";
import c10 from "../../../app/assets/images/brand-logo/c10.jpg";
import c11 from "../../../app/assets/images/brand-logo/c11.jpg";
import c12 from "../../../app/assets/images/brand-logo/c12.jpg";
import c13 from "../../../app/assets/images/brand-logo/c13.jpg";
import c14 from "../../../app/assets/images/brand-logo/c14.jpg";
import c15 from "../../../app/assets/images/brand-logo/c15.jpg";
import c16 from "../../../app/assets/images/brand-logo/c16.jpg";
import c17 from "../../../app/assets/images/brand-logo/c17.jpg";
import c19 from "../../../app/assets/images/brand-logo/c19.jpg";
import c20 from "../../../app/assets/images/brand-logo/c20.jpg";
import c21 from "../../../app/assets/images/brand-logo/c21.png";
import c22 from "../../../app/assets/images/brand-logo/c22.png";
import c23 from "../../../app/assets/images/brand-logo/c23.png";
import c24 from "../../../app/assets/images/brand-logo/c24.png";
import c25 from "../../../app/assets/images/brand-logo/c25.png";
import c26 from "../../../app/assets/images/brand-logo/c26.png";
import c27 from "../../../app/assets/images/brand-logo/c27.png";
import c28 from "../../../app/assets/images/brand-logo/c28.png";
import c29 from "../../../app/assets/images/brand-logo/c29.png";
import c30 from "../../../app/assets/images/brand-logo/c30.png";
import c31 from "../../../app/assets/images/brand-logo/c31.png";

// icons import

import icon1 from "../../../app/assets/images/kpo-media/icon1.png";
import icon2 from "../../../app/assets/images/kpo-media/icon2.png";
import icon3 from "../../../app/assets/images/kpo-media/icon3.png";


import Meta from "../../Meta/Knowledge-process";

const KPO = () => {
	useEffect(() => {
	  document.title = 'Knowledge Process Outsourcing | CAP Digisoft ';
	}, []);
  const properties = {
    prevArrow: (
      <button className="cs-btn btn-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button className="cs-btn btn-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
          />
        </svg>
      </button>
    ),
  };

  return (
    <div className="kpo-page">
      <HeaderDark />
      <Meta/>
      <section class="d-flex align-items-center justify-content-center banner-sec kpo-sec bg-img margin-minus">
        <div className="container custom-cn-width position-relative z-index-1">
          <div className="row g-4">
            <div className="col-xxl-5 col-xl-6 col-lg-6 p-0 m-0 ms-xl-0 px-xl-0 col-md-7">
              <h2 class="pb-3 text-uppercas">
                Knowledge Process Outsourcing that Supports Your Business Goals
              </h2>
              <p class="text-dark">
                Our KPO team uses their advanced analytical and technical skills
                to help enterprises meet the demand of their customers for
                high-quality services, and helps to create efficiencies for
                company operations. We work closely with your team to create
                new, strategic value to those products and services, increase
                ROI, and save costs up to 32%.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row g-xl-0 g-4 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-8 order-lg-2">
              <div className="design-block sec-title b-r-6">
                <h2 class="mb-3">A full-suite of KPO services.</h2>
                <p>
                  Our KPO team employs the latest best practices and technology
                  and we have developed our own semi-automated tools and highly
                  effective, proprietary project progress reporting system. Our
                  expert team excels at taking complex business processes and
                  creating custom outsourced solutions that can solve even the
                  most complicated issues.
                </p>
                <a
                  href="javascript:;"
                  class="default-btn secondary-main solution_class_popu mt-lg-4 mt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                  form_title="Knowledge Process Outsourcing"
                >
                  Let's Talk
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 order-lg-1">
              <img
                src={cutout1}
                class="img-fluid b-r-6"
                alt="A full-suite of KPO services"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="bg-light text-center section-padding">
        <div className="container custom-cn-width">
          <div className="row g-5 justify-content-center">
            <div className="col-xxl-7 col-xl-8 col-lg-10 col-md-10 mb-4 mb-lg-5">
              <h1 class="fw-light">
                What are the benefits of Knowledge Process Outsourcing?
              </h1>
              <p class="mt-3">
                Our KPO team employs the latest best practices and technology
                and we have developed our own semi-automated tools and highly
                effective, proprietary project progress reporting system. Our
                expert team excels at taking complex business processes and
                creating custom outsourced solutions that can solve even the
                most complicated issues.
              </p>
              <h5 class="mt-3 fw-400">
                Outsourcing business back office, front office, and knowledge
                related processes can offer a diverse variety of benefits to an
                organization, including:
              </h5>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-6 col-xl-3">
              <div className="features-item h-100 justify-content-start">
                <div className="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <img src={icon1} class="img-fluid" alt="Cost savings" />
                </div>
                <h4>Cost savings</h4>
                <p class="mt-3">
                  Outsourced solutions can find new efficiencies and improve
                  productivity by using our decades of experience and the latest
                  technologies to reduce administrative costs and performance
                  bottlenecks. Companies can also reduce staffing and training
                  costs through KPO.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 sec-primary-ic d-flex align-items-center justify-content-center mx-auto rounded-pill">
                  <img src={icon2} class="img-fluid" alt="Cost savings" />
                </div>
                <h4>Focus on primary business functions</h4>
                <p class="mt-3">
                  Save valuable time by letting your team focus on their primary
                  functions. This can result in improved customer satisfaction
                  as well as higher employee morale.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
                  <img src={icon3} class="img-fluid" alt="Cost savings" />
                </div>
                <h4>Improved results in secondary areas</h4>
                <p class="mt-3">
                  Using CAP Digisoft’s KPO expert team to handle your secondary
                  functions, such as data entry, billing, data analytics, or
                  document services are all handled fare more efficiently –
                  delivering you cutting-edge results.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 d-flex align-items-center justify-content-center mx-auto sec-primary-ic rounded-pill">
                  <i class="fas fa-rocket text-white"></i>
                </div>
                <h4>Improved operational efficiency</h4>
                <p class="mt-3">
                  CAP Digisoft’s KPO specialists save you time, improve the
                  accuracy of your results, and increase overall productivity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="acc-light section-padding">
        <div className="container custom-cn-width">
          <div className="row g-xl-5 g-4 justify-content-center">
            <div className="col-lg-6 col-xl-5 col-md-11">
              <div className="mb-4 mb-lg-5 pe-lg-5 text-center text-lg-start">
                <h1 class="fw-light">
                  Expert technical knowledge to serve all your process needs.
                </h1>
                <h5 class="mt-3 fw-400">
                  We offer a full suite of services including:
                </h5>
              </div>
              <div className="accordion position-relative pt-0" id="design-acc">
                <div className="accordion-item">
                  <h2 class="accordion-header" id="h1">
                    <button
                      class="accordion-button pointer-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#d1"
                      aria-expanded="true"
                      aria-controls="d1"
                    >
                      <span class="num">1</span>
                      <span>Document Management Support</span>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header" id="h2">
                    <button
                      class="accordion-button collapsed pointer-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#d2"
                      aria-expanded="false"
                      aria-controls="d2"
                    >
                      <span class="num">2</span>
                      <span>Intelligent Data Extraction</span>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header" id="h3">
                    <button
                      class="accordion-button collapsed pointer-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#d3"
                      aria-expanded="false"
                      aria-controls="d3"
                    >
                      <span class="num">3</span>
                      <span>Legal Document Services</span>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header" id="h4">
                    <button
                      class="accordion-button collapsed pointer-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#d4"
                      aria-expanded="false"
                      aria-controls="d4"
                    >
                      <span class="num">4</span>
                      <span>Legal Medical Document Services</span>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header" id="h5">
                    <button
                      class="accordion-button collapsed pointer-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#d5"
                      aria-expanded="false"
                      aria-controls="d5"
                    >
                      <span class="num">5</span>
                      <span>Data Auditing Services</span>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header" id="h6">
                    <button
                      class="accordion-button collapsed pointer-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#d6"
                      aria-expanded="false"
                      aria-controls="d6"
                    >
                      <span class="num">6</span>
                      <span>E-Commerce Data Support</span>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header" id="h7">
                    <button
                      class="accordion-button collapsed pointer-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#d7"
                      aria-expanded="false"
                      aria-controls="d7"
                    >
                      <span class="num">7</span>
                      <span>Web Data Mining</span>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header" id="h8">
                    <button
                      class="accordion-button collapsed pointer-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#d8"
                      aria-expanded="false"
                      aria-controls="d8"
                    >
                      <span class="num">8</span>
                      <span>Back Office Support</span>
                    </button>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-9 text-center">
              <div className="about-border right zoom">
                <div className="overflow-hidden border-20">
                  <img src={cutout2} alt="" class="border-0" />
                </div>
                <div className="about-bg"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="inner-client-sec bg-light-blue section-padding">
        <div className="container custom-cn-width">
          <div className="row align-items-center g-4 justify-content-center">
            <div className="col-lg-10">
              <h1 class="fw-400">
                <i class="fa fa-quote-left me-2"></i>Just the first assessment
                Audit results were enough to judge the work methods of CAP
                DIGISOFT. Their expertise and focused approach on our voice and
                data process helped us plan strategies on marketing and sales
                activities. CAP DIGISOFT is a truly customer oriented
                organisation, my experience has been exceptionally good.
                <i class="fa fa-quote-right ms-2"></i>
              </h1>
              <h4 class="fw-400 mt-4 pt-lg-3">
                - Director of Sales, Logistics Firm
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container custom-cn-width">
          <div className="row g-xl-5 g-4 align-items-center justify-content-center text-center">
            <div className="col-xxl-7 col-xl-8 col-lg-9">
              <h1 class="fw-light mb-lg-4 mb-3">
                Discover the CAP Digisoft Difference
              </h1>
              <p class="px-xl-4">
                Our highly-skilled, cost-effective, KPO experts bring the
                highest quality process outsourcing results at affordable
                prices, allowing you to save money and be more competitive.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="p-0 text-center text-lg-start">
        <div className="container custom-cn-width">
          <div className="cta-block b-r-6">
            <div className="row g-xl-5 g-4 align-items-center justify-content-center">
              <div className="col-lg-9">
                <h2 class="fw-light text-white">
                  Custom knowledge process outsourcing services that can solve
                  even the most complex and non-standard business process
                  challenges.
                </h2>
              </div>
              <div className="col-lg-3 text-lg-end">
                <a
                  href="javascript:;"
                  class="default-btn solution_class_popu"
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                  form_title="Knowledge Process Outsourcing"
                >
                  Let's Talk
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="text-center client-block section-padding">
        <div className="container">
          <div className="row g-lg-5 g-4 justify-content-center">
            <div className="col-lg-12 mb-4 mb-lg-5">
              <h1 class="px-lg-4 ">
                Trusted by more than{" "}
                <span class="text-theme fw-bold text-grad">3000+</span> clients
                globally
              </h1>
            </div>
          </div>
          <Slide
            className="brand-slider"
            {...properties}
            slidesToScroll={1}
            slidesToShow={4}
            indicators={false}
            autoplay={true}
          >
            <div className="each-slide-effect mx-3">
              <img src={c1} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c2} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c3} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c4} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c5} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c6} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c7} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c8} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c9} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c10} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c11} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c12} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c13} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c14} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c15} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c16} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c17} alt="Logo" class="img-fluid mb-1" />
            </div>

            <div className="each-slide-effect mx-3">
              <img src={c19} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c20} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c21} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c22} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c23} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c24} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c25} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c26} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c27} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c28} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c29} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c30} alt="Logo" class="img-fluid mb-1" />
            </div>
            <div className="each-slide-effect mx-3">
              <img src={c31} alt="Logo" class="img-fluid mb-1" />
            </div>
          </Slide>
        </div>
      </section>
      <section class="other-sec pt-0 section-padding">
        <div className="container custom-cn-width">
          <div className="row g-5 text-center">
            <div className="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Solutions</h1>
            </div>
          </div>
          <div className="">
            <div className="row g-4 g-xl-5 text-center text-xl-start">
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Software</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Mobile App &amp; Software Development
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/mobile-software-development"
                       rel="noreferrer"
                    >
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">call center</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Nearshore Call Center Outsourcing
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/call-center"
                       rel="noreferrer"
                    >
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Product</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">Idea to Product</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/idea-booster"
                       rel="noreferrer"
                    >
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default KPO;
