/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderDark from "../../../../common/header/HeaderDark";
import FooterOne from "../../../../common/footer/FooterOne";

import Img1 from "../../../../../src/app/assets/images/cds-blog/blog-24.jpg";
import Aut2 from "../../../../../src/app/assets/images/cds-blog/blog-2-aut.jpg";

// social icons

import FB from "../../../../../src/app/assets/images/cds-blog/fb.png";
import TW from "../../../../../src/app/assets/images/cds-blog/tw.png";
import LI from "../../../../../src/app/assets/images/cds-blog/li.png";

//recent blogs

import Recentblogs from "./recent-blogs";
import NewsLetter from "../../Form/Newsletter";
import Youmayinterested from "./interested-blog";
import Sharebutton from "./sharebutton";

const Blog1 = () => {
  useEffect(() => {
    document.title =
      "Marketing Strategies for the Age of Everything Online | CAP Digisoft ";
  }, []);

  return (
    <div className="blog-detail_page">
      <HeaderDark />
      <div className="social-icons-float d-none">
        <Link to="">
          <p>Facebook</p>
          <img alt="" class="img-fluid rounded-3 " src={FB} />
        </Link>
        <Link to="">
          <p>Linkedin</p>
          <img alt="" class="img-fluid rounded-3 " src={LI} />
        </Link>
        <Link to="">
          <p>Twitter</p>
          <img alt="" class="img-fluid rounded-3 " src={TW} />
        </Link>
      </div>
      <div className="blog-detail-wrapper section-padding pt-lg-5">
        <div className="container  custom-cn-width">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details-left">
                <div className="blog-detail-heading">
                  <h6>Strategic Digital Marketing</h6>
                  <h2>Marketing Strategies for the Age of Everything Online</h2>
                </div>
                <hr></hr>
                <div className="blog-detail-media">
                  <img alt="" class="w-100 mb-4 mt-2" src={Img1} />
                </div>

                <div className="blog-contents">
                  <div className="sticky-sharebutton">
                    <Sharebutton />
                  </div>
                  <div className="blog-detail-elevation">
                    <p>
                      The digital age has transformed the marketing landscape.
                      Gone are the days of mass media campaigns and
                      one-size-fits-all approaches. Today’s consumers are
                      bombarded with messages, so cutting through the noise
                      requires strategic, targeted marketing.
                    </p>

                    <p>
                      At CAP Digisoft, we understand the ever-evolving digital
                      marketing world.{" "}
                      <span className="high-lighted">
                        We partner with our clients to develop innovative and
                        data-driven marketing strategies that reach the right
                        audience at the right time
                      </span>
                      .
                    </p>

                    <p>
                      So, how can you thrive in this dynamic environment? Let’s
                      explore some key marketing strategies for the digital age:
                    </p>

                    <h4>Content is King (and Queen):</h4>

                    <p>
                      In today’s world, high-quality,{" "}
                      <span className="high-lighted">
                        informative content is essential.
                      </span>{" "}
                      Create blog posts, infographics, videos, and social media
                      content that{" "}
                      <span className="high-lighted">
                        educates, entertains, and engages your target audience.
                      </span>
                    </p>

                    <h4>Embrace the Power of Social Media:</h4>

                    <p>
                      Social media platforms are powerful tools for connecting
                      with your audience and building brand awareness.{" "}
                      <span className="high-lighted">
                        Tailor your content to each platform and actively engage
                        with your followers
                      </span>{" "}
                      through comments, contests, and live streams.
                    </p>

                    <h4>Personalization is Key:</h4>

                    <p>
                      Gone are the days of generic marketing messages. Today’s
                      consumers crave personalized experiences.{" "}
                      <span className="high-lighted">
                        Leverage marketing automation tools
                      </span>{" "}
                      to segment your audience and deliver targeted content
                      based on their interests and needs.
                    </p>

                    <h4> SEO: The Search Engine Gateway:</h4>

                    <p>
                      Organic search is a powerful way to attract potential
                      customers.{" "}
                      <span className="high-lighted">
                        Optimize your website content for relevant keywords and
                        ensure technical SEO best practices are followed.
                      </span>{" "}
                      This increases your chances of ranking higher in search
                      results and driving organic traffic to your{" "}
                      <Link to="/comprehensive-website-design">website</Link>.
                    </p>

                    <h4> The Rise of Influencer Marketing:</h4>

                    <p>
                      Partnering with{" "}
                      <span className="high-lighted">
                        social media influencers
                      </span>{" "}
                      can be a great way to reach a wider audience and build
                      brand trust. Choose influencers aligned with your brand
                      values and target audience.
                    </p>

                    <h4> Metrics that Matter: Measure and Adapt</h4>

                    <p>
                      Marketing is no longer a guessing game.{" "}
                      <span className="high-lighted">
                        Use analytics tools to track your campaign performance
                      </span>{" "}
                      , identify what’s working, and continuously refine your
                      strategies for maximum impact.
                    </p>

                    <h4> Embrace the Power of Story telling:</h4>

                    <p>
                      People connect with stories.{" "}
                      <span className="high-lighted">
                        Craft compelling narratives that showcase your brand
                        values
                      </span>{" "}
                      and resonate with your audience on an emotional level.
                    </p>

                    <h4>CAP Digisoft: Embracing the Future of Work</h4>

                    <p>
                      In this digital age, having a strong online presence is
                      crucial for success. At <Link to="/">CAP Digisoft</Link>,
                      we offer a range of{" "}
                      <span className="high-lighted">
                        digital marketing services
                      </span>{" "}
                      to help you reach your target audience and achieve your
                      business goals.{" "}
                      <Link to="/contactus">Contact us today </Link>
                      to discuss how we can develop a customized digital
                      marketing strategy that drives results.
                    </p>
                  </div>
                </div>

                <div className="blog-detail-cta my-3 my-3">
                  <button
                    type="button"
                    class="btn cust-btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#meeting"
                  >
                    Get in touch today
                  </button>

                  <h4 className="text-dark fw-500 pb-3 fs-4 mt-3">Tags</h4>
                  <div className="blog-detail-tags mb-5">
                    <ul className="list-unstyled ps-0 mb-0">
                      <li>
                        <Link to="#">Cloud Computing</Link>
                      </li>
                      <li>
                        <Link to="#">Collaboration Tools</Link>
                      </li>
                      <li>
                        <Link to="#">Communication Platforms</Link>
                      </li>
                      <li>
                        <Link to="#">Digital Transformation</Link>
                      </li>
                      <li>
                        <Link to="#">Flexible Workspaces</Link>
                      </li>
                      <li>
                        <Link to="#">Internet Of Things (IoT)</Link>
                      </li>
                      <li>
                        <Link to="#">Office Evolution</Link>
                      </li>
                      <li>
                        <Link to="#">Productivity Software</Link>
                      </li>
                      <li>
                        <Link to="#">Remote Work</Link>
                      </li>
                      <li>
                        <Link to="#">Smart Office</Link>
                      </li>
                    </ul>
                  </div>

                  <hr></hr>

                  <div className="blog-detail-author d-md-flex pt-3 d-block text-md-start text-center">
                    <div>
                      <img alt="" class="rounded-circle  me-3" src={Aut2} />
                    </div>
                    <div>
                      <h4 className="text-dark fw-500 pb-3 fs-4">
                        About Barath Kumar
                      </h4>
                      <h6 className="pb-5">
                        IT Entrepreneur with an experience of more than 20 years
                        in Enterprise Software and IT & IT Enabled Services.
                        Barath holds a Masters in Computer Application with a
                        Graduation in Applied Sciences and Computer Technology.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="blog-details-right">
                <Recentblogs />
              </div>
              <NewsLetter />
            </div>
          </div>
          <hr></hr>
          <Youmayinterested />
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Blog1;
