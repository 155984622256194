import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/reeler/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/reeler/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/reeler/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/reeler/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/flutter.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/dart.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/firebase.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/genie.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/jugl.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/practica.png";

const Reeler = () => {
  useEffect(() => {
    document.title = "Reeler | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-reeler-page">
      <HeaderLight />
      <div className="container-fluid pt-0 px-0 margin-minus">
        <div className="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Reeler</h2>
          <p class="text-white text-center pb-4 pt-3 px-4">
            Performance Tracker & Store Optimizer
          </p>
          <div className="row justify-content-center">
            <div className="img text-center">
              <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
          </div>
        </div>
      </div>
      <div className="about py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-xl-3 col-md-4 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is reeler?</p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12">
              <p className="p-4 p-lg-0">
                Reeler is the next generation Hook App to catch up with friends
                and hook them for a meet at a desired location. It is the best
                place to catch up your friends who are new to town or long-time
                locals with the simplest of UI to track their way and help each
                other meet.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="picture">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                There are a lot of free Apps to meet and spark new friendships
                but there isn’t a best way to stay connected and throw hooks to
                catch up with the good old friends and plan a meet in regular
                intervals at your favourite spot. Other Apps have a lot of
                distractions as they want users to try limitless options and
                lose the track of what they are looking for in that App.
              </p>
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                We came up with Reeler, simple yet powerful way to catch up with
                your online and lock their time to spend with your team. We
                enabled a smart and dynamic geo tag to find out your friends so
                that you can throw hooks to make sure they join you for the
                meetup.Best platform for friends to Plan a place, connect, track
                and meetup.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="picture1">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="impact py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 py-3">
              <p class="mb-lg-2 mb-4">
                Perfect meetup plan starts here. Explore the perfect App with
                Reeler that gives you access to
              </p>
              <ul class="mt-top list-styled ps-4">
                <li>Create Profiles</li>
                <li>Find friends</li>
                <li>Find Meetup Destination</li>
                <li>Create Meetup plan</li>
                <li>Throw meetup hook invites</li>
                <li>Accept/ Reject Hook</li>
                <li>Share notification</li>
                <li>Dynamic Map Viewer</li>
                <li>Track and follow friends to destination</li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-12 pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-0 pt-lg-5">
              <div className="impact-img4 d-flex flex-wrap align-items-center">
                <div className="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12  pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p className="pt-1 pt-lg-4 p-4 p-lg-0">
                We heard you! Now there is an App exclusive to plan meetups. Now
                people can stop saying “there is no time to catch up with
                friends in Real-time.”
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-cases pb-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center py-4 g-4">
            <div className="col-lg-4 col-sm-12">
              <Link to="/genie">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Genie</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    The Genie app serves as a transformative launcher{" "}
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12 ">
              <Link to="/jugl">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Jugl</h4>
                  </div>
                  <p class=" pt-2">Workforce communication made simple</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/practica">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Practica</h4>
                  </div>
                  <p class=" pt-2">School app for learning</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Reeler;
