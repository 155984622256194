import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import emaillogo from "../../../app/assets/images/icon/newsletter.png";
import { Link } from "react-router-dom";
import { IoLockClosed } from "react-icons/io5";

const Newsletter = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleNameInput = (e) => {
    if (/[0-9]/.test(e.key)) {
      e.preventDefault(); // Prevent numeric characters
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      pageLink: window.location.href,
    },
    validationSchema: Yup.object({
      name: Yup.string()
      .test('no-first-space', 'First letter should not be a space', value => value && !/^ /.test(value))
      .required("Please enter your name")   
      .test('only-one-space-between', 'Only one space allowed', value => value && !/ {2,}/.test(value))
      .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
      .min(3, "Name must be at least 3 characters long")
      .max(50, "Name should not exceed 50 characters long"),

      email: Yup.string().email("Please enter a valid email address")
      .test('no-com.co', 'Please enter a valid email address', value => !/\.com\.co$/.test(value))
      .test('no-single-letter-after-com', 'Please enter a valid email address', value => !/\.com\.[a-zA-Z]$/.test(value))
      .test('no-spaces', 'Please enter a valid email address', value => !/\s/.test(value))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Please enter a valid email address'
      )
      .test('only-one-dot-com', 'Please enter a valid email address', value => (value.match(/\.com/g) || []).length <= 1)
      .test('no-repeated-domain', 'Please enter a valid email address', value => {
        const domainExtensions = ['.com', '.net', '.in', '.co'];
        const matches = value.match(/\.\w+/g);
        if (matches) {
          const uniqueExtensions = new Set(matches.map(match => match.toLowerCase()));
          return uniqueExtensions.size === matches.length;
        }
        return true;
      })
      .test('laxEmail_new', 'Please enter a valid email address.', function(value) {
        if (value) {
          if (value.indexOf("@") > 0 && value.indexOf("@") === value.lastIndexOf("@")) {
            const [localPart, domain] = value.split("@");
            const firstChar = localPart[0];
            const lastChar = localPart.charAt(localPart.length - 1);
            const afterAt = domain[0];
    
            if (firstChar === '.' || lastChar === '.' || (/^[A-Za-z]$/.test(afterAt) === false)) {
              return false;
            }
    
            const domainParts = domain.split(".");
            if (domainParts.length < 2) {
              return false;
            }
    
            const topLevelDomain = domainParts.pop();
            if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
              return false;
            }
    
            return domainParts.every(part => part.length > 1);
          } else {
            return false;
          }
        }
        return true;
      })
      .required("Please enter your email address"),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        setIsSubmitting(true);

        if (window.grecaptcha) {
          window.grecaptcha.ready(async () => {
            try {
              const token = await window.grecaptcha.execute(
                "6LdRfrYpAAAAAM6i4yl1LJWvbrvxcnIhzqn1XWmB",
                { action: "submit" }
              );

              const response = await fetch("backend/submitsubscribers.php", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "g-recaptcha-response": token,
                },
                body: JSON.stringify({
                  mode: "subscriptionData",
                  sub_name: values.name,
                  sub_email: values.email.toLowerCase(),
                  page_url: values.pageLink,
                }),
              });

              const responseData = await response.json();

              if (responseData === 1) {
                setIsSubscribed(true);
              } else if (responseData === 0) {
                alert(responseData.error || "Error submitting data");
              }
            } catch (error) {
              console.error("Error:", error);
              setErrors({ submit: "Error submitting form. Please try again." });
            } finally {
              setIsSubmitting(false);
              setSubmitting(false);
            }
          });
        } else {
          setIsSubmitting(false);
          setSubmitting(false);
        }
      } catch (error) {
        setIsSubmitting(false);
        setSubmitting(false);
        console.error("Unexpected error:", error);
        setErrors({ submit: "Unexpected error. Please try again." });
      }
    },
  });

  return (
    <div className="px-0 bg-light subscribe-container">
      <div
        className="subscribe-box rounded w-100"
        style={{ padding: "2rem" }}
      >
        {isSubscribed ? (
          <div className="thank-you-message text-center">
            <h5 className="fs-5 mb-2 fw-bold">Thank you for subscribing!</h5>
            <p style={{ fontSize: "14px" }}>
              You'll now receive our newsletter. Feel free to explore more of
              our content.
            </p>
            <Link
              to="/blog"
              className="btn subcribes-submit text-white mt-3 px-4 py-1"
            >
              Discover more
            </Link>
          </div>
        ) : (
          <>
            <div className="text-box w-100 bg-light">
              <h4 className="fw-bold mb-2">
                {" "}
                <span>
                  {" "}
                  <img
                    src={emaillogo}
                    className="mb-1"
                    width={25}
                    alt="logo not found"
                  />{" "}
                </span>
                Subscribe for Our Newsletter
              </h4>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="subscribe-form d-flex align-items-center flex-column gap-4"
            >
              <div className="col-5 w-100">
                <label htmlFor="name" className="form-label text-dark">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control ps-3 "
                  id="subscribe-input"
                  placeholder="Enter your name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleNameInput}
                />
                {formik.touched.name && formik.errors.name ? (
                  <small className="error-msg text-danger">
                    {formik.errors.name}
                  </small>
                ) : null}
              </div>
              <div className="col-6 w-100">
                <label htmlFor="email" className="form-label text-dark">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  className="form-control ps-3 border "
                  id="subscribe-input"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleKeyPress}
                />
                {formik.touched.email && formik.errors.email ? (
                  <small className="error-msg text-danger">
                    {formik.errors.email}
                  </small>
                ) : null}
                <div className="form-check mt-2 ps-0 pt-2">
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    <small className="agree-text">
                      <IoLockClosed />
                      <span>
                        Your email is secure. We prioritize privacy and never
                        share your email without consent. See our
                        <Link to="/privacy-policy"> Privacy Notice.</Link>
                      </span>
                    </small>
                  </label>
                </div>
              </div>
              <div className="btn-grp w-100 d-flex flex-column align-items-center justify-content-center">
                <button
                  type="submit"
                  className="btn text-white subcribes-submit bg-white w-100 rounded-3"
                  id="subcribes-submit"
                  disabled={isSubscribed || isSubmitting}
                >
                  {isSubmitting
                    ? "Subscribing..."
                    : isSubscribed
                    ? "Subscribed"
                    : "Subscribe"}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
