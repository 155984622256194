import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/bread/banner.jpg";

import icon1 from "../../../../src/app/assets/images/casestudy/bread/icon1.png";
import icon2 from "../../../../src/app/assets/images/casestudy/bread/icon2.png";
import icon3 from "../../../../src/app/assets/images/casestudy/bread/icon3.png";

import PDF from "../../../app/assets/casestudypdf/panera-bread.pdf";

const Panera = () => {
  useEffect(() => {
    document.title = "Panera | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-logistics-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div className="container custom-cn-width position-relative">
          <div className="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div className="col-lg-9">
              <h5 class="text-white">Case Study</h5>
              <h1 class="text-white fw-semibold">Panera Bread</h1>
            </div>
            <div className="col-lg-3 text-lg-end">
              <a href={PDF} class="default-btn orange-btn" target="_blank" rel="noreferrer">
                <i class="fa fa-file-pdf me-1"></i>
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row justify-content-center g-lg-5 g-4">
            <div className="col-lg-7">
              <h1 class="fw-light mb-lg-4 mb-3">Overview</h1>
              <p class="">
                There are often blind spots in a business’s sales strategy,
                either from a lack of resources or lack of support and those
                blind spots can lead to thousands of lost sales per year. This
                is especially true when the Foodservice industry currently
                accounts for{" "}
                <span class="text-bold">51% of the family food dollar</span> -
                more than double what it was in 1955.
              </p>
              <p class="mt-3">
                In order to alleviate one of these blind spots, Panera Bread
                utilized <span class="text-bold">Flobile’s expertise</span> in
                building prospect databases, email campaign customization, and
                marketing services to create a new catering customer base where
                there was previously none.
              </p>
              <p class="mt-3">
                By doing this not only was Flobile able to demonstrate how much
                of the market was being missed but were also able to make it
                easier for those potential prospects to take advantage of the
                client’s services and provided the business with a{" "}
                <span class="text-bold">metric to measure success</span>.
              </p>
            </div>
            <div className="col-lg-5 col-md-9">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 text-center text-lg-start section-padding pt-0">
        <div className="container custom-cn-width">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-7 mb-4 mb-lg-5">
              <h1 class="fw-light">CDS Solution Approach</h1>
            </div>
          </div>
          <div className="row g-xl-5 g-4">
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons sec-primary-ic mb-4 mx-auto mx-lg-0  d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon1}
                    class="img-fluid"
                    alt="Prospect Database Cleansing"
                  />
                </div>
                <h4>Prospect Database</h4>
                <p class="mt-3">
                  Flobile’s team of data scientists were able to identify every
                  possible prospect within a 20-mile radius and qualified them
                  based on select criteria. Flobile also verified that all
                  contact data was accurate before adding them to the campaign.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="Prospect Database Governance"
                  />
                </div>
                <h4>Customer Catering Menu Design</h4>
                <p class="mt-3">
                  Flobile created a custom catering menu which made the ordering
                  process even easier for the new customers being contacted
                  through the prospect database.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center  sec-primary-ic rounded-pill">
                  <img
                    src={icon3}
                    class="img-fluid"
                    alt="Email Marketing Strategy"
                  />
                </div>
                <h4>Custom Email Marketing</h4>
                <p class="mt-3">
                  Flobile created a custom email nurture campaign to usher the
                  collected leads from prospect to customer, all with provable
                  metrics along the journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class=" text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row g-lg-0 g-4 justify-content-center">
            <div className="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div className="p-lg-5 p-4">
                <h1 class="fw-light text-white">Results</h1>
                <p class="mt-3 mb-3 text-white">
                  Panera experienced a{" "}
                  <span class="text-bold">2x increase</span>
                  in catering sales for the business throughout the 6-month
                  period Flobile was engaged with the client, and Flobile
                  provided them a roadmap for future catering success.
                </p>
                <div className="case-study-count row g-3 g-xl-0">
                  <div className="col-xl-12 col-md-6 text-center">
                    <h1 class="fw-light text-white">2x</h1>
                    <p class="text-white lh-sm">Increase in catering sales</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div className="p-lg-5 p-4">
                <h1 class="fw-light mb-3">Conclusion</h1>
                <h4 class="mt-3 ">Prospect Database Cleansing</h4>
                <p class="mt-3 ">
                  In order to effectively connect with the maximum number of
                  customers, companies need a partner with the experience,
                  ability, and resources to do the time-consuming task of
                  building an actionable, qualified prospect database. It’s also
                  crucial to have a partner that has the experience to take that
                  data and combine it with expertise in digital marketing to
                  drive those sales home. <span class="text-bold">Flobile</span>{" "}
                  combines all of these abilities and more to help companies
                  realize their{" "}
                  <span class="text-bold">full sales potential.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div className="container custom-cn-width">
          <div className="row g-5 align-items-center justify-content-center text-center">
            <div className="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div className="container custom-cn-width">
          <div className="row g-lg-5 g-4 text-center">
            <div className="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Case Studies</h1>
            </div>
          </div>
          <div className="">
            <div className="row g-4 g-xl-5 text-center text-xl-start">
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Workflow Management System
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/workflow-management-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Merger and Acquisition Firm
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/mergers-and-acquisition-firm">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Frontera Search Partners
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/frontera">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default Panera;
