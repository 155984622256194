import React, { Component } from "react";
import { Link } from "react-router-dom";

class CourseGrid01 extends Component {

render() {
return (
//Course style 1
<section className="course-wrapper section-padding pt-sm-0">
  <div className="container custom-cn-width">
    <div className="row justify-content-center">
      <div className="col-xl-8">
        <div className="section-heading text-center">
          <h2 className="font-lg fw-light mb-lg-5 mb-4">
            Our Solutions
          </h2>
        </div>
      </div>
    </div>

    <div className="gen-banner-wrapper">
      <div className="row">
        <div className="col-lg-5">
          <h4 class="mb-3 text-white font-size-24">Generative AI</h4>
          <p class="hidden-4 text-white font-size-17 mb-3">
            CAP Digisoft's Generative AI Framework, powered by
            state-of-the-art Large Language Models, makes adding AI to
            your products a more straightforward and secure way. It
            provides an understanding of your enterprise data, giving your
            application teams/users the tools to make decisions quickly
            and respond to human-like language queries.
          </p>
          <Link class="underline-border font-size-15 fw-500" to="/generative-ai">
            <span className="border-line me-1 pb-1 fw-400">Learn more</span>

            <i class="fa fa-arrow-right font-size-15 position-relative mt-1"></i>
          </Link>
        </div>
      </div>
    </div>

    <div className="row g-4 g-xl-5 text-center text-xl-start other-sec mt-4">
			
				<div className="col-lg-4 col-md-6">
					<fieldset>
						<legend class="text-xl-start">Product</legend>
						<div className="d-flex flex-column justify-content-between h-100">
							<h4 class="mb-3">Idea to Product</h4>
							<p class="hidden-4">When it comes to innovation, the secret to success comes down to timing. Wait too long and someone else can swoop in and capitalize on a similar idea. Unfortunately, entrepreneurs with great ideas often lack the resources or technical expertise to make their ideas a reality. There’s no reason you have to go it alone. CAP Digisoft Solutions is the perfect partner to walk you through and assist with what can be an overwhelming process.</p>
							<Link class="d-inline-block underline-border mt-3" to="/idea-booster"  rel="noreferrer"><span class="border-line d-inline-block me-1">Learn more</span><i class="fa fa-arrow-right fa-sm position-relative"></i></Link>
						</div>
					</fieldset>
				</div>
				<div className="col-lg-4 col-md-6">
					<fieldset class="transition">
						<legend class="text-xl-start">Service</legend>
						<div className="d-flex flex-column justify-content-between h-100">
							<h4 class="mb-3">Client Success Service</h4>
							<p class="hidden-4">In the dynamic world of startups and product-centric companies, the quest for robust Client Success mechanisms is more crucial than ever. It's a pursuit that can significantly influence both growth and loyalty. Recognizing this urgency, CAP Digisoft steps in to bridge the gap.</p>
							<Link class="d-inline-block underline-border mt-3" to="/client-success"><span class="border-line d-inline-block me-1">Learn more</span><i class="fa fa-arrow-right fa-sm position-relative"></i></Link>
						</div>
					</fieldset>
				</div>
				<div className="col-lg-4 col-md-6">
					<fieldset>
						<legend class="text-xl-start">Website Design</legend>
						<div className="d-flex flex-column justify-content-between h-100">
							<h4 class="mb-3">Comprehensive Website Design</h4>
							<p class="hidden-4">The role of a company website in the 21st century is no longer about claiming a stake on the internet highway or a mere branding exercise. Today’s websites need to be lean, effective conversion machines that make it easy for your customers and prospects to find the information they’re looking for and make decisive buying decisions.</p>
							<Link class="d-inline-block underline-border mt-3" to="/comprehensive-website-design"><span class="border-line d-inline-block me-1">Learn more</span><i class="fa fa-arrow-right fa-sm position-relative"></i></Link>
						</div>
					</fieldset>
				</div>
				<div className="col-lg-4 col-md-6">
					<fieldset>
						<legend class="text-xl-start">Software</legend>
						<div className="d-flex flex-column justify-content-between h-100">
							<h4 class="mb-3">Mobile App &amp; Software Development</h4>
							<p class="hidden-4">Some business challenges don’t have an “off-the shelf” software solution, or require a custom-built solution to tackle complex problems. This area of mobile app and software development is where CAP Digisoft excels. </p>
							<Link class="d-inline-block underline-border mt-3" to="/mobile-software-development"  rel="noreferrer"><span class="border-line d-inline-block me-1">Learn more</span><i class="fa fa-arrow-right fa-sm position-relative"></i></Link>
						</div>
					</fieldset>
				</div>
				<div className="col-lg-4 col-md-6">
					<fieldset>
						<legend class="text-xl-start">KPO</legend>
						<div className="d-flex flex-column justify-content-between h-100">
							<h4 class="mb-3">Knowledge Process Outsourcing</h4>
							<p class="hidden-4">Our KPO team uses their advanced analytical and technical skills to help enterprises meet the demand of their customers for high-quality services, and helps to create efficiencies for company operations. We work closely with your team to create new, strategic value to those products and services, increase ROI, and save costs up to 32%.</p>
							<Link class="d-inline-block underline-border mt-3" to="/knowledge-process-outsourcing"><span class="border-line d-inline-block me-1">Learn more</span><i class="fa fa-arrow-right fa-sm position-relative"></i></Link>
						</div>
					</fieldset>
				</div>
				<div className="col-lg-4 col-md-6">
					<fieldset>
						<legend class="text-xl-start">Call Center</legend>
						<div className="d-flex flex-column justify-content-between h-100">
							<h4 class="mb-3">Nearshore Call Center Outsourcing</h4>
							<p class="hidden-4">CAP Digisoft’s Nearshore Call Center outsourcing is an increasingly popular alternative for businesses looking to benefit from the advantages of contracting outsourced call center services without the potential pitfalls that are associated with offshore and farshore teams. We can increase your conversion results and your ROI with all the benefits of working with a US-based partner</p>
							<Link class="d-inline-block underline-border mt-3" to="/call-center"  rel="noreferrer"><span class="border-line d-inline-block me-1">Learn more</span><i class="fa fa-arrow-right fa-sm position-relative"></i></Link>
						</div>
					</fieldset>
				</div>				
			</div>
  </div>
</section>
);
}
}

export default CourseGrid01;