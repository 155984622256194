import React, { useEffect } from "react";
import "../../../../app/assets/css/idea-booster.css";
import FooterOne from "../../../../common/footer/FooterOne";

import Hero from "./sub-components/hero";
import Inovative from "./sub-components/inovative";
import Unlocking from "./sub-components/unlocking";
import Guidance from "./sub-components/guidance";
import Victory from "./sub-components/victory";
import Journey from "./sub-components/journey";
import OurInovations from "./sub-components/ourInovations";
import Leverage from "./sub-components/leverage";
import AppJourney from "./sub-components/appJourney";
import TurnDream from "./sub-components/turnDream";
import Navigating from "./sub-components/navigating";
import Testimonial from "./sub-components/testimonial";
import Apps from "./sub-components/apps";
import Confidently from "./sub-components/confidently";

const IdeaBooster = () => {
  useEffect(() => {
    document.title = "Idea Booster Services: Ignite Your Creativity Today+";
  }, []);

  return (
    <>
      <div className="idea-booster-page">
        <Hero />
        <Inovative />
        <Unlocking />
        <Guidance />
        <Victory />
        <Journey />
        <OurInovations />
        <Leverage />
        <AppJourney />
        <Confidently />
        <Testimonial />
        <Navigating />
        <TurnDream />
        <Apps />
        <FooterOne />
      </div>
    </>
  );
};

export default IdeaBooster;
