import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


export default function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  //for handle validation for both mobile and web application
  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
    e.target.value = filteredValue;
  };
  const handleKeyPress = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  const [countryCode, setCountryCode] = useState("");
    useEffect(() => {
    // Fetch the user's country based on their IP
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        // ipapi returns the country code in ISO Alpha-2 format, which react-phone-input-2 expects
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
      })
      .catch((error) => {
        setCountryCode("in")
        console.error("Error fetching IP information:", error);
        // Optionally set a default or handle the error
      });
  }, []);

  //email key down
  // Assuming you have an input field with id "email"
 
  
   const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .test('no-first-space', 'First letter should not be a space', value => value && !/^ /.test(value))
      .required("Please enter your name")   
      .test('only-one-space-between', 'Only one space allowed', value => value && !/ {2,}/.test(value))
      .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
      .min(3, "Name must be at least 3 characters long")
      .max(50, "Name should not exceed 50 characters long"),

      email: Yup.string().email("Please enter a valid email address")
      .test('no-com.co', 'Please enter a valid email address', value => !/\.com\.co$/.test(value))
      .test('no-single-letter-after-com', 'Please enter a valid email address', value => !/\.com\.[a-zA-Z]$/.test(value))
      .test('no-spaces', 'Please enter a valid email address', value => !/\s/.test(value))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Please enter a valid email address'
      )
      .test('only-one-dot-com', 'Please enter a valid email address', value => (value.match(/\.com/g) || []).length <= 1)
      .test('no-repeated-domain', 'Please enter a valid email address', value => {
        const domainExtensions = ['.com', '.net', '.in', '.co'];
        const matches = value.match(/\.\w+/g);
        if (matches) {
          const uniqueExtensions = new Set(matches.map(match => match.toLowerCase()));
          return uniqueExtensions.size === matches.length;
        }
        return true;
      })
      .test('laxEmail_new', 'Please enter a valid email address.', function(value) {
        if (value) {
          if (value.indexOf("@") > 0 && value.indexOf("@") === value.lastIndexOf("@")) {
            const [localPart, domain] = value.split("@");
            const firstChar = localPart[0];
            const lastChar = localPart.charAt(localPart.length - 1);
            const afterAt = domain[0];
    
            if (firstChar === '.' || lastChar === '.' || (/^[A-Za-z]$/.test(afterAt) === false)) {
              return false;
            }
    
            const domainParts = domain.split(".");
            if (domainParts.length < 2) {
              return false;
            }
    
            const topLevelDomain = domainParts.pop();
            if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
              return false;
            }
    
            return domainParts.every(part => part.length > 1);
          } else {
            return false;
          }
        }
        return true;
      })
      .required("Please enter your email address"),
     
    phone: Yup.string().required("Please enter phone number")
    .min(9,"Please enter valid phone number"), 
    company: Yup.string()
      .test('no-first-space', 'First letter should not be a space', value => value && !/^ /.test(value))
      .test('only-one-space-between', 'Only one space allowed between words', value => value && !/ {2,}/.test(value))
      .min(3, "Company name must be at least 3 characters")
      .max(50, "Company Name should not exceed 50 characters long")
      .required("Please enter Company name"),
    message: Yup.string()
      .test('no-first-space', 'First letter should not be a space', value => value && !/^ /.test(value))
      .test('only-one-space-between', 'Only one space allowed between words', value => value && !/ {2,}/.test(value))
      .min(3, "Your requirement must be at least 3 characters")
      .max(500, "Your requirements should not exceed 500 characters")
      .required("Please enter your requirement"),
  });
  
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      pageLink: window.location.href,
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      if (window.grecaptcha) {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            "6LdRfrYpAAAAAM6i4yl1LJWvbrvxcnIhzqn1XWmB",
            { action: "submit" }
          );
          // Now you have a token, submit it to your backend along with form values
          const response = await axios.post(
            "backend/submitForm.php",
            {
              mode: "addCenterModuleData_new",
              ...values,
              "g-recaptcha-response": token,
            }
          );

          const responseData = response.data; 
          if (responseData === 1) {
            formik.resetForm();
            // Handle successful submission
            const backdrop = document.querySelector(".modal-backdrop");
            if (backdrop) {
              //alert('hi');
              backdrop.remove();
            }
            navigate("/thankyou");
          } else if((responseData === 0) ){
            // Handle errors or unsuccessful submission
            console.error("Error submitting form data");
          }
          setIsSubmitting(false);
        });
      }
    },
  });

  return (
    <div className="section" id="contact">
      <div className="ct-wrapper rounded-lg overflow-hidden">
        <div className="card-body">
          <div className="">
            <form onSubmit={formik.handleSubmit}>
              {/* Name field */}
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Full Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder="Full Name"
                  onInput={handleNameInput}
                />
                {formik.touched.name && formik.errors.name ? (
                 <div className="error-msg text-danger">{formik.errors.name}</div>
                ) : null}
              </div>

              {/* Email field */}
              <div className="mb-3" >
                <label htmlFor="email" className="form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onKeyDown={handleKeyPress}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Email"
                />
                {formik.touched.email && formik.errors.email ? (
                 <div className="error-msg text-danger ">{formik.errors.email}</div>
                ) : null}
              </div>

              {/* Phone field */}
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone Number <span className="text-danger">*</span>
                </label>
                <PhoneInput
                  country={countryCode}
                  placeholder="Phone"
                  onChange={(phone) => formik.setFieldValue("phone", phone)}
                  value={formik.values.phone}
                  inputStyle={{
                    width: "100%",
                    height: "38px",
                  }}
                />
                {formik.touched.phone && formik.errors.phone ? (
                 <div className="error-msg text-danger">{formik.errors.phone}</div>
                ) : null}
              </div>

              {/* Company field */}
              <div className="mb-3">
                <label htmlFor="company" className="form-label">
                  Company Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  name="company"
                  placeholder="Company Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                />
                {formik.touched.company && formik.errors.company ? (
                 <div className="error-msg text-danger">{formik.errors.company}</div>
                ) : null}
              </div>

              {/* Message field */}
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Your Requirement <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Your Requirement"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                ></textarea>
                {formik.touched.message && formik.errors.message ? (
                 <div className="error-msg text-danger">{formik.errors.message}</div>
                ) : null}
              </div>

              <button
                type="submit"
                className="btn cust-btn-primary secondary-main mt-3"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
