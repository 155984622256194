import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/logistics/banner.jpg";

import icon1 from "../../../../src/app/assets/images/casestudy/logistics/icon1.png";
import icon2 from "../../../../src/app/assets/images/casestudy/logistics/icon2.png";
import icon3 from "../../../../src/app/assets/images/casestudy/logistics/icon3.png";

import PDF from "../../../app/assets/casestudypdf/logistics-company.pdf";

const Logistics = () => {
  useEffect(() => {
    document.title = "Logistics Company | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-logistics-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div className="container custom-cn-width position-relative">
          <div className="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div className="col-lg-9">
              <h5 class="text-white">Case Study</h5>
              <h1 class="text-white fw-semibold">Logistics Company</h1>
            </div>
            <div className="col-lg-3 text-lg-end">
              <a href={PDF} class="default-btn orange-btn" target="_blank" rel="noreferrer">
                <i class="fa fa-file-pdf me-1"></i>
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row justify-content-center g-lg-5 g-4">
            <div className="col-lg-7">
              <h1 class="fw-light mb-lg-4 mb-3">Overview</h1>
              <p>
                Over time as technology has evolved around the logistics
                industry, business models have sometimes remained stagnant
                resulting in sales goals that don’t meet expectations. Business
                owners in the industry say that their current compliance and
                lead generation models require a lot of nurturing for successful
                sales enablement, but that it’s imperative to keep pace with the
                market.
              </p>
              <p class="mt-3">
                When partnering with a new company Flobile works together with
                them to design a path to success, finding where the gaps are in
                that path and filling in those missing areas. Over{" "}
                <span class="text-bold">10.5 billion tons</span> of freight is
                transferred annually in the US, using over{" "}
                <span class="text-bold">3.6 million heavy-duty trucks</span>{" "}
                operated by{" "}
                <span class="text-bold">3.5 million commercial drivers</span>.
                Though our client firm is one of the largest logistics companies
                nationally, operating in over 3,000 locations, they had trouble
                connecting with these potential prospects because they were
                working with outdated and inaccurate data.
              </p>
              <p class="mt-3">
                Through repeated assessment and analysis of their current
                strategy, content and prospect data Flobile identified the
                missing links and connected the dots using their expertise and
                resources to create a custom path to success. Flobile verified,
                validated and cleansed their prospect data and implemented a
                data governance solution to make sure that data was kept
                up-to-date with continuous verification.
              </p>
            </div>
            <div className="col-lg-5 col-md-9">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 text-center text-lg-start section-padding pt-0">
        <div className="container custom-cn-width">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-7 mb-4 mb-lg-5">
              <h1 class="fw-light">CDS Solution Approach</h1>
            </div>
          </div>
          <div className="row g-xl-5 g-4">
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons sec-primary-ic mb-4 mx-auto mx-lg-0  d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon1}
                    class="img-fluid"
                    alt="Prospect Database Cleansing"
                  />
                </div>
                <h4>Prospect Database Cleansing</h4>
                <p class="mt-3">
                  Prospect Database Cleansing Flobile’s team of data scientists
                  verified and validated existing contacts with shops around the
                  southern region and qualified them based on select criteria.
                  Flobile also verified that all contact data was accurate
                  before adding them to the CRM.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="Prospect Database Governance"
                  />
                </div>
                <h4>New Prospect Identification</h4>
                <p class="mt-3">
                  Flobile worked with our client firm to create a profile of
                  their customers and then used that data as the basis to find
                  new prospects for their sales teams..
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center  sec-primary-ic rounded-pill">
                  <img
                    src={icon3}
                    class="img-fluid"
                    alt="Email Marketing Strategy"
                  />
                </div>
                <h4>Custom Dashboard</h4>
                <p class="mt-3">
                  Flobile created a custom dashboard for the inside sales team
                  to view complete prospect data with insights on existing and
                  new prospect listings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class=" text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row g-lg-0 g-4 justify-content-center">
            <div className="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div className="p-lg-5 p-4">
                <h1 class="fw-light text-white">Results</h1>
                <p class="mt-3 text-white mb-3">
                  By providing data enablement on truck information for existing
                  and new leads, Flobile was able to create a roadmap for
                  continued growth and success. Flobile’s partnership resulted
                  in:
                </p>
                <div className="case-study-count row g-3 g-xl-0">
                  <div className="col-xl-4 col-md-6 text-center">
                    <h1 class="fw-light text-white">2x</h1>
                    <p class="text-white lh-sm">data enhancement</p>
                  </div>
                  <div className="col-xl-4 col-md-6 text-center">
                    <h1 class="fw-light text-white">100</h1>
                    <p class="text-white lh-sm">
                      hrs/month reduction in time spent on calls by the inside
                      sales team
                    </p>
                  </div>
                  <div className="col-xl-4 col-md-6 text-center">
                    <h1 class="fw-light text-white">200%</h1>
                    <p class="text-white lh-sm">increase in engagement rate</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div className="p-lg-5 p-4">
                <h1 class="fw-light  mb-3">Conclusion</h1>
                <h4 class="mt-3 ">Prospect Database Cleansing</h4>
                <p class="mt-3 ">
                  For effective and profitable growth, companies need to find a
                  partner with expertise in handling quantitative and
                  qualitative actionable tasks, and the resources to complete
                  them within an aggressive timeframe. That relationship can be
                  even more valuable when that partner has the experience to
                  take data and combine it with a{" "}
                  <span class="text-bold">proven track record</span> of success
                  in digital marketing to drive those sales home.{" "}
                  <span class="text-bold">Flobile</span> combines all of these
                  abilities and more and is able to effectively incorporate them
                  into current sales processes. Our client firm approached the
                  process with an open mind and welcomed suggestions, and{" "}
                  <span class="text-bold">Flobile</span> was able to provide
                  them with positive sales results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div className="container custom-cn-width">
          <div className="row g-5 align-items-center justify-content-center text-center">
            <div className="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div className="container custom-cn-width">
          <div className="row g-lg-5 g-4 text-center">
            <div className="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Case Studies</h1>
            </div>
          </div>
          <div className="">
            <div className="row g-4 g-xl-5 text-center text-xl-start">
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Workflow Management System
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/workflow-management-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Merger and Acquisition Firm
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/mergers-and-acquisition-firm">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Frontera Search Partners
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/frontera">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default Logistics;
