import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/attesta/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/attesta/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/attesta/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/attesta/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/jquery.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/python.png";
import tech8 from "../../../../src/app/assets/images/casestudy/tech/t-react.png";
import tech9 from "../../../../src/app/assets/images/casestudy/tech/xampp.png";
import tech10 from "../../../../src/app/assets/images/casestudy/tech/jquery.png";
import tech11 from "../../../../src/app/assets/images/casestudy/tech/pe.png";
import tech12 from "../../../../src/app/assets/images/casestudy/tech/json.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/tracky.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/sunbelt.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/schooler.png";

const Attesta = () => {
  useEffect(() => {
    document.title = "AttestaAI | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-attesta-page">
      <HeaderLight />
      <div className="container-fluid pt-0 px-0 margin-minus">
        <div className="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">AttestaAI</h2>
          <p class="text-white text-center pb-4 pt-3 px-4">
            Amazing AI-based document discovery to Reduce your review time by
            70%
          </p>
          <div className="row justify-content-center">
            <div className="img text-center">
              <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
          </div>
        </div>
      </div>
      <div className="about py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-xl-3 col-md-4 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is AttestaAI?</p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12">
              <p className="p-4 p-lg-0">
                AttestaAI is a one stop platform for users to experience
                next-level automated summaries with optimized report processing
                and character recognition via an Artificial Intelligence-powered
                engine. Your scanned pages now have information and field
                reports that is categorised based on requirements, all it
                requires is to allow the machine to learn and automate the
                process for the first time.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="picture">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="picture-img text-center">
                <img class="tracky-mockup img-fluid" src={cutout1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                One of our big law firm client in USA was using a manual method
                to scan and add the list of cases to analyze a case based on
                summaries, records and incidents with scenarios date wise. It
                was a time-consuming process as it was over 10000 pages and
                plotting keywords and subject metrics was difficult which was
                taking days to get the job done.
              </p>
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4 pt-2">
                We developed a system named Attesta to automate business records
                using an AI engine, and derive information and knowledge from
                those records. The system can take any document format - from
                quality assurance documents, SOP documentation, to batch records
                - to create a relation and generate output built on entities
                (report) based on the user’s needs.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="picture1">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="impact py-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-3 col-sm-12 ">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12  ">
              <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled">
                <li>
                  The user can automate and convert raw documents into
                  informational records
                </li>
                <li>
                  The user can derive information and knowledge using Keywords
                </li>
                <li>
                  Generate a glossary and create tags for the user to view
                  similar keywords on different pages.
                </li>
                <li>Track process updates online</li>
                <li>
                  Character recognition and field management via AI and machine
                  learning
                </li>
                <li>Structured record management</li>
                <li>
                  Meticulous summary with simplified formats for clear
                  understanding.
                </li>
                <li>Accessible anytime, anywhere</li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-12 pb-3 pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-sm-12 pt-lg-5">
              <div className="impact-img4 d-flex flex-wrap align-items-center">
                <div className="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech7} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech8} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0  case">
                  <img src={tech9} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech10} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech11} class="img-fluid" alt="" />
                </div>
                <div className="img2 p-2 pb-0 case">
                  <img src={tech12} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12  pt-5">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p className="pt-1 pt-lg-4 p-4 p-lg-0">
                In the end, the client saw the real benefits of our AI system
                that understands the scanned report by reading through the lines
                and identifying its relevance irrespective of direct
                specification. This AI system can take any document format to
                create a relation and generate output.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="more-cases pb-5">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="heading">
                <div className="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center py-4 g-4">
            <div className="col-lg-4 col-sm-12">
              <Link to="/tracky">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Tracky</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    A perfect tool to help you control your workspace{" "}
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12 ">
              <Link to="/sunbelt">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Sunbelt</h4>
                  </div>
                  <p class=" pt-2">Performance Tracker & Store Optimizer</p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-12">
              <Link to="/skoolers">
                <div className="case rounded-3  h-100 case1 p-4 bg-light">
                  <div className="case-title">
                    <h4 class="">Skoolers</h4>
                  </div>
                  <p class=" pt-2">
                    Easy way to manage your school education structure
                  </p>
                  <div className="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Attesta;
