import React from "react";
import { Helmet } from 'react-helmet';
import LogoDark from "./app/assets/images/logo-dark.png";

class Head extends React.Component {
  render() {
    const { currRoute } = this.props;
    let headContent;
    if (currRoute === "") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="CAP Digisoft Solutions has been delivering Software, Artificial Intelligence and KPO solutions, servicing 100+ small, medium and larger corporations globally."
            />
            <link rel="canonical" href="https://capdigisoft.com/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="CAP Digisoft - Software Development | Artificial Intelligence | Web Solutions"
            />
            <meta
              property="og:description"
              content="CAP Digisoft offers Software, Mobile, & Web Application Development, and Artificial Intelligence Services. We help Global Businesses successfully solve complex Digital Challenges. Call Today for a Free Consultation."
            />
            <meta property="og:url" content="https://capdigisoft.com/" />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="CAP Digisoft - Software Development | Artificial Intelligence | Web Solutions"
            />
            <meta
              name="twitter:description"
              content="CAP Digisoft is a full service software consultants specializing in the design, development and support of engaging user experiences."
            />
            <meta
              name="twitter:image"
              content={LogoDark}
            />
            <meta name="twitter:site" content="@CAPDigisoft" />

            {/* <script type="application/ld+json" class="yoast-schema-graph">
              {`{
		"@context": "https://schema.org",
		"@graph": [{
		"@type": "Organization",
		"@id": "https://capdigisoft.com/index/#organization",
		"name": "Cap Digisoft Digital consultancy",
		"url": "https://capdigisoft.com/index/",
		"sameAs": ["https://www.facebook.com/capdigisoftsolutions",
		"https://www.linkedin.com/company/cap-digisoft-solutions-inc./",
		"https://twitter.com/CAPDigisoft"
		],
		"logo": {
		"@type": "ImageObject",
		"@id": "https://capdigisoft.com/index/#logo",
		"inLanguage": "en-US",
		"url": "Logo URL",
		"contentUrl": "Logo URL",
		"width": 150,
		"height": 80,
		"caption": "Cap Digisoft"
		},
		"image": {
		"@id": "https://capdigisoft.com/index/#logo"
		}
		},
		{
		"@type": "WebSite",
		"@id": "https://capdigisoft.com/index/#website",
		"url": "https://capdigisoft.com/index/",
		"name": "Cap Digisoft",
		"description": "",
		"publisher": {
		"@id": "https://capdigisoft.com/index/#organization"
		},
		"potentialAction": [{
		"@type": "SearchAction",
		"target": {
		"@type": "EntryPoint",
		"urlTemplate": "https://capdigisoft.com/index/?s={search_term_string}"
		},
		"query-input": "required name=search_term_string"
		}],
		"inLanguage": "en-US"
		},
		
		{
		"@type": "ImageObject",
		"@id": "https://capdigisoft.com/index/#primaryimage",
		"inLanguage": "en-US",
		"url": "Logo URL",
		"contentUrl": "",
		"width": 2000,
		"height": 1333
		}, {
		"@type": "WebPage",
		"@id": "https://capdigisoft.com/index/#webpage",
		"url": "https://capdigisoft.com/index/",
		"name": "Digital Consultancy & Web Development Services | Software Consulting",
		"isPartOf": {
		"@id": "https://capdigisoft.com/index/#website"
		},
		"about": {
		"@id": "https://capdigisoft.com/index/#organization"
		},
		"primaryImageOfPage": {
		"@id": "https://capdigisoft.com/index/#primaryimage"
		},
		"datePublished": "2007-04-26T13:46:22+00:00",
		"dateModified": "2021-12-20T14:13:42+00:00",
		"description": "CAP Digisoft Solutions has been delivering Software, Artificial Intelligence and KPO solutions, servicing 100+ small, medium and larger corporations globally."
		
		
		}
		]
  }`}
            </script> */}
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "products") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content=" Digital product development services refers to the full scope of creating new digital experiences for an organization’s end users. See more."
            />
            <link rel="canonical" href="https://capdigisoft.com/products" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="What Are Digital Product Development Services? | CAP Digisoft"
            />
            <meta
              property="og:description"
              content="Digital product development services refers to the full scope of creating new digital experiences for an organization’s end users. See more."
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/products"
            />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="What Are Digital Product Development Services? | CAP Digisoft"
            />
            <meta
              name="twitter:description"
              content="Digital product development services refers to the full scope of creating new digital experiences for an organization’s end users. See more."
            />
            <meta
              name="twitter:image"
              content={LogoDark}
            />
            <meta name="twitter:site" content="@CAPDigisoft" />
            {/* <script type="application/ld+json" class="yoast-schema-graph">
              {`{
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "Organization",
              "@id": "https://capdigisoft.com/indexHelmet/#organization",
              "name": "CAP Digisoft Digital consultancy",
              "url": "https://capdigisoft.com/index/",
              "sameAs": [
                "https://www.facebook.com/capdigisoftsolutions",
                "https://www.linkedin.com/company/cap-digisoft-solutions-inc./",
                "https://twitter.com/CAPDigisoft"
              ],
              "logo": {
                "@type": "ImageObject",
                "@id": "https://capdigisoft.com/index/#logo",
                "inLanguage": "en-US",
                "url": {LogoDark},
                "contentUrl": {LogoDark},
                "width": 150,
                "height": 80,
                "caption": "CAP Digisoft"
              },
              "image": {
                "@id": "https://capdigisoft.com/index/#logo"
              }
            },
            {
              "@type": "WebSite",
              "@id": "https://capdigisoft.com/index/#website",
              "url": "https://capdigisoft.com/index/",
              "name": "Digital Product Development Services",
              "publisher": {
                "@id": "https://capdigisoft.com/index/#organization"
              },
              "potentialAction": [
                {
                  "@type": "SearchAction",
                  "target": {
                    "@type": "EntryPoint",
                    "urlTemplate": "https://capdigisoft.com/index/?s={search_term_string}"
                  },
                  "query-input": "required name=search_term_string"
                }
              ],
              "inLanguage": "en-US"
            },
            {
              "@type": "ImageObject",
              "@id": "https://capdigisoft.com/products/#primaryimage",
              "inLanguage": "en-US",
              "url": "https://capdigisoft.com/images/idea-bg.jpg",
              "contentUrl": "https://capdigisoft.com/images/idea-bg.jpg",
              "width": 583,
              "height": 546
            },
            {
              "@type": "WebPage",
              "@id": "https://capdigisoft.com/products/#webpage",
              "url": "https://capdigisoft.com/products",
              "name": "Digital Product Development Services",
              "isPartOf": {
                "@id": "https://capdigisoft.com/index/#website"
              },
              "primaryImageOfPage": {
                "@id": "https://capdigisoft.com/products/#primaryimage"
              },
              "datePublished": "2016-08-02T20:02:36+00:00",
              "dateModified": "2021-12-17T13:21:42+00:00",
              "description": "Digital product development services refers to the full scope of creating new digital experiences for an organization’s end users. See more.",
              "breadcrumb": {
                "@id": "https://capdigisoft.com/products/#breadcrumb"
              },
              "inLanguage": "en-US",
              "potentialAction": [
                {
                  "@type": "ReadAction",
                  "target": [
                    "https://capdigisoft.com/products/"
                  ]
                }
              ]
            }
          ]
        }`}
            </script> */}
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "contactus") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content=" Get in touch with CAP Digisoft, and learn more about our global presence"
            />
            <link
              rel="canonical"
              href="https://capdigisoft.com/contactus"
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Contact Us  |  Business Development Solutions"
            />
            <meta
              property="og:description"
              content="Get in touch with CAP Digisoft, and learn more about our global presence"
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/contactus"
            />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Contact Us  |  Business Development Solutions"
            />
            <meta
              name="twitter:description"
              content="Get in touch with CAP Digisoft, and learn more about our global presence"
            />
            <meta
              name="twitter:image"
              content={LogoDark}
            />
            <meta name="twitter:site" content="@CAPDigisoft" />
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "generative-ai") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="Capdigisoft pioneers Generative AI solutions, pushing the boundaries of innovation. Explore our cutting-edge technology for smarter, more creative outcomes."
            />
            <meta name="author" content="https://capdigisoft.com" />
            <meta name="robots" content="index, follow" />
            <link
              rel="alternate"
              hreflang="x-default"
              href="https://capdigisoft.com/generative-ai"
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Unlocking Potential with Capdigisoft: Leaders in Generative AI Technology"
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/generative-ai"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />
            <meta
              property="og:description"
              content="Elevate your projects with Capdigisoft expertise in Generative AI. Unleash creativity and efficiency through our advanced artificial intelligence solutions."
            />
            {/* <script type="application/ld+json">
              {`
{
	"@context": "http://schema.org/",
	"@type": "Organization",
	"name": "Capdigisoft",
	"logo": {LogoDark},
	"url": "https://capdigisoft.com/generative-ai",
	"address": {
		"@type": "PostalAddress",
		"addressCountry": "United States"
	},
	"sameAs": ["https://www.facebook.com/capdigisoftsolutions/", "https://www.linkedin.com/company/cap-digisoft-solutions-inc./?originalSubdomain=in", "https://twitter.com/CAPDigisoft", "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw"]
}`}
            </script>
            <script type="application/ld+json">
              {`{
	"@context": "http://schema.org/",
	"@type": "BreadcrumbList",
	"itemListElement": [
		{
			"@type": "ListItem",
			"position": 1,
			"name": "Capdigisoft",
			"item": "https://capdigisoft.com/"
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "generative-ai",
			"item": "https://capdigisoft.com/generative-ai"
		}
	]
}`}
            </script> */}
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "client-success") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta
              name="description"
              content="Capdigisoft is dedicated to your success. Explore our Client Success Services, designed to optimize your growth and ensure unparalleled customer satisfaction."
            />
            <meta name="author" content="https://capdigisoft.com" />
            <meta name="robots" content="index, follow" />

            <link
              rel="alternate"
              hreflang="x-default"
              href="https://capdigisoft.com/client-success"
            />

            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Capdigisoft - Redefining Success with Client-Centric Solutions"
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/client-success"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />
            <meta
              property="og:description"
              content="Experience a new level of success with Capdigisoft Client Success Services. We are dedicated to driving your business forward through tailored strategies and support."
            />

            {/* <script type="application/ld+json">
              {`{
	"@context": "http://schema.org/",
	"@type": "Organization",
	"name": "Capdigisoft",
	"logo": {LogoDark},
	"url": "https://capdigisoft.com/client-success",
	"address": {
		"@type": "PostalAddress",
		"addressCountry": "United States"
	},
	"sameAs": ["https://www.facebook.com/capdigisoftsolutions/", "https://www.linkedin.com/company/cap-digisoft-solutions-inc./?originalSubdomain=in", "https://twitter.com/CAPDigisoft", "https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw"]
}`}
            </script>

            <script type="application/ld+json">
              {`{
	"@context": "http://schema.org/",
	"@type": "BreadcrumbList",
	"itemListElement": [
		{
			"@type": "ListItem",
			"position": 1,
			"name": "Capdigisoft",
			"item": "https://capdigisoft.com/"
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "Client Success Service",
			"item": "https://capdigisoft.com/client-success"
		}
	]
}`}
            </script> */}
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "it_support") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "staffing-support-service") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta
              name="description"
              content="Discover the power of strategic outsourcing with Capdigisoft. Our Recruitment Process Outsourcing services redefine your hiring process for optimal efficiency and talent acquisition"
            />
            <meta name="author" content="https://capdigisoft.com" />
            <meta name="robots" content="index, follow" />

            <link
              rel="alternate"
              hreflang="x-default"
              href="https://capdigisoft.com/staffing-support-service"
            />

            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Recruitment Process Outsourcing by Capdigisoft: Efficiency, Expertise, Excellence"
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/staffing-support-service"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />
            <meta
              property="og:description"
              content="Capdigisoft is your trusted ally in recruitment success. Our Recruitment Process Outsourcing services are tailored to optimize your hiring process and drive organizational growth."
            />
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "comprehensive-website-design") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="If you don’t have a custom website that contributes directly to your revenue through sales or leads, you’re missing out. Contact our web team today!"
            />
            <link
              rel="canonical"
              href="https://capdigisoft.com/comprehensive-website-design"
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Website Design Services | Professional Web Design | Full Service Agency"
            />
            <meta
              property="og:description"
              content="If you don’t have a custom website that contributes directly to your revenue through sales or leads, you’re missing out. Contact our web team today!"
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/comprehensive-website-design"
            />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Website Design Services | Professional Web Design | Full Service Agency"
            />
            <meta
              name="twitter:description"
              content="If you don’t have a custom website that contributes directly to your revenue through sales or leads, you’re missing out. Contact our web team today!"
            />
            <meta
              name="twitter:image"
              content={LogoDark}
            />
            <meta name="twitter:site" content="@CAPDigisoft" />
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "knowledge-process-outsourcing") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="CAP Digisoft provides knowledge process outsourcing (KPO) services. These knowledge services include information services, research services and complex high-end analytics."
            />
            <link
              rel="canonical"
              href="https://capdigisoft.com/knowledge-process-outsourcing"
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="KPO: Knowledge Process Outsourcing - KPO services"
            />
            <meta
              property="og:description"
              content="CAP Digisoft provides knowledge process outsourcing (KPO) services. These knowledge services include information services, research services and complex high-end analytics."
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/knowledge-process-outsourcing"
            />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="KPO: Knowledge Process Outsourcing - KPO services"
            />
            <meta
              name="twitter:description"
              content="CAP Digisoft provides knowledge process outsourcing (KPO) services. These knowledge services include information services, research services and complex high-end analytics."
            />
            <meta
              name="twitter:image"
              content={LogoDark}
            />
            <meta name="twitter:site" content="@CAPDigisoft" />
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "b2b-lead-generation-expert") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="B2B Lead Generation Experts : Our Orchestrated Outbound Services are designed to help you grow your company by building a strong sales pipeline."
            />
            <meta
              property="og:title"
              content="B2B Lead Generation Experts | B2B Outbound Lead Generation"
            />
            <meta
              property="og:description"
              content="B2B Lead Generation Experts : Our Orchestrated Outbound Services are designed to help you grow your company by building a strong sales pipeline."
            />
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "ourcompany") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="Read about our full end-to-end IT services and solutions"
            />
            <link
              rel="canonical"
              href="https://capdigisoft.com/our-company"
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="CAP Digisoft - IT Services and Solutions Company | Data Management Services"
            />
            <meta
              property="og:description"
              content="Read about our full end-to-end IT services and solutions"
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/our-company"
            />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="CAP Digisoft - IT Services and Solutions Company | Data Management Services"
            />
            <meta
              name="twitter:description"
              content="Read about our full end-to-end IT services and solutions"
            />
            <meta
              name="twitter:image"
              content={LogoDark}
            />
            <meta name="twitter:site" content="@CAPDigisoft" />
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "mteam") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="CAP Digisoft - A technology innovation company. Our Team comprises of the technology leaders and well experienced professionals from various domains."
            />
            <link
              rel="canonical"
              href="https://capdigisoft.com/management-team"
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="CAP Digisoft - IT Services and Solutions Company"
            />
            <meta
              property="og:description"
              content="CAP Digisoft - A technology innovation company. Our Team comprises of the technology leaders and well experienced professionals from various domains."
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/management-team"
            />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="CAP Digisoft - IT Services and Solutions Company"
            />
            <meta
              name="twitter:description"
              content="CAP Digisoft - A technology innovation company. Our Team comprises of the technology leaders and well experienced professionals from various domains."
            />
            <meta
              name="twitter:image"
              content={LogoDark}
            />
            <meta name="twitter:site" content="@CAPDigisoft" />
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "careers") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="Looking to develop your career in a passionate professional environment with dedicated workforce? Join our Team."
            />
            <link rel="canonical" href="https://capdigisoft.com/careers" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Careers at CAP Digisoft" />
            <meta
              property="og:description"
              content="Looking to develop your career in a passionate professional environment with dedicated workforce? Join our Team."
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/careers"
            />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content={LogoDark}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Careers at CAP Digisoft" />
            <meta
              name="twitter:description"
              content="Looking to develop your career in a passionate professional environment with dedicated workforce? Join our Team."
            />
            <meta
              name="twitter:image"
              content={LogoDark}
            />
            <meta name="twitter:site" content="@CAPDigisoft" />
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "pressrelease") {
      headContent = (
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content="CAP Digisoft Solutions, Inc. has announced the opening of a new call center office in El Salvador. The state-of-the-art facility, located in the heart of San Salvador, will serve as a growth solution for companies seeking to expand their market share and improve their customer service operations."
            />
            <link
              rel="canonical"
              href="https://capdigisoft.com/press-release"
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Press Release | CAP Digisoft" />
            <meta
              property="og:description"
              content="CAP Digisoft Solutions, Inc. has announced the opening of a new call center office in El Salvador. The state-of-the-art facility, located in the heart of San Salvador, will serve as a growth solution for companies seeking to expand their market share and improve their customer service operations. "
            />
            <meta
              property="og:url"
              content="https://capdigisoft.com/press-release"
            />
            <meta property="og:site_name" content="CAP Digisoft" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/capdigisoftsolutions/"
            />
            <meta
              property="article:modified_time"
              content="2021-08-23T14:13:42+00:00"
            />
            <meta
              property="og:image"
              content="https://capdigisoft.com/images/el-salvador/image-11.jpg"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Press Release | CAP Digisoft" />
            <meta
              name="twitter:url"
              content="https://capdigisoft.com/press-release"
            />
            <meta
              name="twitter:description"
              content="CAP Digisoft Solutions, Inc. has announced the opening of a new call center office in El Salvador. The state-of-the-art facility, located in the heart of San Salvador, will serve as a growth solution for companies seeking to expand their market share and improve their customer service operations."
            />
            <meta
              name="twitter:image"
              content="https://capdigisoft.com/images/el-salvador/twitter/image-11.jpg"
            />
            <meta name="twitter:site" content="@CAPDigisoft" />
          </Helmet>
        </React.Fragment>
      );
    } else if ( currRoute === "cds-opens-new-call-center-office-in-el-salvador") {
      headContent = (
        <React.Fragment>
          <Helmet>
          <title>CAP Digisoft Solutions, Inc. Opens New Call Center Office in El Salvador | CAP Digisoft Solutions</title>
          </Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "joiint-inc-introduces-a-new-application") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Joiint Inc. Introduces a New Application that Easily Juggles your Personal and Professional Lives | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "blog") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Blog | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "casestudy") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "fura") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "iledger") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "geni") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "practica") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Practica | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "mergers-and-acquisition-firm") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Merger and Acquisition Firm | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "logistics-company") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Logistics Company | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "attesta") {
      headContent = (
        <React.Fragment>
          <Helmet><title>AttestaAI | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "hireharmony") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "workflow-management-system") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Workflow Management System | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "medico-view-desk-system") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Medico – View Desk | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "narrative-medical-record-summaries") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Narrative Medical Record Summaries | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "frontera") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Frontera Search Partners | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "j&p") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "tracky") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Tracky | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "skoolers") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Skoolers | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "panera-bread") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Panera Bread | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "liti-code-system") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Liti-code System | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "ai-driven-optimization-of-prescription-fulfillment" ) {
      headContent = (
        <React.Fragment>
          <Helmet><title>AI-Driven Optimization of Prescription Fulfillment | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "intellicue") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "medico-risk-chart-app-system") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Medico – Risk Chart App | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "medico-ai-chrono-sort-app-attesta-system") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Medico AI – Chrono Sort App (Attesta) | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "e-sort-desk-system") {
      headContent = (
        <React.Fragment>
          <Helmet><title>E-Sort Desk | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "clued-in-closet") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "bussopi") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Bussopi | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "reeler") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Reeler | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "helpfindingmyagent") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "jugl") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "freedownload") {
      headContent = (
        <React.Fragment>
          <Helmet><title>Free Downloads | CAP Digisoft Solutions</title></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "downloadbook/:id") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "marketing-and-consulting") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "nearshore-call-center-outsourcing") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "terms-of-use") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "privacy-policy") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "form") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "downloadform") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "thankyou") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else if (currRoute === "thankyoudownload") {
      headContent = (
        <React.Fragment>
          <Helmet></Helmet>
        </React.Fragment>
      );
    } else {
      headContent = <></>;
    }

    return headContent;
  }
}

export default Head;

