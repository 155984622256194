import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/helpfindingmyagent/banner.jpg";

import icon1 from "../../../../src/app/assets/images/casestudy/helpfindingmyagent/icon1.png";
import icon2 from "../../../../src/app/assets/images/casestudy/helpfindingmyagent/icon2.png";
import icon3 from "../../../../src/app/assets/images/casestudy/helpfindingmyagent/icon3.png";

import PDF from "../../../app/assets/casestudypdf/helpfindingmyagent.pdf";

const HelpFinding = () => {
  useEffect(() => {
    document.title = "Help finding my agent | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-logistics-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div className="container custom-cn-width position-relative">
          <div className="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div className="col-lg-9">
              <h5 class="text-white">Case Study</h5>
              <h1 class="text-white fw-semibold">Help Finding My Agent</h1>
            </div>
            <div className="col-lg-3 text-lg-end">
              <a href={PDF} class="default-btn orange-btn" target="_blank" rel="noreferrer">
                <i class="fa fa-file-pdf me-1"></i>
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row justify-content-center g-lg-5 g-4">
            <div className="col-lg-7">
              <h1 class="fw-light mb-lg-4 mb-3">Overview</h1>
              <p class="">
                It’s an inescapable fact there are unfilled niches in every
                market - some that are glaringly obvious and others that only
                seem that way after the niche is filled by a savvy entrepreneur.
                But the fact that{" "}
                <span class="text-bold">
                  only 56% of small businesses make it to their fifth year
                </span>{" "}
                means it’s also often the case that successfully filling those
                niches requires more than just a website, a hope, and a prayer.
              </p>
              <p class="mt-3">
                An entrepreneur in the real estate market had a business idea to
                serve an unfilled niche but lacked the marketing expertise to
                bring that idea into reality. As a partner, they were able to
                use <span class="text-bold">Flobile’s expertise</span> in brand
                building, content strategy, paid media,{" "}
                <span class="text-bold">SEO</span> and more all to bear in
                driving the startup website to success.
              </p>
              <p class="mt-3">
                Flobile handled the development of the site from the ground up,
                from conception to launch, using their experience in building
                top-shelf buyer enablement tools to fulfill a need in the market
                that hadn’t yet been addressed. Flobile became a{" "}
                <span class="text-bold">valuable partner</span> to the
                entrepreneur and was able to provide him with resources that
                would have been prohibitively expensive to develop himself.
              </p>
            </div>
            <div className="col-lg-5 col-md-9">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 text-center text-lg-start section-padding pt-0">
        <div className="container custom-cn-width">
          <div className="row g-xl-5 g-4">
            <div className="col-lg-7 mb-4 mb-lg-5">
              <h1 class="fw-light">CDS Solution Approach</h1>
            </div>
          </div>
          <div className="row g-xl-5 g-4">
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons sec-primary-ic mb-4 mx-auto mx-lg-0  d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon1}
                    class="img-fluid"
                    alt="Prospect Database Cleansing"
                  />
                </div>
                <h4>Custom CRM Development</h4>
                <p class="mt-3">
                  Flobile provided the expertise and resources for not only
                  custom web and mobile development, but also the development of
                  the backend software to power the site’s buyer enablement
                  tools, which Integrated with email, text message, calendars
                  and more.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="Prospect Database Governance"
                  />
                </div>
                <h4>Product Launch, Sales and Marketing Strategy</h4>
                <p class="mt-3">
                  From branding to email marketing to paid media and PR, Flobile
                  handled every aspect of the sales and marketing strategy for
                  the launch of the product.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="features-item h-100 justify-content-start">
                <div className="icons mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center sec-primary-ic rounded-pill">
                  <img
                    src={icon3}
                    class="img-fluid"
                    alt="Email Marketing Strategy"
                  />
                </div>
                <h4>Content and SEO</h4>
                <p class="mt-3">
                  No website would be complete without the content to fill it
                  and the SEO to get it properly showing up in search engine
                  results. With the concept from the entrepreneur in hand,
                  Flobile handled all of the rest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class=" text-center text-lg-start section-padding">
        <div className="container custom-cn-width">
          <div className="row g-lg-0 g-4 justify-content-center">
            <div className="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div className="p-lg-5 p-4">
                <h1 class="fw-light text-white">Results</h1>
                <p class="mt-3 mb-3 text-white">
                  From <span class="text-bold">conception to launch</span>,
                  Flobile handled the heavy lifting to bring this{" "}
                  <span class="text-bold">business idea to reality</span>-
                  utilizing resources in web and mobile development, buyer
                  enablement tool development, sales and marketing strategy, and
                  more. Clients in the area who were looking for representation
                  were able to connect to agents and the agents, in turn, saw{" "}
                  <span class="text-bold">30-40% more revenue</span>, making
                  everyone happy.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div className="p-lg-5 p-4">
                <h1 class="fw-light  mb-3">Conclusion</h1>

                <p class="mt-3 ">
                  Sometimes having a great business idea isn’t enough. There are
                  so many moving parts to building a successful business from
                  the ground up that it can often help to partner with a firm
                  that has expertise in areas that you may not necessarily have
                  the resources or ability to bring to your business yourself.{" "}
                  <span class="text-bold">
                    Flobile has a proven track record
                  </span>{" "}
                  of supporting businesses with expert knowledge in marketing,
                  content, software development and much more -{" "}
                  <span class="text-bold">elevating startup businesses</span>
                  to greater levels of success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div className="container custom-cn-width">
          <div className="row g-5 align-items-center justify-content-center text-center">
            <div className="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div className="container custom-cn-width">
          <div className="row g-lg-5 g-4 text-center">
            <div className="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Case Studies</h1>
            </div>
          </div>
          <div className="">
            <div className="row g-4 g-xl-5 text-center text-xl-start">
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Workflow Management System
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/workflow-management-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">Panera Bread</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/panera-bread">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Merger and Acquisition Firm
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/mergers-and-acquisition-firm">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default HelpFinding;
