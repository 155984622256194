import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Ganalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('UA-3150027-2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Initialize Facebook Pixel
    ReactPixel.init('1858253294214836');
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    // Track pageview on route change
    ReactGA.pageview(location.pathname + location.search);
    ReactPixel.pageView();
  }, [location]);

  return (

    <div>
      {/* Google Analytics */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-3150027-2"></script>

      {/* Google Ads */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-1049519324"></script>

      {/* Leadberry */}
      <script async src="https://app.leadberry.com/trackers/lb-24555-24555-110026.js"></script>

      {/* Smartlook */}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.smartlook||(function(d) {
              var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
              var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
              c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
            })(document);
            smartlook('init', '701a7ea654d226ee8ace0c374412d40d2fc34598', { region: 'eu' });
          `,
        }}
      ></script>

      {/* Product Schema Markup */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
            {
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "Idea to Product | B2B Lead Generation",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.4",
                "reviewCount": "5192"
              }
            }
          `,
        }}
      ></script>

      {/* Metricool */}
      <script async src="https://tracker.metricool.com/resources/be.js"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `beTracker.t({hash:"6992c0d0837ea9f3dfa7ef5408adae22"})`,
        }}
      ></script>
    </div>

  );
};

export default Ganalytics;
