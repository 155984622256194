import React from 'react';
import quote from '../../../app/assets/images/banner/quote.png';
import Slider from "../slide";


const About = () => {
    
  return (
    <section className="work-process section-padding" style={{ paddingBottom: "90px" }}>
      <div className="container custom-cn-width">
        <div className="row g-lg-5 g-4 justify-content-center">
          <div className="col-lg-9 mb-4 mb-lg-5">
            <div className="quote-img text-center">
              <img src={quote} alt="Logo" className="img-fluid mb-1" />
            </div>
            <div className="section-heading text-center cus-pd-24-3">
              <h1 className=" fw-light">
                Seeing the supportive words from CAP Digisoft Solutions satisfied clients is why our team puts in the hard work, every day
              </h1>
            </div>
          </div>
        </div>

        <Slider/>
      </div>
    </section>
  );
};

export default About;
