/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import { Link } from "react-router-dom";

import HeaderOne from "../../../common/header/HeaderOne";
import FooterOne from "../../../common/footer/FooterOne";

import person1 from "../../../../src/app/assets/images/genai-media/person1.png";
import bg1 from "../../../../src/app/assets/images/genai-media/bg1.jpg";
import bg2 from "../../../../src/app/assets/images/genai-media/bg2.jpg";
import bg3 from "../../../../src/app/assets/images/genai-media/bg3.jpg";
import bg4 from "../../../../src/app/assets/images/genai-media/bg4.png";

import pdt1 from "../../../../src/app/assets/images/genai-media/pdt1.jpg";
import pdt2 from "../../../../src/app/assets/images/genai-media/pdt2.jpg";
import pdt3 from "../../../../src/app/assets/images/genai-media/pdt3.jpg";
import pdt4 from "../../../../src/app/assets/images/genai-media/pdt4.jpg";
import pdt5 from "../../../../src/app/assets/images/genai-media/pdt5.jpg";
import pdt6 from "../../../../src/app/assets/images/genai-media/pdt6.jpg";

import case1 from "../../../../src/app/assets/images/genai-media/case1.jpg";
import case2 from "../../../../src/app/assets/images/genai-media/case2.jpg";
import case3 from "../../../../src/app/assets/images/genai-media/case3.jpg";

import person2 from "../../../../src/app/assets/images/genai-media/person2.png";
import person3 from "../../../../src/app/assets/images/genai-media/person3.png";
import person4 from "../../../../src/app/assets/images/genai-media/person4.png";

//generative form
import GenerativeForm from "../Form/generativeform";
import Meta from "../../Meta/Generativeai";

const Genai = () => {
	useEffect(() => {
	  document.title = 'Capdigisoft: Unleashing the Power of Generative AI Innovation';
	}, []);
  return (
    <div className="geanai-page">
      <HeaderOne />
      <Meta/>
      <section className="d-flex align-items-center justify-content-center banner-sec gen-home overflow-hidden text-start margin-minus">
        <div className="container custom-cn-width position-relative z-index-1">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="position-relative">
                <h2 className=" text-white fw-bold">GENERATIVE AI</h2>
                <h5 className="text-white fw-light mt-4 pt-3 font-size-21">
                  Unlock the Future of Efficiency: Harness Generative AI to make
                  decisions quickly and empower your teams with trust
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gen-about section-padding">
        <div className="container custom-cn-width position-relative z-index-1">
          <div className="row align-items-center justify-content-center g-xl-5 g-4">
            <div className="col-xl-8 col-lg-8">
              <h1 className="pb-3 fw-light">Why Partner with CAP Digisoft?</h1>
              <p className="text-dark mb-3 font-size-19">
                CAP Digisoft's Generative AI Framework, powered by
                state-of-the-art Large Language Models, simplifies the
                integration of AI into your products. It comprehends your
                enterprise data, enabling your teams to make decisions quickly
                and interact in natural language.{" "}
              </p>
              <p className="text-dark font-size-19">
                At CAP Digisoft, we merge cutting-edge technology with human
                insight. Designed for intuitive, human-like interactions, our
                solutions and products adhere to Responsible AI principles. We
                prioritize enterprise security, transparency, and
                trustworthiness. Our aim is to make AI more accessible to you,
                by fusing innovation, expertise, and strategic partnerships.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="global-sec py-lg-5 py-4 section-padding">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8">
              <h5 className="fw-400 mb-4 font-size-19">
              &#x22;Generative AI is really having a moment. People from around the
                world in all industry segments are starting to recognize the
                power of generative AI. It changes the way people think about
                the problems that they solve during their core day-to-day work,
                and it's absolutely thrilling. This is a pivotal moment for
                artificial intelligence as it discovers new applications.
                However, with the rapid pace of advancements, things are
                happening so fast; there are risks that we have to manage. We
                have to think through responsible ways of creating and deploying
                this technology. Overall, I believe in the power of progress.
                I'm convinced that crafting tools to boost people's productivity
                will not only enhance our work but also make the world a better
                place.&#x22;
              </h5>
              <div className="d-flex jusitfy-content-start align-items-center">
                <div className="author-image">
                  <img src={person1} alt="Arun-expert" className="img-fluid" />
                </div>
                <div className="ps-2 author-name">
                  <h5 className="">Arunkumar Nagaiyasamy</h5>
                  <p className="">Generative AI Expert at CAP DigiSoft</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="propel-sec section-padding">
        <div className="container custom-cn-width position-relative z-index-1">
          <div className="row g-xl-5 g-4 justify-content-center">
            <div className="col-xl-11 col-lg-8 col-md-10">
              <div className="mb-xl-5 mb-4">
                <h1 className="fw-light">
                  Discover How CAP Digisoft Can Propel Your Vision
                </h1>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center g-xl-5 g-4">
            <div className="col-xl-6 col-lg-6 text-center text-lg-end">
              <img src={bg1} alt="Arun-expert" className="img-fluid" />
            </div>
            <div className="col-xl-5 col-lg-5 ">
              <div className="propel-item rounded-3 bg-white propel-left  d-flex flex-column justify-content-between">
                <div>
                  <h4 className="text-dark pb-3">
                    Gen AI Strategy and Use Case Selection
                  </h4>
                  <p className="text-dark">
                    Our Generative AI strategy and use case selection empowers
                    enterprise leaders to identify and prioritize
                    business-critical generative AI applications. We focus on
                    delivering quick prototypes and immediate, measurable
                    results while laying the groundwork in human capital,
                    operational methods, and tech infrastructure. This allows
                    businesses to expand their generative AI efforts effectively
                    and securely, optimizing returns and reducing risks.{" "}
                  </p>

                  <ul className="mt-3 ps-4">
                    <li className="text-dark">
                      <span className="fw-bold text-dark">
                        Application-Specific AI: {" "} 
                      </span>
                       Embed tailored generative models directly into enterprise
                      applications to enhance productivity and decision-making.
                    </li>
                    <li className="text-dark">
                      <span className="fw-bold text-dark">
                        Robust Infrastructure: {" "}
                      </span>
                       Deploy scalable, high-performance platforms for training
                      and running generative AI models, ensuring optimal task
                      automation.
                    </li>
                    <li className="text-dark">
                      <span className="fw-bold text-dark">
                        Managed Services:{" "} 
                      </span>{" "}
                       Offer turn-key cloud services that enable easy integration
                      of generative AI into existing enterprise workflows via
                      simple APIs.
                    </li>
                    <li className="text-dark">
                      <span className="fw-bold text-dark">
                        Unmatched Security: {" "}
                      </span>
                       Prioritize data security and privacy, ensuring that
                      customer data is segregated and secure in compliance with
                      regulatory standards.
                    </li>
                    <li className="text-dark">
                      <span className="fw-bold text-dark">
                        Flexible Deployment: {" "}
                      </span>
                      Provide options for deploying generative AI services
                      either on public clouds or within private data centers for
                      maximum compatibility and security.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="propel-sec pt-0 section-padding">
        <div className="container custom-cn-width position-relative z-index-1">
          <div className="row align-items-center justify-content-center g-xl-5 g-4">
            <div className="col-xl-5 col-lg-5 order-2 order-lg-1">
              <div className="propel-item rounded-3 bg-white  propel-right  d-flex flex-column justify-content-between">
                <div>
                  <h4 className="pb-3 text-dark">
                    Enterprise – Tailored Generative AI
                  </h4>
                  <p className="text-dark">
                    Our Custom Generative AI solution is designed specifically
                    for enterprise needs, aiming to leverage your existing
                    skills and expertise while supporting your creative ideas
                    and solutions. It ensures that the synergy of human
                    intelligence remains at the forefront. Keep your data secure
                    and privately managed within your own secure cloud, whether
                    through cloud partners like AWS, GCP, Azure, etc., or within
                    your own virtual private clouds. Data is never sent to Cap
                    Digitsoft; it stays within your own environment.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6  text-center text-lg-start order-1 oder-lg-2">
              <img src={bg2} alt="Arun-expert" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className="propel-sec pt-0 section-padding">
        <div className="container custom-cn-width position-relative z-index-1">
          <div className="row align-items-center justify-content-center g-xl-5 g-4">
            <div className="col-xl-6 col-lg-6 text-center text-lg-end">
              <img src={bg3} alt="Arun-expert" className="img-fluid" />
            </div>
            <div className="col-xl-5 col-lg-5 ">
              <div className="propel-item rounded-3 bg-white  propel-left d-flex flex-column justify-content-between">
                <div>
                  <h4 className="pb-3 text-dark">
                    GenAI-Driven Software Engineering
                  </h4>
                  <p className="text-dark">
                    Our Generative AI software development life cycle (SDLC)
                    frameworks and prompt templates are designed specifically
                    with various roles—such as product owner, product manager,
                    scrum master, business/data analyst, architect, data
                    modeler, data engineer, BI engineer, data scientist, quality
                    assurance, and DevOps engineer—in mind. They integrate
                    seamlessly into their day-to-day activities to empower
                    efficiency, boost productivity, and accelerate product or
                    prototype delivery in order to support business priorities.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ai-service-sec section-padding w-100">
        <div className="container custom-cn-width">
          <div className="row g-xl-5 g-4 justify-content-center">
            <div className="col-xl-10 col-lg-8 col-md-10">
              <div className="">
                <h1 className="fw-light">Our Generative AI Services</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-lg-4 g-4 align-items-center">
            <div className="col-lg-5 col-md-6">
              <img src={bg4} alt="bg" className="img-fluid rounded-3" />
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="">
                <p>
                  We consult on Generative AI and provide solutions across
                  diverse sectors including law firms, healthcare, retail,
                  energy, and finance. We're not just about concepts; we're
                  about turning visionary AI ideas into actionable features,
                  ensuring you're ahead in the technological curve.
                </p>
                <ul className="gen-service-ul mt-3 ps-4">
                  <li>
                    <span className="fw-bold">Rapid Prototyping:</span> From
                    idea to PoC in mere weeks.
                  </li>
                  <li>
                    <span className="fw-bold">AI-Enhanced Products:</span>{" "}
                    Infuse your offerings with cutting-edge AI capabilities.
                  </li>
                  <li>
                    <span className="fw-bold">Business-Centric Approach:</span>{" "}
                    Your business objectives are at the heart of our solutions.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row justify-content-start my-4 g-4 text-center text-xl-start">
                <div className="col-lg-6 col-md-6">
                  <div className="feature-box d-xl-flex">
                    <div>
                      <div className="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                        <svg
                          className="w-50 fill-white"
                          xmlns="https://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M385.1 419.1C349.7 447.2 304.8 464 256 464s-93.7-16.8-129.1-44.9l80.4-80.4c14.3 8.4 31 13.3 48.8 13.3s34.5-4.8 48.8-13.3l80.4 80.4zm68.1 .2C489.9 374.9 512 318.1 512 256s-22.1-118.9-58.8-163.3L465 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L419.3 58.8C374.9 22.1 318.1 0 256 0S137.1 22.1 92.7 58.8L81 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L58.8 92.7C22.1 137.1 0 193.9 0 256s22.1 118.9 58.8 163.3L47 431c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l11.8-11.8C137.1 489.9 193.9 512 256 512s118.9-22.1 163.3-58.8L431 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-11.8-11.8zm-34.1-34.1l-80.4-80.4c8.4-14.3 13.3-31 13.3-48.8s-4.8-34.5-13.3-48.8l80.4-80.4C447.2 162.3 464 207.2 464 256s-16.8 93.7-44.9 129.1zM385.1 92.9l-80.4 80.4c-14.3-8.4-31-13.3-48.8-13.3s-34.5 4.8-48.8 13.3L126.9 92.9C162.3 64.8 207.2 48 256 48s93.7 16.8 129.1 44.9zM173.3 304.8L92.9 385.1C64.8 349.7 48 304.8 48 256s16.8-93.7 44.9-129.1l80.4 80.4c-8.4 14.3-13.3 31-13.3 48.8s4.8 34.5 13.3 48.8zM208 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z" />
                        </svg>
                      </div>
                    </div>
                    <div className="ms-xl-4 mt-4 mt-xl-0">
                      <h4 className="mb-3">GenAI Technology Consulting</h4>
                      <p>
                        We offer GenAI technology consulting services, helping
                        navigate the ever-changing AI landscape. We analyze your
                        product's needs, consult on what is possible with new AI
                        technology, and choose the right approach.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="feature-box d-xl-flex">
                    <div>
                      <div className="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                        <svg
                          className="w-50 fill-white"
                          xmlns="https://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M432 48H208c-17.7 0-32 14.3-32 32V96H128V80c0-44.2 35.8-80 80-80H432c44.2 0 80 35.8 80 80V304c0 44.2-35.8 80-80 80H416V336h16c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32zM48 448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V256H48V448zM64 128H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z" />
                        </svg>
                      </div>
                    </div>
                    <div className="ms-xl-4 mt-4 mt-xl-0">
                      <h4 className="mb-3">AI proof-of-concept development</h4>
                      <p>
                        Validate your AI feature hypothesis with quick discovery
                        that takes 4-6 weeks. During this time, we will analyze
                        your needs, available data, establish performance
                        baseline, prepare and test several approaches that solve
                        your problem.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="feature-box d-xl-flex">
                    <div>
                      <div className="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                        <svg
                          className="w-50 fill-white"
                          xmlns="https://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path d="M256 64H384v64H256V64zM240 0c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h48v32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96v32H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H240c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H192V288H448v32H400c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H560c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H512V288h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V192h48c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H240zM96 448V384H224v64H96zm320-64H544v64H416V384z" />
                        </svg>
                      </div>
                    </div>
                    <div className="ms-xl-4 mt-4 mt-xl-0">
                      <h4 className="mb-3">
                        Generative AI Model Integration &amp; Deployment
                      </h4>
                      <p>
                        We connect pre-trained generative models to your
                        product. Our end-to-end service includes model
                        selection, integration, testing, and deployment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="feature-box d-xl-flex">
                    <div>
                      <div className="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                        <svg
                          className="w-50 fill-white"
                          xmlns="https://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M320 488c0 9.5-5.6 18.1-14.2 21.9s-18.8 2.3-25.8-4.1l-80-72c-5.1-4.6-7.9-11-7.9-17.8s2.9-13.3 7.9-17.8l80-72c7-6.3 17.2-7.9 25.8-4.1s14.2 12.4 14.2 21.9v40h16c35.3 0 64-28.7 64-64V153.3C371.7 141 352 112.8 352 80c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V320c0 70.7-57.3 128-128 128H320v40zM456 80a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM192 24c0-9.5 5.6-18.1 14.2-21.9s18.8-2.3 25.8 4.1l80 72c5.1 4.6 7.9 11 7.9 17.8s-2.9 13.3-7.9 17.8l-80 72c-7 6.3-17.2 7.9-25.8 4.1s-14.2-12.4-14.2-21.9V128H176c-35.3 0-64 28.7-64 64V358.7c28.3 12.3 48 40.5 48 73.3c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-32.8 19.7-61 48-73.3V192c0-70.7 57.3-128 128-128h16V24zM56 432a24 24 0 1 0 48 0 24 24 0 1 0 -48 0z" />
                        </svg>
                      </div>
                    </div>
                    <div className="ms-xl-4 mt-4 mt-xl-0">
                      <h4 className="mb-3">
                        Integrating AI models to build AI-enhanced features for
                        Domain / Business Function Specific{" "}
                      </h4>
                      <p>
                        We leverage existing pretrained Large Language Models
                        (LLMs), GPT 3.5, GPT 4 and fine tune, customizations,
                        to build AI enhanced features for your product.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="feature-box d-xl-flex">
                    <div>
                      <div className="icons sec-primary-ic mb-4 d-flex align-items-center justify-content-center mx-auto rounded-pill">
                        <svg
                          className="w-50 fill-white"
                          xmlns="https://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M186.1 343.3c-9.7 9.7-9.7 25.3 0 34.9 9.7 9.7 25.3 9.7 34.9 0L378.2 221.1c19.3-19.3 50.6-19.3 69.9 0s19.3 50.6 0 69.9L294 445.1c19.3 19.3 50.5 19.3 69.8 0l0 0 119.3-119.2c38.6-38.6 38.6-101.1 0-139.7-38.6-38.6-101.2-38.6-139.7 0l-157.2 157.2zm244.5-104.8c-9.7-9.7-25.3-9.7-34.9 0l-157.2 157.2c-19.3 19.3-50.5 19.3-69.8 .1l-.1-.1c-9.6-9.6-25.3-9.7-34.9 0l0 0c-9.7 9.6-9.7 25.3 0 34.9l0 0c38.6 38.6 101.1 38.6 139.7 0l157.2-157.2c9.7-9.7 9.7-25.3 0-34.9zm-262 87.3l157.2-157.2c9.6-9.7 9.6-25.3 0-34.9-9.6-9.6-25.3-9.6-34.9 0L133.7 290.9c-19.3 19.3-50.6 19.3-69.9 0l0 0c-19.3-19.3-19.3-50.5 0-69.8l0 0L218 66.9c-19.3-19.3-50.6-19.3-69.9 0l0 0L28.9 186.1c-38.6 38.6-38.6 101.1 0 139.7 38.6 38.6 101.1 38.6 139.7 0zm-87.3-52.4c9.6 9.6 25.3 9.6 34.9 0l157.2-157.2c19.3-19.3 50.6-19.3 69.8 0l0 0c9.7 9.7 25.3 9.7 34.9 0 9.7-9.7 9.7-25.3 0-34.9-38.6-38.6-101.1-38.6-139.7 0L81.3 238.5c-9.7 9.6-9.7 25.3 0 34.9h0z" />
                        </svg>
                      </div>
                    </div>
                    <div className="ms-xl-4 mt-4 mt-xl-0">
                      <h4 className="mb-3">
                        Post-Release Improvements and Maintenance
                      </h4>
                      <p>
                        We are committed to providing comprehensive
                        after-release support for &nbsp; your AI app. AI
                        technology is evolving rapidly, so we provide
                        monitoring, improvements, updates, and
                        performance&nbsp;enhancements to our&nbsp;solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="other-sec section-padding">
        <div className="container custom-cn-width">
          <div className="row">
            <div className="col-lg-12 mb-lg-5 mb-4 px-0">
              <h1 className="fw-light">Our Products</h1>
            </div>
          </div>
          <div className="">
            <div className="row text-center justify-content-center align-items-center">
              <div className="col-lg-4 col-md-6 p-0">
                <img src={pdt1} alt="bg" className="img-fluid" />
              </div>
              <div className="col-lg-4 col-md-6  p-0">
                <img src={pdt2} alt="bg" className="img-fluid" />
              </div>
              <div className="col-lg-4 col-md-6 p-0">
                <img src={pdt3} alt="bg" className="img-fluid" />
              </div>
              <div className="col-lg-4 col-md-6 p-0">
                <img src={pdt4} alt="bg" className="img-fluid" />
              </div>
              <div className="col-lg-4 col-md-6 p-0">
                <img src={pdt5} alt="bg" className="img-fluid" />
              </div>
              <div className="col-lg-4 col-md-6 p-0">
                <img src={pdt6} alt="bg" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="other-sec pt-0 section-padding">
        <div className="container custom-cn-width">
          <h1 className="fw-light">Related Case Studies</h1>
          <div className="mt-5">
            <div className="row g-4 g-xl-5 justify-content-center">
              <div className="col-lg-4 col-md-6 mt-3">
                <fieldset className="transition">
                  <legend className=" mb-0">CASE STUDY</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div>
                      <img src={case1} alt="bg" className="img-fluid" />
                      <h6 className="mt-3 font-size-17">Mid-Size Law Firms</h6>
                      <h4 className="transition mt-0 mb-2 mb-lg-3 font-size-26">
                        Narrative Medical Record Summaries
                      </h4>
                    </div>
                    <Link
                      className="d-inline-block underline-border"
                      to="/narrative-medical-record-summaries"
                    >
                      <span className="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i className="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <fieldset className="transition">
                  <legend className=" mb-0">CASE STUDY</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div>
                      <img src={case2} alt="bg" className="img-fluid" />
                      <h6 className="mt-3 font-size-17">Leading Hospital</h6>
                      <h4 className="transition mt-0 mb-2 mb-lg-3 font-size-26">
                        AI-Driven Optimization of Prescription Fulfillment
                      </h4>
                    </div>
                    <Link
                      className="d-inline-block underline-border"
                      to="/ai-driven-optimization-of-prescription-fulfillment"
                    >
                      <span className="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i className="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <fieldset className="transition">
                  <legend className="mb-0">CASE STUDY</legend>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div>
                      <img src={case3} alt="bg" className="img-fluid" />
                      <h6 className="mt-3 font-size-17">Jugl</h6>
                      <h4 className="transition mt-0 mb-2 mb-lg-3 font-size-26">
                        Workforce communication made simple
                      </h4>
                    </div>
                    <Link className="d-inline-block underline-border" to="/jugl">
                      <span className="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i className="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-xl-0 section-padding">
        <div className="container custom-cn-width">
          <div className="row g-lg-5 g-4 justify-content-center">
            <div className="col-lg-12 mb-4 mb-lg-5">
              <h1 className="fw-light">Reach out for a conversation</h1>
            </div>
          </div>
          <div className="row g-xl-5 g-4">
            <div className="col-lg-3 col-md-6 team-block">
              <div className="team-image border-0 p-0 d-flex flex-column justify-content-between">
                <div>
                  <img
                    src={person1}
                    alt="bg"
                    className="img-fluid transition black-white-image"
                  />

                  <p className="h6 mt-2 mb-1 fw-400 font-size-17">
                    Arunkumar Nagaiyasamy
                  </p>
                  <p className="small fw-light font-size-15">
                    Generative AI Expert
                  </p>
                </div>
                <small>
                  <a
                    className="text-dark mt-2 d-flex align-items-center font-size-14"
                    href="javascript:;"
                    data-bs-toggle="modal"
                    data-bs-target="#generativeform"
                  >
                    <span className=" d-inline-block me-1">Contact</span>
                    <i className="fa mt-1 fa-chevron-right fa-sm position-relative"></i>
                  </a>
                </small>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 team-block">
              <div className="team-image border-0 p-0  d-flex flex-column justify-content-between h-100">
                <div>
                  <img
                    src={person2}
                    alt="bg"
                    className="img-fluid transition black-white-image"
                  />
                  <p className="h6 mt-2 mb-1  fw-400 font-size-17">
                    Barath Kumar
                  </p>
                  <p className="small fw-light  font-size-15">
                    Director &amp; Vice President
                  </p>
                </div>
                <small>
                  <a
                    className="text-dark mt-2 d-flex align-items-center font-size-14"
                    href="javascript:;"
                    data-bs-toggle="modal"
                    data-bs-target="#generativeform"
                  >
                    <span className=" d-inline-block me-1">Contact</span>
                    <i className="fa mt-1 fa-chevron-right fa-sm position-relative"></i>
                  </a>
                </small>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 team-block">
              <div className="team-image border-0 p-0  d-flex flex-column justify-content-between h-100">
                <div>
                  <img
                    src={person3}
                    alt="bg"
                    className="img-fluid transition black-white-image"
                  />
                  <p className="h6 mt-2 mb-1 fw-400 font-size-17">
                    Praveenkumar
                  </p>
                  <p className="small fw-light font-size-15">
                    AI Solutions Architect
                  </p>
                </div>
                <small>
                  <a
                    className="text-dark mt-2 d-flex align-items-center font-size-14"
                    href="javascript:;"
                    data-bs-toggle="modal"
                    data-bs-target="#generativeform"
                  >
                    <span className=" d-inline-block me-1">Contact</span>
                    <i className="fa mt-1 fa-chevron-right fa-sm position-relative"></i>
                  </a>
                </small>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 team-block">
              <div className="team-image border-0 p-0  d-flex flex-column justify-content-between h-100">
                <div>
                  <img
                    src={person4}
                    alt="bg"
                    className="img-fluid transition black-white-image"
                  />
                  <p className="h6 mt-2 mb-1  fw-400 font-size-17">
                    Scott Jensen
                  </p>
                  <p className="small fw-light font-size-15">
                    AI Sales Manager
                  </p>
                </div>
                <small>
                  <a
                    className="text-dark mt-2 d-flex align-items-center font-size-14"
                    href="javascript:;"
                    data-bs-toggle="modal"
                    data-bs-target="#generativeform"
                  >
                    <span className=" d-inline-block me-1">Contact</span>
                    <i className="fa mt-1 fa-chevron-right fa-sm position-relative"></i>
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" text-center text-lg-start section-padding pt-0 pb-0">
        <div className="container custom-cn-width">
          <div className="cta-block p-5 b-r-6">
            <div className="row g-xl-5 g-4 align-items-center justify-content-center">
              <div className="col-lg-9">
                <h1 className="text-white">See how Generative AI works</h1>
                <h5 className="fw-light mt-2 text-white">
                  Nothing beats a Demonstration. And, you can ask us all your
                  questions
                </h5>
              </div>
              <div className="col-lg-3 text-lg-end text-sm-center">
                <a
                  href="javascript:;"
                  className="cust-btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#generativeform"
                >
                  Request a demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="shadow p-5">
                <h1 className="mb-4 fw-light ">Get in touch</h1>
                <GenerativeForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="generativeform"
        tabindex="-1"
        aria-labelledby="apply-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Get in touch </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <GenerativeForm />
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
    </div>
  );
};

export default Genai;
