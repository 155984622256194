/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

import HeaderOne from "../../../common/header/HeaderOne";
import FooterOne from "../../../common/footer/FooterOne";

import itanimae from "../../../../src/app/assets/images/itsupport/it-anime.gif";

// eslint-disable-next-line no-unused-vars
import cutout1 from "../../../../src/app/assets/images/itsupport/cutout1.jpg";
import Meta from "../../Meta/IT-support";

// icon imports
import icon1 from "../../../../src/app/assets/images/itsupport/icon1.png";
import icon2 from "../../../../src/app/assets/images/itsupport/icon2.png";
import icon3 from "../../../../src/app/assets/images/itsupport/icon3.png";
import icon4 from "../../../../src/app/assets/images/itsupport/icon4.png";
import icon5 from "../../../../src/app/assets/images/itsupport/icon5.png";
import icon6 from "../../../../src/app/assets/images/itsupport/icon6.png";

const ItSupport = () => {
  useEffect(() => {
    document.title = "IT Support | CAP Digisoft ";
  }, []);
  return (
    <div className="itsupport-page">
      <HeaderOne />
      <Meta/>
      <div className="page-header hero-sec itsuport-home cus-hero-padding margin-minus">
        <div className="container">
          <div className="row align-items-lg-center">
            <div data-aos="fade-up" class="col-12 aos-init aos-animate">
              <div className="sectiontitle-element hero-section-title text-center">
                <h1 class="big text-white">
                  Your Expert &amp; Specialized IT Solutions
                </h1>
                <p class="text-white">
                  Seamless IT solutions ensuring smooth business operations from
                  troubleshooting to system maintenance.
                </p>
              </div>
            </div>

            <div className="hero-btn text-center mt-4">
              <button
                className="cust-btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#meeting"
              >
                Discover Our Expert Solutions Now !
              </button>
            </div>
          </div>
        </div>
      </div>

      <section
        className="section-padding position-relative pb-0"
        data-padding=""
        data-bg="grey"
      >
        <div className="container custom-cn-width">
          <div className="row align-items-lg-center">
            <div data-aos="fade" class="col-12 col-lg-6 aos-init aos-animate">
              <div className="sectiontitle-element">
                <h2>Proactive IT Support for Seamless Operations</h2>
                <p>
                  Technical support professionals assist clients experiencing
                  hardware, software, and networking issues. They provide
                  support either on-site or via remote systems to troubleshoot
                  software installations, resolve network failures, diagnose
                  hardware malfunctions, and address other IT-related issues.
                </p>
                <div className="list-element">
                  <ul className="mt-3">
                    <li>Client meetings for diagnosing tech issues.</li>
                    <li>Offering on-site or remote technical support.</li>
                    <li>Providing tailored solutions for client needs.</li>
                    <li>Fixing hardware, software, and networking.</li>
                  </ul>
                </div>
                

                <div className="mt-4">
                  <button
                    className="cust-btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#meeting"
                  >
                    Elevate Your Business with Innovative Tech
                  </button>
                </div>
              </div>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-delay="200"
              class="col-12 col-lg-6 aos-init aos-animate"
            >
              <div className="square-element">
                <div className="info">
                  <div className="image">
                    <img src={itanimae} class="gif-img" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section data-padding="">
        <div className="container">
          <div data-aos="fade-up" class="row aos-init aos-animate">
            <div className="col-12">
              <div className="sectiontitle-element--center sec-2-padding">
                <span class="toptitle">Services</span>
                <h2>Our IT Support Services</h2>
                <p className="pb-4">
                  We offer a comprehensive range of IT support services to meet
                  your business requirements. Our services include network
                  setup, software installation, hardware maintenance,
                  cybersecurity solutions, and more.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              class="col-12 col-lg-4 col-md-6 aos-init aos-animate serv-card-wrap"
            >
              <div className="icon-element--round--box--border card h-100">
                <div className="info">
                  <div className="cust-icon sec-primary-ic">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="text">
                    <h5>Microsoft</h5>
                    <p class="text-left">
                      Microsoft Support stands ready to provide expert guidance
                      and troubleshooting for all Microsoft products, ensuring
                      smooth operation and peace of mind for users.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="200"
              class="col-12 col-lg-4 col-md-6 aos-init aos-animate serv-card-wrap"
            >
              <div className="icon-element--round--box--border card h-100">
                <div className="info">
                  <div className="cust-icon sec-primary-ic">
                    <img src={icon2} class="" alt="" />
                  </div>
                  <div className="text">
                    <h5>Cloud</h5>
                    <p class="text-left">
                      Leverage the power of cloud computing for your business.
                      Our cloud solutions provide scalability and flexibility to
                      meet your demands.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              class="col-12 col-lg-4 col-md-6 aos-init aos-animate serv-card-wrap"
            >
              <div className="icon-element--round--box--border card h-100">
                <div className="info">
                  <div className="cust-icon sec-primary-ic">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="text">
                    <h5>AWS</h5>
                    <p class="text-left">
                      Unlock the full potential of AWS with our expert services.
                      From setup to optimization, we ensure your AWS environment
                      is optimized for success.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="100"
              class="col-12 col-lg-4 col-md-6 aos-init aos-animate serv-card-wrap"
            >
              <div className="icon-element--round--box--border card h-100">
                <div className="info">
                  <div className="cust-icon sec-primary-ic">
                    <img src={icon4} class="" alt="" />
                  </div>
                  <div className="text">
                    <h5>Networking</h5>
                    <p class="text-left">
                      Build a robust network infrastructure with our networking
                      solutions. We design and implement scalable networks
                      tailored to your requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              class="col-12 col-lg-4 col-md-6 aos-init aos-animate serv-card-wrap"
            >
              <div className="icon-element--round--box--border card h-100">
                <div className="info">
                  <div className="cust-icon sec-primary-ic">
                    <img src={icon5} class="" alt="" />
                  </div>
                  <div className="text">
                    <h5>Database</h5>
                    <p class="text-left">
                      Manage your data efficiently with our database solutions.
                      From setup to maintenance, we ensure your databases are
                      secure and optimized.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              class="col-12 col-lg-4 col-md-6 aos-init aos-animate serv-card-wrap"
            >
              <div className="icon-element--round--box--border card h-100">
                <div className="info">
                  <div className="cust-icon sec-primary-ic">
                    <img src={icon6} class="" alt="" />
                  </div>
                  <div className="text">
                    <h5>Malware Defense</h5>
                    <p class="text-left">
                      Protect your systems from threats with our antivirus
                      solutions. Our proactive approach ensures your business
                      stays safe from malware and cyber attacks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="my-card">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="small-card">
                  <img src={cutout1} alt="" className="img-fluid" />
                </div>
              </div>

              <div className="col-lg-8">
                <div className="inner-card-cust">
                  <h5 className="mb-2">Compliance Made Simple</h5>
                  <p>
                    Navigating regulatory requirements can be daunting, but
                    we're here to simplify it for you. Our comprehensive
                    compliance solution ensures your organization meets
                    regulatory standards effortlessly. From policy management to
                    risk reduction, we've got you covered. Protect your business
                    and streamline compliance - explore our services today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default ItSupport;
