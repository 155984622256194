import React from "react";
import { Link } from "react-router-dom";

import HeaderDark from "../../../../../common/header/HeaderOne";

const Hero = () => {
  return (
    <>
      <div>
        <HeaderDark />
        {/* Hero Section Start Here */}
        <div className="wg-banner-1 border-line-btm margin-minus">
          <div className="container custom-cn-width">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div className="content text-center position-relative">
                  <div className="title hero-title">
                    <h1 className="big text-white">
                      Unleash the Power of Your App with the Idea Booster
                      Program
                    </h1>
                  </div>
                  <p className="hero-para">
                    We empower you to showcase the potential of your app idea to
                    the world by meticulously designing it and strategically
                    positioning its value.
                  </p>

                  <Link
                    class="default-btn solution_class_popu"
                    data-bs-toggle="modal"
                    data-bs-target="#meeting"
                    form_title="Knowledge Process Outsourcing">
                    Let's Begin Your Journey Together
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>
        {`
          header {
            position: relative;
            z-index: 99;
          }

          .wg-banner-1 {
            padding-top: 178px;
            padding-bottom: 122px;
            background: url(../../../app/assets/images/idea-booster/bannger-bg.jpg)
              bottom/100% no-repeat #13303c;
          }
          header#header_main {
            background: #13303c !important;
            box-shadow: 0 16px 32px 0 rgba(196, 203, 255, 0);
          }
          .wg-banner-1 .content .title h2,
          .wg-result .content .title h2 {
            color: #fff;
            font-size: 56px;
            font-weight: 700;
            line-height: 70px;
            letter-spacing: 0;
            margin-bottom: 24px;
            padding-left: 110px;
            padding-right: 110px;
          }
          .wg-banner-1 .content .title {
            color: #fff;
            font-size: 65px;
            font-weight: 700;
            line-height: 75px;
            letter-spacing: -1.95px;
            margin-bottom: 24px;
          }
          .arrow-hero {
            position: absolute;
            top: -25px;
            margin-left: 75px;
          }
          .rocket-gif,
          .spin-wheel-gif {
            width: 60px;
            margin-left: 16px;
          }
          .wg-banner-1 .content p,
          .wg-result .content p {
            font-size: 24px;
            margin-bottom: 39px;
            padding-left: 200px;
            padding-right: 200px;
          }
          .tf-button:hover {
            background: #ffbf00;
            color: #000;
          }
          .btn-glow {
            padding: 16px 34px;
            box-shadow: 0 0 0 0 #ffbf00;
            -webkit-animation: 1.4s ease-out infinite glow;
            animation: 1.4s ease-out infinite glow;
          }
          @-webkit-keyframes glow {
            0% {
              box-shadow: 0 0 0 0 #ffbf00;
            }
            50% {
              box-shadow: 0 0 30px 0 #ffbf00;
            }
          }
          @keyframes glow {
            0% {
              box-shadow: 0 0 0 0 #ffbf00;
            }
            50% {
              box-shadow: 0 0 30px 0 #ffbf00;
            }
          }
          .cust-padding,
          .cust-padding-2,
          .cust-padding-3 {
            padding: 80px 0;
          }
          .bg-black,
          .spin-wheel-modal {
            background-color: #13303c !important;
          }
          .heading-section .sub-title {
            color: #696969;
            font-size: 22px;
            font-weight: 500;
            margin-top: -9px;
          }
          .text-gradient {
            background: #52bdc7;
            -webkit-background-clip: text;
            font-size: 20px;
            font-weight: 700 !important;
            margin-top: 0 !important;
          }
          .head-gradient {
            font-size: 40px;
            background: #52bdc7;
            -webkit-background-clip: text;
            line-height: 45px;
            font-weight: 600;
          }
          .fw-bold,
          .gallery-sub-head,
          .section-head,
          b,
          cite,
          strong {
            font-weight: 700;
          }
          .head-gradient,
          .text-gradient {
            -webkit-text-fill-color: transparent;
            color: #696969;
          }
          .guide-1,
          .guide-2,
          .vison-1,
          .vison-2 {
            position: absolute;
          }
          .vison-1 {
            top: -193px;
            right: -139px;
          }
          .vison-2 {
            top: 109px;
            left: -55px;
          }
          .heading-section {
            margin-bottom: 24px;
            padding: 0 10px;
          }
          .free-con img {
            width: 70px;
          }
          @media (max-width: 767px) {
            .wg-banner-1 {
              padding: 120px 0 !important;
            }
          }
          .sectiontitle-elements h2 {
            line-height: 1.25;
            font-size: 2.5rem;
            padding-bottom: 1.25rem;
          }
        `}
      </style>
    </>
  );
};

export default Hero;
