import React from "react";

// cutout imports
import cutout3 from "../../../../../app/assets/images/idea-booster/cutout3.png";
import cutout4 from "../../../../../app/assets/images/idea-booster/cutout4.png";

const leverage = () => {
  return (
    <>
      {/* Leverage Our Expertise Section Start Here */}
      <div className="wg-result cust-padding bg-white overflow-hidden border-line-btm">
        <div className="container custom-cn-width">
          <div className="row justify-content-center align-items-center">
            <div className="col-xxl-7 col-md-7 order-lg-1 order-2">
              <div className="main-title sectiontitle-elements">
                <h2 class="fw-light pe-lg-5">
                  Leverage Our Expertise and Credibility
                </h2>
                <p className="text-dark">
                  As your technical partner, CAP Digisoft's reputation becomes a
                  powerful tool in attracting further investment. Our
                  credibility in the tech industry acts as a testament to the
                  potential of your app, providing investors with the confidence
                  they need to commit to your project.
                </p>
              </div>
            </div>

            <div className="col-xxl-5 col-md-5 order-lg-2 order-1">
              <div className="text-center position-relative">
                <img
                  src={cutout3}
                  class="sub-sec-img img-fluid rounded-3 mb-4"
                  alt="ib-img"
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center align-items-center mt-5">
            <div className="col-xxl-5 col-md-5">
              <div className="text-center position-relative">
                <img
                  src={cutout4}
                  class="sub-sec-img img-fluid rounded-3 mb-4"
                  alt="ib-img"
                />
              </div>
            </div>

            <div className="col-xxl-7 col-md-7">
              <div className="main-title sectiontitle-elements">
                <h2 class="fw-light pe-lg-5">
                  From Start to Launch: A Partnership for Success
                </h2>
                <p className="text-dark">
                  Our commitment goes beyond just getting started; we are with
                  you every step of the way, from the spark of an idea to the
                  excitement of the launch and beyond. CAP Digisoft isn't just
                  about building apps; we're about building successes. Join us
                  on this journey to turn your app into the next market
                  sensation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>
        {`
          .grad-ball .grad-ball-2 {
            position: absolute;
            bottom: -170px;
            left: -120px;
          }
          .grad-ball .grad-ball-1 {
            position: absolute;
            top: -200px;
            right: -130px;
          }
          .sub-sec-img {
            position: relative;
            z-index: 1;
          }
          .sub-section h3 {
            font-size: 60px;
            line-height: 1.2;
            font-weight: 500;
          }
        `}
      </style>
    </>
  );
};

export default leverage;
