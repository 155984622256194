import React from "react";

// icons imports
import cutout1 from "../../../../../app/assets/images/idea-booster/vision.png";

const Inovative = () => {
  return (
    <>
      {/* Innovative Realization Section Start Here */}
      <div className="wg-result cust-padding-2 overflow-hidden border-line-btm">
        <div className="container custom-cn-width">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="heading-section content text-center wow fadeInUp">
                <h5 class="mb-2 fw-400">Innovative Realization</h5>
                <div className="main-title sectiontitle-elements">
                  <h2 class="fw-normal mb-0 pb-0 pb-0 pb-0">
                    Transform Your Vision into Reality
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <p class="text-dark mb-0">
                Those with experience in app development advise starting small.
                This means validating your idea before heavily investing in the
                full development process.{" "}
                <b>
                  CAP Digisoft's Idea Booster program enables cost-effective,
                  insightful validation by designing your app screen by screen
                  for user feedback without a large initial cost.
                </b>{" "}
                This approach can draw co-founders, investors, and users,
                facilitating a smoother market entry.
              </p>

              <h3 class="head-gradient my-3">
                This isn't just about aesthetics;
                <br /> It's about experience.
              </h3>

              <p className="text-dark mb-0">
                Leveraging extensive experience in successful mobile app design,
                CAP Digisoft collaborates closely to{" "}
                <b>ensure your product is usable, marketable & scalable </b>{" "}
                incorporating cost efficiency, technology consideration, and
                optimal user experience into our design philosophy.
              </p>
            </div>

            <div className="col-lg-5 mt-lg-0 mt-md-5">
              <div className="text-lg-end text-center position-relative d-none d-sm-block">
                <img
                  src={cutout1}
                  class="img-fluid vision-img rounded-5"
                  alt="ib-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>
        {`
          .wg-create-design {
            padding: 100px 0 70px;
            background: no-repeat #f5f5f5;
          }
          .section-head {
            font-size: 60px;
          }
          .box-feature-item p,
          .wg-create-design .content p {
            margin-bottom: 24px;
          }
          .wg-create-design .heading-section {
            margin-bottom: 18px;
          }
          .offer-card h4,
          .vdo-sec-txt {
            font-size: 24px !important;
          }
          .v-testi .card-body {
            padding: 12px !important;
          }
          .testimonial-video {
            width: 100% !important;
            height: 100% !important;
          }
          .testimonial-video video {
            border-radius: 10px;
            width: 100%;
          }
          .offer-sec,
          .pt-100 {
            padding-top: 100px;
          }
          .cust-head-para {
            padding-left: 220px;
            padding-right: 220px;
            margin-bottom: 50px !important;
          }
          .vdo-sec-txt {
            line-height: 1.4;
          }
          .idea-booster-page .card {
            padding: 12px 0px;
            border-radius: 15px;
            border: none;
            box-shadow: rgba(149, 157, 165, 0.1) 0 8px 24px;
            box-shadow: none;
          }
          .card-numb {
            color: #fff;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 50px;
            margin-bottom: 15px;
            flex-shrink: 0;
          }
        `}
      </style>
    </>
  );
};

export default Inovative;
