import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import bannerimg from "../../../app/assets/images/banner/banner.png";
import "react-slideshow-image/dist/styles.css";
import { IoArrowForwardOutline, IoArrowBack } from "react-icons/io5";

class Banner extends Component {
  render() {
    const properties = {
      prevArrow: (
        <button className="btn-prev-slider">
          <IoArrowBack className="text-white"/>
        </button>
      ),
      nextArrow: (
        <button className="btn-next-slider">
          <IoArrowForwardOutline className="text-white"/>
        </button>
      ),
    };
    return (
      <section className="banner-style-4 banner-padding">
        <div className='banner-video'>
          <img src={bannerimg} alt="Banner Image" width={1920} height={700} />
        </div>
        
        {/* <div className=''>
        <video src={bannervideo} type="video/mp4" autoPlay loop />
    </div> */}
        <div className="container custom-cn-width">
          <div className="banner-content position-relative px-lg-5 px-3">
            <Slide {...properties} autoplay={true} duration={1800} pauseOnHover={true} >
              <div className="each-slide-effect px-4">
                <div className="row align-items-center">
                  <div className="col-xl-7">
                    <h2 className="text-uppercase text-white fw-bold">
                      Generative AI
                    </h2>

                    <p className="text-white pt-3 mb-0">
                      {" "}
                      Unlock the Future of Efficiency: Harness Generative AI to
                      make decisions quickly and empower your teams with trust
                    </p>
                    <div className="btn-container mt-4">
                      <Link to="/generative-ai" className="btn cust-btn-primary ">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="each-slide-effect px-4">
                <div className="row align-items-center">
                  <div className="col-xl-7">
                    <h2 className="text-uppercase text-white fw-bold">
                      App Idea to Product
                    </h2>

                    <p className="text-white pt-3 mb-0">
                      {" "}
                      Unlock the Future of Efficiency: Harness Foster Innovative
                      Product Development: Propel your creations with efficient
                      evaluation processes and expert guidance
                    </p>
                    <div className="btn-container mt-4">
                      <Link to="/idea-booster"  rel="noreferrer" className="btn cust-btn-primary ">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="each-slide-effect px-4">
                <div className="row align-items-center">
                  <div className="col-xl-7">
                    <h2 className="text-uppercase text-white fw-bold">
                      Call Center Service
                    </h2>

                    <p className="text-white pt-3 mb-0">
                      {" "}
                      Unlock the Future of Efficiency: Harness Elevate your
                      Customer Support: Enhance your services with streamlined
                      assessment methods and proficient supervision
                    </p>
                    <div className="btn-container mt-4">
                      <Link to="/call-center"  rel="noreferrer" className="btn cust-btn-primary ">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="each-slide-effect px-4">
                <div className="row align-items-center">
                  <div className="col-xl-7">
                    <h2 className="text-uppercase text-white fw-bold">
                      Knowledge Processing
                    </h2>

                    <p className="text-white pt-3 mb-0">
                      {" "}
                      Amplify your Knowledge Management: Elevate information
                      processing for in-depth insights and professional support
                    </p>
                    <div className="btn-container mt-4">
                      <Link to="/knowledge-process-outsourcing" className="btn cust-btn-primary ">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="each-slide-effect px-4">
                <div className="row align-items-center">
                  <div className="col-xl-7">
                    <h2 className="text-uppercase text-white fw-bold">
                      Mobile App & Software Development
                    </h2>

                    <p className="text-white pt-3 mb-0">
                      {" "}
                      Forge Exceptional Software Solutions: Advance your
                      Projects with efficient coding and technical expertise
                    </p>
                    <div className="btn-container mt-4">
                      <Link to="/mobile-software-development" className="btn cust-btn-primary" target="">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;
