import React, { Component } from 'react';
import c1 from '../../../app/assets/images/brand-logo/c1.jpg'
import c2 from '../../../app/assets/images/brand-logo/c2.jpg'
import c3 from '../../../app/assets/images/brand-logo/c3.jpg'
import c4 from '../../../app/assets/images/brand-logo/c4.jpg'
import c5 from '../../../app/assets/images/brand-logo/c5.jpg'
import c6 from '../../../app/assets/images/brand-logo/c6.jpg'
import c7 from '../../../app/assets/images/brand-logo/c7.jpg'
import c8 from '../../../app/assets/images/brand-logo/c8.jpg'
import c9 from '../../../app/assets/images/brand-logo/c9.jpg'
import c10 from '../../../app/assets/images/brand-logo/c10.jpg'
import c11 from '../../../app/assets/images/brand-logo/c11.jpg'
import c12 from '../../../app/assets/images/brand-logo/c12.jpg'
import c13 from '../../../app/assets/images/brand-logo/c13.jpg'
import c14 from '../../../app/assets/images/brand-logo/c14.jpg'
import c15 from '../../../app/assets/images/brand-logo/c15.jpg'
import c16 from '../../../app/assets/images/brand-logo/c16.jpg'
import c17 from '../../../app/assets/images/brand-logo/c17.jpg'
import c19 from '../../../app/assets/images/brand-logo/c19.jpg'
import c20 from '../../../app/assets/images/brand-logo/c20.jpg'
import c21 from '../../../app/assets/images/brand-logo/c21.png'
import c22 from '../../../app/assets/images/brand-logo/c22.png'
import c23 from '../../../app/assets/images/brand-logo/c23.png'
import c24 from '../../../app/assets/images/brand-logo/c24.png'
import c25 from '../../../app/assets/images/brand-logo/c25.png'
import c26 from '../../../app/assets/images/brand-logo/c26.png'
import c27 from '../../../app/assets/images/brand-logo/c27.png'
import c28 from '../../../app/assets/images/brand-logo/c28.png'
import c29 from '../../../app/assets/images/brand-logo/c29.png'
import c30 from '../../../app/assets/images/brand-logo/c30.png'
import c31 from '../../../app/assets/images/brand-logo/c31.png'
import cta from '../../../app/assets/images/banner/cta.png'

class Features extends Component {

render() {
return (

<section className="features-3 section-padding">
    <div className="container custom-cn-width cus-pd-24-3">
        <div className="row g-lg-5 g-4 justify-content-center">
            <div className="col-lg-12 mb-4 mb-lg-5 text-center">
                <h1 class="px-lg-4 fw-light">Trusted by more than <span
                        class="text-theme fw-bold text-grad">3000+</span> clients globally</h1>
            </div>
        </div>
        <div className="row g-0 line-box-3 text-start justify-content-center">
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c1} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c2} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c3} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c4} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c5} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c6} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c7} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c8} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c9} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c10} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c11} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c12} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c13} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c14} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c15} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c16} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c17} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>

            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c19} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c20} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c21} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c22} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c23} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c24} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c25} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c26} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c27} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c28} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c29} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c30} width={169} height={104} alt="Client Logo" class="img-fluid" />
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-center trusted-logo">
                <img src={c31} width={169} height={55.45} alt="Client Logo" class="img-fluid" />
            </div>

        </div>

    </div>

    <div className="container custom-cn-width mt-80 text-center text-lg-start section-padding pb-0">
        <div className="home-cta">
            <div className="row g-lg-5 g-4 justify-content-center">
                <div className="col-lg-5">
                    <div>
                        <img src={cta} width={370} height={274} alt="group of people" class="img-fluid" />
                    </div>
                </div>
                <div className="col-lg-7">
                    <h2 class="mb-lg-4 mb-3 fw-light font-size-24">
                        CAP Digisoft has utilized enabling technology for over 20 years to help thousands of clients save millions of dollars in business operating costs.
                    </h2>
                    <a href="contactus" class="btn cust-btn-primary light-variant">Contact us today to see how we can help you
                        save as well</a>
                </div>
            </div>
        </div>
    </div>
</section>

);
}
}

export default Features;