import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <style jsx>{``}</style>
      {/* Dream into an App Section Start Here */}

      <div className="section-padding text-center text-lg-start pb-0">
        <div className="container custom-cn-width">
          <div className="">
            <div className="row g-xl-5 g-4 align-items-center justify-content-center cta-block b-r-6">
              <div className="col-lg-9 mt-0">
                <div className="">
                  <h4 class="text-light lh-24">
                    Taking the first step towards realizing your app development
                    dream is easier than you think. With CAP Digisoft's Idea
                    Booster program, you're gaining a partner dedicated to
                    transforming your idea into reality.
                  </h4>
                  <h4 className="text-light">
                    Like only 8% of the found stake action on their idea, be
                    part of that 8% and schedule a FREE consulting today
                  </h4>
                </div>
              </div>
              <div className="col-lg-3 mt-lg-0 text-lg-end">
                <Link
                  class="default-btn solution_class_popu"
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                  form_title="Knowledge Process Outsourcing">
                  Let's Talk
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default Hero;
