import React from "react";

// mini icons imports
import ic1 from "../../../../../app/assets/images/idea-booster/icons/1.png";
import ic2 from "../../../../../app/assets/images/idea-booster/icons/2.png";
import ic3 from "../../../../../app/assets/images/idea-booster/icons/3.png";
import ic4 from "../../../../../app/assets/images/idea-booster/icons/4.png";
import ic5 from "../../../../../app/assets/images/idea-booster/icons/5.png";
import ic6 from "../../../../../app/assets/images/idea-booster/icons/6.png";
import ic7 from "../../../../../app/assets/images/idea-booster/icons/7.png";
import ic8 from "../../../../../app/assets/images/idea-booster/icons/8.png";
import ic9 from "../../../../../app/assets/images/idea-booster/icons/9.png";
import ic10 from "../../../../../app/assets/images/idea-booster/icons/10.png";
import ic11 from "../../../../../app/assets/images/idea-booster/icons/11.png";
import ic12 from "../../../../../app/assets/images/idea-booster/icons/12.png";
import ic13 from "../../../../../app/assets/images/idea-booster/icons/13.png";


const Guidance = () => {
  return (
    <>
      {/* Expert Guidance Section Start Here */}
      <div className="wg-result bg-black cust-padding overflow-hidden position-relative border-line-btm">
        <div className="container custom-cn-width">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="heading-section sectiontitle-element--center wow fadeInUp text-center">
                <h5 class="mb-2 fw-400 text-gradient">
                  Expert Guidance Every Step of the Way
                </h5>
                <div className="main-title sectiontitle-elements">
                  <h2 class="fw-normal text-white mb-0 pb-0">
                    Your journey from concept to product is in capable hands
                  </h2>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-center tetx-center expertise-card">
                <div>
                  <img src={ic1} alt="ib-img" />
                  Product Success Strategy
                </div>
                <div>
                  <img src={ic2} alt="ib-img" />
                  Product Design
                </div>
                <div>
                  <img src={ic3} alt="ib-img" />
                  Product Fundraising
                </div>
                <div>
                  <img src={ic4} alt="ib-img" />
                  Product Development
                </div>
                <div>
                  <img src={ic5} alt="ib-img" />
                  Product UI/UX
                </div>
                <div>
                  <img src={ic6} alt="ib-img" />
                  Product Pricing Strategy
                </div>
                <div>
                  <img src={ic7} alt="ib-img" />
                  Product Technology Discovery
                </div>
                <div>
                  <img src={ic8} alt="ib-img" />
                  Product Incremental Development
                </div>
                <div>
                  <img src={ic9} alt="ib-img" />
                  Product Go To Market Strategy
                </div>
                <div>
                  <img src={ic10} alt="ib-img" />
                  Product Marketing
                </div>
                <div>
                  <img src={ic11} alt="ib-img" />
                  Product Sales
                </div>
                <div>
                  <img src={ic12} alt="ib-img" />
                  Product Customer Support
                </div>
                <div>
                  <img src={ic13} alt="ib-img" />
                  Product IP Discovery
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}

      <style jsx>
        {`
          .expertise-card div {
            border: 2px solid #557580;
            border-radius: 50px;
            padding: 10px 20px;
            font-size: 20px;
            color: #fff;
            display: flex;
            align-items: center;
            background: rgb(35 68 81);
            box-shadow: 0 4px 30px rgb(89 89 89 / 28%);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            margin-right: 15px;
            margin-bottom: 15px;
          }
          .expertise-card img {
            width: 40px;
            padding-right: 10px;
          }
          .expt-sec .guide-1 {
            position: absolute;
            top: -270px;
            right: -210px;
          }
          .expt-sec .guide-2 {
            position: absolute;
            top: -42px;
            left: -127px;
          }
        `}
      </style>
    </>
  );
};

export default Guidance;
